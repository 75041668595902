import styled from "styled-components";

const AuthWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 63px);
  padding-left: 36px;
  padding-right: 36px;
  .input-wrapper {
    line-height: 9px;
  }
  .subsription-status-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    position: absolute;
    left: 31px;
    top: 72px;
    p {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color:#000;
}
.green {
  color: green;
  font-weight: 900;
}
svg {
    font-size: 24px;
    color:#0057d3;
    cursor: pointer;
}
}
  .title-column {
    .title-content {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #303f45;
      display: block;
      margin-bottom: 6px;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 250px;
      white-space: nowrap;
    }
    .title-details {
      font-weight: 400;
      font-size: 9px;
      line-height: 12px;
      strong {
        color: ${({ theme }) => theme["link-color"]};
        font-weight: 600;
      }
    }
  }
  .check-icon-auth {
    color: ${({ theme }) => theme["white-color"]};
    width: 100%;
    max-width: 96px;
    height: 100%;
    min-height: 96px;
    background: #018900;
    border-radius: 64px;
    margin-top: -14px;
  }
  .right-side-img-wrapper {
    margin-left: 106px;
    margin-top: 98px;
  }
  .right-content-wrapper {
    width: 100%;
    max-width: 414px;
    margin: auto;
    margin-top: 157px;
    h1 {
      font-weight: 900;
      font-size: 32px;
      line-height: 42px;
      /* or 131% */

      letter-spacing: 0.02em;
      color: ${({ theme }) => theme["primary-color"]};
      text-align: left;
      text-transform: capitalize;
      margin-top: 32px;
      padding-bottom: 48px;
      margin-bottom: 0px;
    }
    .verified-wrapper {
      font-size: 36px;
      font-weight: 700;
      line-height: 44px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: capitalize;
      margin-top: 32px;
      padding-bottom: 24px;
      margin-bottom: 0px;
    }
    button {
      margin-top: 24px;
    }
  }
  .market-place-wrapper {
    .Market-button {
      width: 100%;
      max-width: 298px;
      display: flex;
      margin-top: 32px;
      justify-content: center;
    }
  }
  .country-detail {
    img {
      margin-right: 6px;
    }
    h3 {
      font-size: 18px;
    }
  }
  .title {
    margin-bottom: 29px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    /* letter-spacing: 0; */
    letter-spacing: -0.45px;
    /* letter-spacing: 0.02em; */
    /* color: #161924; */
    color: ${({ theme }) => theme["body-text"]};
  }
  .subtitle {
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px !important;
    letter-spacing: 0.04em !important;
    color: ${({ theme }) => theme["black-color"]};
    margin-bottom: 24px;
  }
  .auth-section-left {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 8px;
    img {
      max-width: 100%;
      position: relative;
      left: -3px;
    }
  }
  .auth-section-right {
    padding-top: 49px;
  }
  .auth-section-middle {
    .auth-section-middle-content {
      &.auth-section-signup-content {
        max-height: calc(100vh - 325px);
      }
      max-height: calc(100vh - 250px);
      overflow-x: hidden;
      overflow-y: auto;
    }
    .email-verification-content {
      .auth-section-middle-content {
        max-height: calc(100vh - 150px);
      }
      p {
        margin-bottom: 48px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: ${({ theme }) => theme["secondary-color"]};
        &.not-recive-text {
          margin-top: 32px;
        }
      }
      .title {
        margin-bottom: 16px;
        letter-spacing: 0;
      }
    }
    .input-wrapper {
      margin-bottom: 16px;
    }
    .password-input {
      /* margin-bottom: 16px; */
      margin-bottom: 10px;
      margin-top: -3px;
    }
    .auth-content-centered {
      display: flex;
      justify-content: center;
      height: calc(100vh - 135px);
      /* align-items: center; */
      flex-direction: column;
    }
  }
  label.form-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    display: block;
    margin-bottom: 8px;
  }
  .form-text {
    margin-top: 4px;
    display: block;
    /* margin-left: -1px; */
  }
  .form-check {
    /* margin-top: -3.5px; */
    margin-top: 3px;
    margin-bottom: 24px;
    .form-check-label {
      letter-spacing: 0.02em;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      letter-spacing: 0.02em;
      a {
        text-decoration: none;
        color: ${({ theme }) => theme["link-color"]};
        margin-left: 2px;
      }
    }
  }
  .forgot-link {
    margin-bottom: 22px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    a {
      color: ${({ theme }) => theme["link-color"]};
      text-decoration: none;
    }
  }

  .already-account {
    text-align: center;
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      text-align: center;
      letter-spacing: 0.02em;

      color: ${({ theme }) => theme["black-color"]};
      a {
        color: ${({ theme }) => theme["link-color"]};
        text-decoration: none;
      }
    }
  }
  .auth-btn {
    /* margin-bottom: 24px; */
    margin-bottom: 11px;
    letter-spacing: 0.02em;
    &:hover{
      background-color: #0057d3;
      color: #fff;
    }
  }
  .login-btn {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .auth-reset-footer {
    padding-top: 16px;
  }
  .sub-heading {
    margin-bottom: 24px;
    padding-left: 2px;
    &.sub-heading-cm {
      margin-bottom: 26px;
    }
  }
  .not-recive-text {
    margin-top: 64px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.02em;
    margin-bottom: 0 !important;
    a {
      color: ${({ theme }) => theme["link-color"]};
      text-decoration: none;
      margin-left: 7px;
    }
  }

  .email-verification-wrapper {
    text-align: center;
    .email-verification-image {
      text-align: center;
    }
  }
  .auth-wrapper-login {
    .title {
      margin-bottom: 32px;
    }
    .password-input {
      margin-bottom: 8px;
      margin-top: 0px;
    }
  }
  .auth-reset-password {
    .title {
      margin-bottom: 32px;
    }
    .subtitle {
      margin-bottom: 24px;
    }
    .password-input {
      margin-bottom: 16px;
      margin-top: 0;
    }
    .auth-reset-footer {
      padding-top: 8px;
      .auth-btn {
        margin-bottom: 0;
      }
    }
  }
`;
const StoreConnectivity = styled.div`
  padding-top: 35px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 24px;
  .onboarding-title {
    h1 {
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.02em;
      /* letter-spacing: 0.2px; */
      color: ${({ theme }) => theme["body-text"]};
      margin-bottom: 0;
      img {
        margin-right: 15px;
        cursor: pointer;
        margin-left: 2px;
      }
    }
  }
  .store-connectivity {
    margin-top: 147px;
    .store-sections {
      .store-section {
        background: ${({ theme }) => theme["white-color"]};
        border-radius: 4px;
        .store-section-image {
          min-height: 168px;
          justify-content: center;
          display: flex;
          align-items: center;
          padding-top: 12px;
          border: 1px solid ${({ theme }) => theme["texst-primary"]};
          border-bottom: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
        button {
          background-color: ${({ theme }) => theme["link-color"]};
          color: ${({ theme }) => theme["white-color"]};
          margin-bottom: 0;
          padding-top: 11px;
          padding-bottom: 11px;
        }
      }
    }
  }
  .select-marketplace {
    padding-top: 72px;
    h3 {
      margin-bottom: 21px;
      letter-spacing: 0.6px;
    }
    .search-input {
      margin-bottom: 38px;
      input {
        padding: 11px 9px;
      }
      svg {
        right: 12.9px;
        font-size: 14px;
        top: 15px;
        color: ${({ theme }) => theme["link-color"]};
      }
    }
    .select-marketplace-regions {

    }
    .marketplace-region {
      padding: 24px;
      background: #ffffff;
      /* Text Field */

      border: 1px solid #e6e8f0;
      border-radius: 8px;
      h4 {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.02em;
        margin-bottom: 24px;
      }
      .region-list {
        display: flex;
        gap: 17px;
        flex-wrap: wrap;
        .form-check {
          flex: 1;
          padding-left: 17px;
          letter-spacing: 0.04em;
        }
        &.region-list-europe {
          .form-check {
            &:first-of-type {
              width: 100%;
              flex: 100%;
            }
          }
        }
        .region-country-list {
          display: flex;
          gap: 16px;
          margin-left: -1.54em;
          margin-top: 12px;
          img {
            width: 24px;
            height: 24px;
            border-radius: 200px;
          }
        }
      }
      .form-check-input {
        border: 1px solid #7c8092;
        margin-top: 1.5px;
        margin-left: -17px;
        &:checked {
          background-color: #2196f3;
          border-color: #2196f3;
        }
      }
      .form-check-input {
        &:checked[type="radio"] {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgNyA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi4wNDEwMiAxLjcyODUyTDIuNzkwOTkgNC45Nzg0NUMyLjY5MzQ4IDUuMDc1OTYgMi41NjU0OSA1LjEyNTAzIDIuNDM3NSA1LjEyNTAzQzIuMzA5NTEgNS4xMjUwMyAyLjE4MTUyIDUuMDc1OTYgMi4wODQwMSA0Ljk3ODQ1TDAuNDU5MDQ1IDMuMzUzNDlDMC4yNjM0ODkgMy4xNTgwMiAwLjI2MzQ4OSAyLjg0MTk4IDAuNDU5MDQ1IDIuNjQ2NTFDMC42NTQ1MSAyLjQ1MDk2IDAuOTcwNDU5IDIuNDUwOTYgMS4xNjYwMiAyLjY0NjUxTDIuNDM3NSAzLjkxOEw1LjMzNDA1IDEuMDIxNTVDNS41Mjk1MSAwLjgyNTk4OSA1Ljg0NTQ2IDAuODI1OTg5IDYuMDQxMDIgMS4wMjE1NUM2LjIzNjQ4IDEuMjE3MDEgNi4yMzY0OCAxLjUzMjk2IDYuMDQxMDIgMS43Mjg1MlYxLjcyODUyWiIgZmlsbD0iI0ZBRkFGQSIvPgo8L3N2Zz4K);
          background-size: 7px 6px;
        }
      }
    }
  }
  .store-advertising {
    padding-top: 96px;
    .search-input {
      margin-bottom: 72px;
      input {
        padding: 10px 15px;
      }
      svg {
        right: 12.9px;
        font-size: 14px;
        top: 13px;
        color: ${({ theme }) => theme["link-color"]};
      }
    }
    .edit-store-search-input {
      margin-bottom: 45px;
      input {
        padding: 10px 15px;
      }
      svg {
        right: 12.9px;
        font-size: 14px;
        top: 13px;
        color: ${({ theme }) => theme["link-color"]};
      }
    }
    .marketplace-region {
      background: ${({ theme }) => theme["white-color"]};
      border: 1px solid #cfd8dc;
      border-radius: 8px;
      padding: 24px;
      padding-bottom: 23px;
      h3 {
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme["body-text"]};
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 9px;
        margin-left: -1px;
      }
      h4 {
        letter-spacing: 0.06em;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: ${({ theme }) => theme["label-color"]};
        margin-bottom: 16px;
        margin-left: -1px;
      }
      h5 {
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.049em;
        color: ${({ theme }) => theme["secondary-color"]};
        margin-bottom: 16px;
        margin-left: -1px;
      }
      .region-list {
        .form-check-input[type="checkbox"] {
          width: 14px;
          height: 14px;
          margin-top: 4px;
          min-height: 14px;
          border-radius: 2px;
          border: 1px solid ${({ theme }) => theme["label-color"]};
        }
        .form-check {
          /* margin-bottom: 16.5px; */
          margin-bottom: 11px;
          margin-left: -1px;
          margin-top: -3px;
          .form-check-label {
            padding-left: 10px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.02em;
            color: ${({ theme }) => theme["secondary-color"]};
            img {
              margin-right: 9.5px;
              margin-top: 0;
            }
            span {
              position: relative;
              top: 2px;
            }
          }
        }
        button {
          margin-top: 12px;
          margin-bottom: 0;
          margin-left: -1px;
        }
      }
    }
  }
  .store-profile {
    .store-select-all {
      margin-bottom: 15px;
      margin-top: -10px;
      padding-right: 10px;

      a {
        display: inline-block;
        text-decoration: none;
        color: ${({ theme }) => theme["link-color"]};
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.02em;
      }
    }
    .profile-card {
      background: ${({ theme }) => theme["white-color"]};
      border: 2px solid ${({ theme }) => theme["text-primary"]};
      border-radius: 4px;
      padding: 16px;
      padding-top: 14px;
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      margin-bottom: 24px;
      &:hover {
        border: 2px solid ${({ theme }) => theme["link-color"]};
      }
      .form-check {
        margin-bottom: 15px;
        min-height: 20px;
        .form-check-input[type="checkbox"] {
          width: 17px;
          height: 17px;
          margin-right: 10px;
          margin-top: 1px;
          border: 1px solid ${({ theme }) => theme["label-color"]};
          border-radius: 4px;
        }
        .form-check-label {
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 0.04em;
          color: ${({ theme }) => theme["primary-color"]};
        }
      }
      .profile-card-content {
        p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: ${({ theme }) => theme["secondary-color"]};
          small {
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: 0.02em;
            color: ${({ theme }) => theme["label-color"]};
            margin-left: -2px;
            margin-right: 1px;
          }
        }
      }
    }
    button {
      margin: 0 auto;
      display: block;
      margin-top: 7px;
    }
  }
`;
const StoreProfile = styled.div``;
const CongratulationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 96px);
  padding-top: 32px;
  img {
    margin-bottom: 32px;
  }
  h3 {
    margin-bottom: 16px;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.5px;
    color: ${({ theme }) => theme["body-text"]};
  }
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: ${({ theme }) => theme["secondary-color"]};
    max-width: 390px;
    margin: 0 auto;
    margin-bottom: 16px;
    text-align: center;
    a{
      text-decoration: none;
    }
  }
  button {
    display: block;
    margin: 0 auto;
    letter-spacing: 0.02em;
    padding: 12px 25px;
  }
`;

export { AuthWrapper, StoreConnectivity, StoreProfile, CongratulationWrapper };
