import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory} from "react-router-dom";
import { debounce } from "lodash";
import { RiDeleteBinLine } from 'react-icons/ri';
import { FcCancel } from 'react-icons/fc';
import { BiBlock } from 'react-icons/bi';
import { Form, Tooltip } from "antd";

import IconTrash from "../../assets/images/icon-basket.svg";
import SearchInput from "../../component/searchInput/Index";
import Button from "../../component/Button/Index";
import Table from "../../component/table/Index";
import TablePagination from "../../component/pagination/index";
import Modal from "../../component/modal/index";
import Notification from '../../component/notifications/notification';
import Input from "../../component/inputs/input/index";
import Spin from '../../component/Spin';

import ResetPassword from "../../assets/images/reset-password.svg";
import Personate from "../../assets/images/personate.svg";
import PasswordInput from "../../component/inputs/passwordInput/index";

import { UserPageWrapper } from "./style";

import {
  CancelSubscription,
  DeleteUser,
  ExportUsers,
  GetUsers,
  InviteUser,
  SetUserState,
  UpdateUserPassword,
  GetUser
} from '../../redux/slices/user-slice';

import { SetStoreState } from '../../redux/slices/store-slice';
import { isContainSpecialCharacter } from "../../utils/helpers";
import { SetProductState } from "../../redux/slices/product-slice";

const UserPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loading,
    totalUsers,
    users,
    error,
    message
  } = useSelector(state => state.user);

  const [tableRows, setTableRows] = useState([]);
  const [passwordModal, setPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [userId, setUserId] = useState('');
  const [popupDelete, setPopupDelete] = useState(false);
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [email, setEmail] = useState('');
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);

  const defaultColDefs = {
    resizable: true,
    sortable: true,
    flex: 1,
  };

  const handleImpersonateUser = (userId, name, email) => {
    dispatch(SetUserState({ field: 'impersonateUserData', value: {
      userId,
      name,
      email
    }}));

    dispatch(SetStoreState({ field: 'tab', value: 'dashboard' }));
    //when impersonateUser then admin products sets to empty in redux.
    dispatch(SetProductState({field: 'favouriteProducts', value: []}));
    dispatch(GetUser({
      impersonateUserId: userId
    }));
    history.push('/dashboard');
  };

  const handleDeleteModal = (id, website) => {
    setPopupDelete(true);
  };

  const pageOptions = [10, 50, 100, 500];
  const columnDefinitions = [
    {
      headerName: "Name",
      minWidth: 315,
      flex: 1,
      field: "poNumber",
      cellRenderer: ({ data }) => {
        const { name } = data || {};

        return name || 'N/A';
      },
    },
    {
      headerName: "Email",
      minWidth: 313,
      flex: 1,
      field: "user",
      cellRenderer: ({ data }) => {
        const { email } = data || {};

        return email || 'N/A';
      },
    },
    {
      headerName: "Status",
      minWidth: 313,
      flex: 1,
      field: "user",
      cellRenderer: ({ data }) => {
        const { status } = data || {};

        return status || 'N/A';
      },
    },
    {
      headerName: "Joining Date",
      minWidth: 190,
      flex: 1,
      field: "vendor",
      cellRenderer: ({ data }) => {
        const { createdAt } = data || {};

        return createdAt ? moment(createdAt).format('lll') : 'N/A';
      },
    },
    {
      headerName: "Bugs Reported",
      minWidth: 190,
      flex: 1,
      field: "bugs",
      cellRenderer: ({ data }) => {
        const { 
          _id: userId,
          numberOfBugs = 0
        } = data || {};

        return (
          numberOfBugs > 0 ? (
            <p onClick={() => window.open(`/bugs/${userId}`, '_blank')} style={{ cursor: 'pointer', color: 'blue'}}>{numberOfBugs}</p>
          ) : 0
        )
      },
    },
    {
      headerName: "Actions",
      minWidth: 190,
      flex: 1,
      pinned: 'right',
      field: "wareHouse",
      cellRenderer: ({ data }) => {
        const {
          _id: userId,
          name,
          email,
          subscriptionId,
          subscriptionPlan
        } = data || {};

        return (
          <div className="d-flex action-icon-wrapper">
            <Tooltip placement="top" title="Reset Pasword">
              <img onClick={() => {
                setPasswordModal(true)
                setUserId(userId)
              }} src={ResetPassword} />
            </Tooltip>
            <Tooltip placement="top" title="Impersonate User">
              <img src={Personate} onClick={() => handleImpersonateUser(userId, name, email)}/>
            </Tooltip>
            <Tooltip placement="top" title="Cancel User Subscription">
              <BiBlock style={{color: subscriptionPlan ? 'red' : 'grey'}}
                className={`filter-clear ${subscriptionPlan ? '' : 'disabled disable-class'}`}
                onClick={() => {
                  setUserId(userId)
                  setCancelSubscriptionModal(true)
                }}
              />
            </Tooltip>
           <Tooltip placement="top" title="Delete User">
            <RiDeleteBinLine
                className="del-icon-color"
                onClick={() => {
                  setUserId(userId)
                  handleDeleteModal(userId)
                }}
              />
           </Tooltip>
          </div>
        )
      }
    },
  ];

  const handleDeleteUser = () => {
    dispatch(DeleteUser({ userId }));
    setPopupDelete(false);
  };

  const handleCancelSubscription = () => {
    dispatch(CancelSubscription({ userId }));
    setCancelSubscriptionModal(false);
  };

  const handleSearch = debounce((e) => {
    setSearchKeyword(e.target.value);
    setPageNumber(1);
  }, 100);

  const handlePasswordUpdate = () => {
    if (password !== confirmPassword) {
      dispatch(SetUserState({ field: 'error', value: 'Password & Confirm Password should be same.' }));
    } else if (!password || !confirmPassword) {
      dispatch(SetUserState({ field: 'error', value: 'Password field cannot be empty.' }));
    } else {
      dispatch(UpdateUserPassword({
        userId,
        password
      }));
    }

    setPasswordModal(false);
    setPassword('');
    setConfirmPassword('');
  };

  const handleOnPageChange = (current, pageSize) => {
    setPageNumber(current);
    setPageLimit(pageSize);
  };

  const isEmailValid = (email) => {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
  };

  const handleInviteUser = () => {
    if (!email) {
      dispatch(SetUserState({ field: 'error', value: 'Email is required' }));
    } else if (!isEmailValid(email)) {
      dispatch(SetUserState({ field: 'error', value: 'Enter a valid email Address' }));
    } else {
      dispatch(InviteUser({ email }));
    }

    setInviteUserModal(false);
  };

  useEffect(() => {
    dispatch(GetUsers({
      searchKeyword,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
    }));
  }, []);

  useEffect(() => {
    if (!isContainSpecialCharacter({ searchKeyword })) {
      dispatch(GetUsers({
        searchKeyword,
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit,
      }));
    } else {
      dispatch(SetUserState({ field: 'totalUsers', value: 0 }));
      dispatch(SetUserState({ field: 'users', value: [] }));
    }
  }, [searchKeyword, pageLimit, pageNumber]);

  useEffect(() => {
    if (users && users.length) {
      const data = users.map(
        (
          {
            _id,
            name,
            email,
            createdAt,
            numberOfBugs,
            status,
            subscriptionId,
            subscriptionPlan
          },
          index
        ) => {
          return {
            _id,
            name,
            email,
            createdAt,
            numberOfBugs,
            status,
            subscriptionId,
            subscriptionPlan
          };
        }
      );

      setTableRows(data.sort((a,b)=> b.numberOfBugs - a.numberOfBugs));
    } else {
      setTableRows([])
    }
  }, [users]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error,
      });
      dispatch(SetUserState({ field: "error", value: "" }));
      return;
    }

    if (message) {
      Notification({
        type: "success",
        title: "Success",
        description: message,
      });
      dispatch(SetUserState({ field: "message", value: "" }));
      dispatch(SetUserState({ field: "success", value: false }));

      if (totalUsers && totalUsers > 0) {
        const remainingUsersOnCurrentPage = users.length - 1;
        if (remainingUsersOnCurrentPage > 0) {
          dispatch(GetUsers({
            searchKeyword,
            limit: pageLimit,
            skip: (pageNumber - 1) * pageLimit
          }));

          if (remainingUsersOnCurrentPage === 0) {
            dispatch(GetUsers({
              searchKeyword,
              limit: pageLimit,
              skip: (1 - 1) * pageLimit
            }));
          }
        } else {
          const nextPage = pageNumber - 1;
          setPageNumber(nextPage === 0 ? 1 : nextPage);
        }
      }
      return;
    }
  }, [error, message]);

  const gridOptions = {
    rowClass: 'ag-row', // Apply the custom CSS class to the row
  };
  return (
    <UserPageWrapper>
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="page-title">Users</h1>
        <div className="d-flex user-page-header">
        <SearchInput placeholder="Search Users" onChange={(e) => handleSearch(e)}/>
          <Button
            className="export-btn-users"
            onClick={() => setInviteUserModal(true)}
          >
            Invite User
          </Button>
          <Button
            className="export-btn-users"
            onClick={() => dispatch(ExportUsers({}))}
          >
            Export Sheet
          </Button>
        </div>
      </div>
      <Table
        height="203px"
        headerHeight={50}
        columnDefinitions={columnDefinitions}
        defaultColDef={defaultColDefs}
        rowData={tableRows}
        rowHeight={67}
        gridOptions={gridOptions}
        loading = {loading}
      />
      <TablePagination
        background="#F8F8F8"
        records={totalUsers}
        options={pageOptions}
        onChange={handleOnPageChange}
        pageNumber={pageNumber}
        pageLimit={pageLimit}
      />
      <Modal
        className="cancel-modal"
        open={popupDelete}
        saveText="Delete"
        closeText="Cancel"
        onSave={() => handleDeleteUser()}
        onClose={() => setPopupDelete(false)}
      >
        <div className="category-modal-wrapper">
          <img src={IconTrash} alt="no/" className="icon-popup" />
          <h2>Are you sure want to delete this user?</h2>
          <p>
            The selected user will be deleted and it will be removed from our
            system.
          </p>
        </div>
      </Modal>
      <Modal
        className="cancel-modal"
        open={cancelSubscriptionModal}
        saveText="Cancel Subscription"
        closeText="Cancel"
        onSave={() => handleCancelSubscription()}
        onClose={() => setCancelSubscriptionModal(false)}
      >
        <div className="category-modal-wrapper">
          <FcCancel className="icon-popup"/>
          <h2>Are you sure want to cancel this user subscription?</h2>
        </div>
      </Modal>
      <Modal
        heading="Invite User"
        header
          className="user-page-password-modal"
          open={inviteUserModal}
          saveText="Sent Invite"
          closeText="Cancel"
          onSave={() => handleInviteUser()}
          onClose={() => {
            setInviteUserModal(false)
            setEmail('')
          }}
      >
        <div className="category-modal-wrapper">
          <Form.Item
            name="email"
            rules={[
              { message: "Please Enter Email!", required: true },
            ]}
            label="Email"
          >
            <Input
              className="payment-form-address"
              type="text"
              placeholder="Email Here"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
        </div>
      </Modal>
      <Modal
      heading="Reset Password"
      header
        className="user-page-password-modal"
        open={passwordModal}
        saveText="Save"
        closeText="Cancel"
        onSave={() => handlePasswordUpdate()}
        onClose={() => {
          setPasswordModal(false)
          setPassword('')
          setConfirmPassword('')
        }}
      >
        <PasswordInput
          barColors={["#0FB600", "#424A47", "#424A47", "#424A47", "#424A47"]}
          strength
          label="Password"
          placeholder="Password Here"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="password-input-wrapper">
        <PasswordInput
          barColors={["#0FB600", "#424A47", "#424A47", "#424A47", "#424A47"]}
          strength
          label="Confirm Password"
          placeholder="Password Here"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        </div>
      </Modal>
      {loading && <Spin />}
    </UserPageWrapper>
  );
};

export default UserPage;
