import { isEmpty } from 'lodash';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { FiAlertCircle } from "react-icons/fi";
import { FaStripe } from "react-icons/fa";
import { BsCheckCircle } from "react-icons/bs";
import { Form } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Input from "../../component/inputs/input/index";
import PageHeader from "../../component/header";
import Modal from "../../component/modal/index";
import Button from "../../component/Button/Index";
import Notification from '../../component/notifications/notification';
import Spin from '../../component/Spin';

import { SetAuthState } from '../../redux/slices/auth-slice';
import {
  CreateCustomer,
  UpdateCustomer,
  SetStripeState
} from '../../redux/slices/stripe-slice';
import {
  GetUser,
  SetUserState
} from '../../redux/slices/user-slice';
import {
  CreateSubscription,
  GetSubscriptionPlans,
  SetSubscriptionState
} from "../../redux/slices/subscription-slice";

import { COUNTRIES } from '../../constants/constants';

import { PaymentWrapper } from "./style";
import { StoreWrapper } from "../OnBoarding/style";

const localPublishableKey = process.env.LOCAL_PUBLISHABLE_KEY;
const prodPublishableKey = process.env.PROD_PUBLISHABLE_KEY;

const stripePromise = loadStripe(process.env.NODE_ENV === 'development' ? localPublishableKey : prodPublishableKey);
// const stripePromise = loadStripe(localPublishableKey);

const CheckoutForms = () => {
  const {
    message: messageSub,
    success: successSub,
    error: errorSub,
    loading: loadingSub,
    subscriptionPlans
  } = useSelector(state => (state.subscription));
  const ExpiryOptions = {
    showIcon: true,
    placeholder: "123",
    style: {
      base: {
        color: "#5A5F7D",
        // iconColor: “#C4F0FF”,
        ":-webkit-autofill": {},
        "::placeholder": {
          color: "#ADB4D2",
        },
      },
    },
  };

  const CardNumberOptions = {
    showIcon: true,
    placeholder: "0000 0000 0000 0000",
    style: {
      base: {
        // iconColor: “#C4F0FF”,
        color: "#5A5F7D",
        ":-webkit-autofill": {},
        "::placeholder": {
          color: "#ADB4D2",
        },
      },
    },
  };

  const ExNumberOptions = {
    showIcon: true,
    placeholder: "MM/YYY",
    style: {
      base: {
        color: "#5A5F7D",
        // iconColor: “#C4F0FF”,
        ":-webkit-autofill": {},
        "::placeholder": {
          color: "#ADB4D2",
        },
      },
    },
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const { user: userData, storeStatus } = useSelector(state => (state.auth));
  const {
    success,
    customerCreated,
    customerUpdated,
    error,
    loading,
    message
  } = useSelector(state => (state.stripe));
  const { subscriptionData, user
  } = useSelector(state => (state.user));

  const [name, setName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({});
  const [nameError, setNameError] = useState('');
  const [countries, setCountries] = useState([]);
  const [address, setAddress] = useState({
    line1: '',
    country: ''
  });
  const [addressError, setAddressError] = useState({
    line1Error: '',
    countryError: ''
  });
  const [cardValidationError, setCardValidationError] = useState({
    cvcError: '',
    dateError: '',
    numberError: '',
    cvcTouched: false,
    dateTouched: false,
    numberTouched: false,
  });

  const [cancelTransaction, setCancelTransaction] = useState();
  const [successTransaction, setSuccessTransaction] = useState(false);
  const [pmntBtnClicked, setPmntBtnClicked] = useState(false)

  const handleName = (value) => {
    const name =  value.trim();
    if (name) {
      setNameError('')
    }

    setName(name);
  };

  const handleCardValidationError = (e) => {
    const {
      elementType,
      error,
      complete,
      empty
    } = e;

    let cvcTouched = false;
    let dateTouched = false;
    let numberTouched = false;
    
    let cvcError =  '';
    let dateError = '';
    let numberError = '';

    if (elementType === 'cardNumber') {
      if (!isEmpty(error)) { 
        numberError = error.message;
        numberTouched = true;
      }
      else if (complete) numberTouched = true;
      else if (empty) numberTouched = false;
      setCardValidationError({
        ...cardValidationError,
        numberError: numberError,
        numberTouched
      });
    } else if (elementType === 'cardCvc') {
      if (!isEmpty(error)) { 
        dateError = error.message;
        cvcTouched = true;
      }
      else if (complete) cvcTouched = true;
      else if (empty) cvcTouched = false;
      setCardValidationError({
        ...cardValidationError,
        cvcError: dateError,
        cvcTouched
      });
    } else if (elementType === 'cardExpiry') {
      if (!isEmpty(error)) {
        cvcError = error.message;
        dateTouched = true;
      }
      else if (complete) dateTouched = true;
      else if (empty) dateTouched = false;
      setCardValidationError({
        ...cardValidationError,
        dateError: cvcError,
        dateTouched
      });
    }
  };

  const handleAddress = (value, key) => {
    if (key === 'line1') {
      let line1Error = '';
      setAddressError({
        ...addressError,
        line1Error
      })
    }

    if (key === 'country') {
      let countryError = '';
      setAddressError({
        ...addressError,
        countryError
      });
    }

    setAddress({
      ...address,
      [key]: value
    });
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const {
      cvcError: cError,
      dateError: dError,
      numberError: nError,
      cvcTouched,
      dateTouched,
      numberTouched,
    } = cardValidationError;

    let error = ''; 

    if (address.line1 === '' || address.country === '' || name === ''
      || cvcTouched !== true || dateTouched !== true || numberTouched !== true
      || cError || dError || nError
    ) {
      let line1Error = '';
      let countryError = '';
      let nameError = '';
      let cvcError = '';
      let numberError = '';
      let dateError = '';

      if (address.line1 === '') {
        line1Error = 'Billing address is required!';
        error = 'Billing address is required!';
      }

      if (address.country === '') {
        countryError = 'Country is required!';
        error = 'Country is required!';
      }

      if (name === '') {
        nameError= 'Name is Required!';
        error= 'Name is Required!';
      }

      if (cardValidationError.cvcError === '' && cvcTouched === false) {
        cvcError = 'Cvc is Requried!';
        error = 'Cvc is Requried!';
      } else {
        cvcError = cardValidationError.cvcError;
        error = cardValidationError.cvcError;
      }

      if (cardValidationError.dateError === '' && dateTouched === false) {
        dateError = 'Date is Required!';
        error = 'Date is Required!';
      } else {
        dateError = cardValidationError.dateError;
        error = cardValidationError.dateError;
      }

      if (cardValidationError.numberError === '' && numberTouched === false) {
        numberError = 'Card Number is Requried!';
        error = 'Card Number is Requried!';
      } else {
        numberError = cardValidationError.numberError;
        error = cardValidationError.numberError;
      }
      if (!name) {
        Notification({
          type: "error",
          title: "Error",
          description: "Name is required"
        });
        return;
      }

      setAddressError({
        line1Error,
        countryError
      })
      setNameError(nameError);

      setCardValidationError({
       ...cardValidationError,
       cvcError,
       numberError,
       dateError
      });
    }

    setPmntBtnClicked(true);
    const card = elements.getElement(CardNumberElement);
    
    const response = await stripe.createToken(card);
    
    if (response?.token) {
      if (user.payment) {
        dispatch(UpdateCustomer({ token: response.token, name, address, stripeUserId: user.payment.stripeUserId }));
      } else {
        dispatch(CreateCustomer({ token: response.token, name, address }));
      }
    } else {
      const { error: errorFromStripe } = response;
      if (errorFromStripe) {
        error = errorFromStripe.message;
      }

      Notification({
        type: "error",
        title: "Error",
        description: error
      });
      setPmntBtnClicked(false)
    }
  };

  useEffect(() => {
    dispatch(GetUser({ userId: userData._id }));
    const countriesData = COUNTRIES?.map((country, index) => {
      return ({
        value: country.code,
        label: country.name
      })
    });

    setCountries(countriesData);
  }, []);

  useEffect(() => {
    if (user.payment && !user.subscriptionId) {
      const country = COUNTRIES.find((country, index) => country.code === user.payment?.address?.country);
      if (country) {
        const countryOption = {
          value: country.code,
          label: country.name
        }
        setSelectedCountry(countryOption);
        setAddress(user.payment?.address);
        setName(user.payment?.name);
      }
      dispatch(SetAuthState({ field: 'user', value: { ...userData, payment: user.payment } }));
      dispatch(SetUserState({ field: 'userSuccess', value: false }));

       dispatch(CreateSubscription({
        priceId: subscriptionData.priceId,
        subscriptionPlan: subscriptionData.subscriptionPlan,
        subscriptionCreatingFirstime: true,
        subscriptionType: subscriptionData.subscriptionType,
        couponCode: subscriptionData?.couponCode,
        subscribedStores: subscriptionData?.subscribedStores 
      }));
    }
  }, [user]);

  useEffect(() => {
    if (customerCreated || customerUpdated) {

      dispatch(GetUser({ userId: userData._id }));
      dispatch(SetStripeState({ field: 'customerCreated', value: false }));
      dispatch(SetStripeState({ field: 'customerUpdated', value: false }));
    }
  }, [customerCreated, customerUpdated]);

  useEffect(() => {
    if (success && message) {
      dispatch(SetStripeState({ field: 'success', value: false }));
      dispatch(SetStripeState({ field: 'message', value: '' }));
      return;
    }
  }, [success, message]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error
      });
      setPmntBtnClicked(false)
      dispatch(SetStripeState({ field: 'error', value: '' }));
    }
  }, [error]);

  useEffect(() => {
    if (successSub && messageSub) {
      Notification({
        type: "success",
        title: "Success",
        description: 'Subscription Created Successfully.'
      });
      dispatch(SetSubscriptionState({ field: 'success', value: false }));
      dispatch(SetSubscriptionState({ field: 'message', value: '' }));
      history.push('/auth/congratulations')
    }
  }, [messageSub, successSub]);

  useEffect(() => {
    if (errorSub) {
      Notification({
        type: "error",
        title: "Error",
        description: errorSub
      });
      setPmntBtnClicked(false)
      dispatch(SetSubscriptionState({ field: 'error', value: '' }));
      history.push('/auth/subscription-plan')
    }
  }, [errorSub]);

  const [form] = Form.useForm();

  return (
    <StoreWrapper>
      <PageHeader />
    <PaymentWrapper>
      <Form name="login" form={form} layout="vertical">
        <div className="d-flex justify-content-between credentials-wrapper align-items-center">
          <h2 className="m-0">Credit Card Credentials</h2>
          <FaStripe className="payment-strip-logo" />
        </div>
        <div className="d-flex align-items-center stripform-item-wrapper">
          <div className="strip-form-item d-flex flex-column">
            <Form.Item
              name="cardName"
              rules={[{ message: "Please Input Your Name!", required: true }]}
              label="Name On Card"
            >
              <Input
                type="text"
                placeholder="Name Here"
                value={name}
                onChange={(e) => handleName(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="strip-form-item d-flex flex-column position-relative">
            <div className="credit-card-input-wrapper">
              <Form.Item
                name="Number"
                rules={[
                  { message: "Please Input Your Card Number!", required: true },
                ]}
                label="Card Number"
              >
                <CardNumberElement
                  className="strip-elements-custom"
                  options={CardNumberOptions}
                  onChange={(e) => handleCardValidationError(e)}
                />
              </Form.Item>
              <div className="card-images"></div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center stripform-item-wrapper strip-form-date-expire">
          <div className="strip-form-item d-flex flex-column">
            <Form.Item
              className="strip-elements-custom"
              name="expirationDate"
              rules={[
                {
                  message: "Please Input Your Expiration Date!",
                  required: true,
                },
              ]}
              label="Expiration Date"
            >
              <CardExpiryElement
                options={ExNumberOptions}
                onChange={(e) => handleCardValidationError(e)}
              />
            </Form.Item>
          </div>
          <div className="strip-form-item d-flex flex-column">
            <Form.Item
              name="cvc"
              rules={[{ message: "Please Input Your CVC!", required: true }]}
              label="CVC"
            >
              <CardCvcElement
                className="strip-elements-custom"
                options={ExpiryOptions}
                placeholder="123"
                onChange={(e) => handleCardValidationError(e)}
              />
            </Form.Item>
          </div>
        </div>
        <div className="stripform-form-address new-feild-wrapper">
          <h2 className="m-0">Address</h2>
          <div className="d-flex align-items-center stripform-item-wrapper">
            <div className="strip-form-item d-flex flex-column">
              <Form.Item
                name="address"
                label="Address"
              >
                <Input
                  className="payment-form-address"
                  type="text"
                  placeholder="Address Here"
                  name='billingAddress'
                  value={address.line1}
                  onChange={(e) => handleAddress(e.target.value, 'line1')} 
                />
              </Form.Item>
            </div>
            <div className="strip-form-item d-flex flex-column">
              <Form.Item
                name="country"
                label="Country"
              >
                <Input
                  className="payment-form-address"
                  type="text"
                  name='country' 
                  placeholder="Select country"
                  value={address.country}
                  onChange={(e) => handleAddress(e.target.value, 'country')}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <div className="footer-wrapper">
        <Button onClick={() => handleSubmit()} disabled ={pmntBtnClicked}>Save</Button>
      </div>
      <Modal
        className="payment-success-modal cancel-modal"
        open={cancelTransaction}
        closeText="Close"
        onSave={() => setCancelTransaction()}
        onClose={() => setCancelTransaction(false)}
        width={440}
      >
        <div className="add-card-popup-wrapper">
          <FiAlertCircle className="check-rounded alert-pop-add-card" />
          <h1 className="m-0 modal-credit-card-heading">Payment Failed</h1>
          <p className="m-0 modal-credit-card-text">
            Please Check the information on your credit card and try again.
            Contact your card issuer for more information.
          </p>
        </div>
      </Modal>
      <Modal
        className="payment-success-modal cancel-modal"
        open={successTransaction}
        closeText="Close"
        onSave={() => setSuccessTransaction()}
        onClose={() => setSuccessTransaction(false)}
        width={440}
      >
          <div className="add-card-popup-wrapper">
            <BsCheckCircle className="check-rounded" />
            <h1 className="m-0 modal-credit-card-heading">Credit Card Added Successfully</h1>
            <p className="m-0 modal-credit-card-text">Your credit card has been successfully and securely added for future billings.</p>
          </div>
      </Modal>
    </PaymentWrapper>
    {(loadingSub || loading) && <Spin />}
    </StoreWrapper>
  );
};

const StripePaymentForm = ({ type }) => (
  <Elements stripe={stripePromise}>
    <CheckoutForms type={type} />
  </Elements>
);
export default StripePaymentForm;
