import styled from "styled-components";

const SubscriptionPlanCardWrapper = styled.div`
height: fit-content;
.qf-segment-wrapper{
  .ant-segmented-item:hover, .ant-segmented-item:focus{
    color: #0057d3;
  }
  .ant-segmented-item-label {
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
}
  label.ant-segmented-item.ant-segmented-item-selected {
    color: #0057d3;
}}
 .card-header-subscription{
  /* padding: 0px 6px; */
  align-items: baseline !important;
 div{
  &:first-child{
       width: 100%;
    /* padding-left: 2px; */
    input{
          border: 1px solid #a79090;
          width:100%;
    }
  }
 }
   button{
     width: fit-content;
    margin: 0px !important;
    /* margin-right:5px !important; */
   }
 }
.card-overlay-wrapper{
  cursor: pointer;
  border: 1px solid #0057d3;
  background-color: #fff;
  border-radius:12px;
  padding: 16px 24px;
  margin: 8px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${({ isEmptyStyle }) => isEmptyStyle ? '570px' : '500px'};
  &.card-overaly {
    border: 2.5px solid green;
}
  button.ant-switch {
    position: absolute;
    top: -5px;
    right: 7px;
    min-width: 76px !important;
    height: 23px;
    width: 67px;
}
  .card-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 8px;
    padding: 24px;
    position:relative;
    .coupon-ui-wrapper{
    width: 209px;
    background: green;
    position: absolute;
    left: -73px;
    top: 34px;
    transform: rotate(313deg);
    color: #fff;
    font-weight: 600;
    padding: 4px 8px;
    font-size: 12px;
    text-align: center;
    }
    .active-badge-ui-wrapper{
    width: 209px;
    background: green;
    position: absolute;
    right: -78px;
    top: 34px;
    transform: rotate(50deg);
    color: #fff;
    font-weight: 600;
    padding: 4px 8px;
    text-align: center;
    }
    h3{
      font-size:32px;
      margin: 0px;
      color:#0057d3;
      font-weight: 900;
    }
    p{
      font-size:16px;
      margin: 0px;
    }
    p.highlight{
      color: blue;
      text-decoration: underline;
    }
    .price-wrapper{
      display: flex;
      align-items: baseline;
      p{
        font-size: 48px;
        font-weight: 900;
        color:#0057d3;
      }
    }
  }
  h6{
    font-size: 18px;
    font-weight: 700;
  }
  ul{
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-top: 8px;
    grid-row-gap: 4px;
    /* max-height: 400px; */
    overflow: auto;
    li{
      list-style: none;
      font-weight:500;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
}
svg{
    font-size: 24px;
    position: absolute;
    bottom: 24%;
    right: 12px;
    animation: arrowDownAnimation 1s infinite .5s alternate cubic-bezier(0.73, 1.01, 0.68, 1.03);
    color:#0057d3;

}
button{
  display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    margin: auto;
    font-weight: 900;
    font-size: 24px;
    padding: 3px 24px;
    width: 100%;
    height: auto;
    margin-top:16px !important;
    border-color: #0057d3;
    color:#0057d3 !important;
    &.button-wrapper {
    background: transparent;
    border: none;
    font-size: 16px !important;
    border-bottom: 3px solid;
    width: auto !important;
    padding: 0 0 !important;
    &:hover{
      background: transparent;
    border: none;
    font-size: 16px !important;
    border-bottom: 3px solid #0057d3;
    color:#0057d3 !important;
    }
}
    &:hover{
      background-color: #0057d3;
      color: #fff !important;
    }
}
@keyframes arrowDownAnimation {
  from{
    transform: translateY(0px);
  }
  to{
    transform: translateY(60px);
  }
}
`

export { SubscriptionPlanCardWrapper }