import styled from "styled-components";

const VerificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 36px;
  .footer-wrapper {
    display: flex;
    grid-gap: 6px;
    align-items: baseline;
    p {
    color: #5A5F7D;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
}
.verify-link {
    color: #5F63F2;
    font-family: Inter;
    font-size: 14px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    cursor: pointer
}
  }
`;
const LoginStyleWrapper = styled.div`
.login-input-wrapper{
  label{
    padding:0 ;
    &::after {
    display: none;
}
  }
}


`;
const StepperStyleWrapper = styled.div`
.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
    max-width: 900px;
    margin: auto;
    padding-top: 32px;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #2a68cc;
}
.ant-steps-item-process .ant-steps-item-icon {
    background-color: #fff;
    border-color: #2a68cc;
}
.ant-steps-item-wait .ant-steps-item-icon {
    background-color: #fff;
    border-color: rgb(42 104 204);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #2a68cc;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #2a68cc;
    font-weight: 600;
    font-size: 16px;
}



`;
const SelectPlanWrapper = styled.div`
.right-side-content {
    display: flex;
    align-items: baseline;
    grid-gap: 16px;
    position: relative;
    padding: 32px 62px 0px;
    &.profile-side-content {
    padding: 32px 32px 0px;
    justify-content: space-between;
}
    svg {
    font-size: 24px;
    color: #0058d4;
    position: absolute;
    left: 31px;
    top: 35px;
    cursor: pointer;
}
}
  .coupon-button {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    border: 1px solid rgb(0, 87, 211);
    padding: 7px 22px;
    border-radius: 4px;
    font-weight: 700;
    background: transparent;
    color: rgb(0, 87, 211);
    &:hover{
      background-color: rgb(0, 87, 211);
      color:#fff;
    }
}
.cards-overlay-wrapper{
display: grid;
grid-gap: 8px;
grid-template-columns: 1fr 1fr 1fr 1fr;
justify-content: space-between;
padding: 24px;
height: calc(100vh - 60px);
overflow: auto;
}
`
export { VerificationWrapper,LoginStyleWrapper, StepperStyleWrapper, SelectPlanWrapper };
