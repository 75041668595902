import React, { useState } from 'react';
import { Button, message, Steps, theme } from 'antd';
import SignIn from './sign-in'
import { StepperStyleWrapper } from './style';

const steps = [
  {
    title: 'First',
    content: '',
  },
  {
    title: 'Second',
    content: 'Second-content',
  },
  {
    title: 'Last',
    content: 'Last-content',
  },
];

const Stepper = () => {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <StepperStyleWrapper>
      <Steps current={current} items={items} />
      <div>{steps[current].content}</div>
      <div style={{ marginTop: 24 }}>
        {/* {current < steps.length - 1 && (
          <Button type="primary" onClick={next}>
            Next
          </Button>
        )} */}
      </div>
    </StepperStyleWrapper>
  );
};

export default Stepper;
