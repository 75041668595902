import styled from "styled-components";

const PaginationWrapper = styled.div`
  height: 54px;
  background: ${(props) => props.background};
  padding: 24px 16px;
  .pagination-wrapper {
    width: 100%;
    .pagination-items-wrapper {
      .pagination-item {
        background: transparent;
        margin: auto 5px;
        padding: 1px 7px;
        border-radius: 2px;
        font-size: 13px;
        color: #515d70;
        cursor: pointer;
        border: 1px solid transparent;
        &.active {
          background: ${({ theme }) => theme["link-color"]};
          color: ${({ theme }) => theme["white-color"]};
        }
        &:focus,
        &:hover {
          border: 1px solid #2589ff;
          color: #2589ff;
        }
      }
    }
    .pagination-icon {
      background: #fff;
      margin: auto 5px;
      padding: 1px 7px;
      color: ${({ theme }) => theme["link-color"]};
      font-size: 26px;
      cursor: pointer;
      border: 1px solid transparent;
      &:focus,
      &:hover {
        border: 1px solid #2589ff;
        color: #2589ff;
      }
    }
  }

  input {
    width: 50px;
    border-radius: 2px;
    margin-left: 8px;
    margin-right: 6px;
    min-width:50px;
    padding: 5px 12px;
    background:transparent;
    &:focus{
        background-color:transparent;
    }
  }
  .pagination-inputs-wrappper select {
    background-color: red;
  }
  .select2-container .select2-selection__control {
    border:none;
    max-height: 32px;
    min-height: 32px;
    background: transparent;
  }
  .counter-label {
    color: #515d70;
    width: 40px;
    display: block;
  }
  .select2-container
    .select2-selection__control
    .select2-selection__placeholder {
    color: #515d70;
  }
  select.drop-down-select.form-select {
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .icon-icon-drop:before {
    content: "\e904";
    color: #278aff;
    width: 13px;
    height: 13px;
    border: 2px solid #278aff;
    background: #ffff;
    border-radius: 23px;
    font-size: 6px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20px;
    right: -3px;
  }
  .table-pagination {
    margin:0;
    margin-right: -2px;
    .page-item {
      .page-link {
        border: none;
        color: ${({ theme }) => theme["label-color"]};
        font-size: ${({ theme }) => theme["base-font-size"]};
        padding: 7px 12px;
        margin-top: 0;
        background:transparent;
        svg {
          color: ${({ theme }) => theme["link-color"]};
          margin-top:3px;
        }
      }
      &.active {
        .page-link {
          background: ${({ theme }) => theme["link-color"]};
          color: ${({ theme }) => theme["white-color"]};
          border-radius: 2px;
        }
      }
    }
  }
`;
export { PaginationWrapper };
