import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

import Button from '../Button/Index';
import { ModalWrapper } from './style';

const Index = (props) => {
  const {
    open,
    onHide,
    onClose,
    onSave,
    header,
    heading,
    className,
    closeText,
    saveText,
    size,
    closeButton,
    footerButton,
    primaryButton,
    canclebtnVariant,
    savebtnVariant,
    width
  } = props;
  return (
    <ModalWrapper
      size={size}
      show={open}
      onHide={onHide}
      className={className}
      centered={true}
      width={width}
    >
      {header && (
        <Modal.Header closeButton={closeButton}>
          <Modal.Title className="fw-bold">{heading}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className="p-4">{props.children}</Modal.Body>

      <Modal.Footer className={footerButton}>
        {closeText && (
          <Link
            to="#"
            className={`text-decoration-none btn-cancel`}
            onClick={onClose}
          >
            {closeText}
          </Link>
        )}
        {saveText && (
          <Button className={`btn-save ${primaryButton}`} onClick={onSave}>
            {saveText}
          </Button>
        )}
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default Index;
