import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import VerificationImg from "../../assets/images/verification-img.svg";
import Notification from '../../component/notifications/notification';
import Spin from '../../component/Spin';

import {
  ForgetPassword,
  SetAuthState
} from '../../redux/slices/auth-slice';

import EmailVerification from "./layout";

const Index = () => {
  const dispatch = useDispatch();
  const {
    error,
    loading,
    success,
    message,
    resetPasswordEmail
  } = useSelector(
    (state) => state.auth
  );

  const handleClick = () => {
    dispatch(ForgetPassword({ email: resetPasswordEmail }));
  }

  useEffect(() => {
    if (success && message) {
      Notification({
        type: 'success',
        title: 'Success',
        description: message
      });

      dispatch(SetAuthState({ field: 'success', value: false }));
      dispatch(SetAuthState({ field: 'message', value: '' }));
      return;
    }

    if (error) {
      Notification({
        type: 'error',
        title: 'Error',
        description: error
      });
      dispatch(SetAuthState({ field: 'error', value: '' }));
      return;
    }
  }, [error, message, success]);

  return (
    <>
      <EmailVerification>
        <div className="auth-content-centered">
          <div className="email-verification-content">
            <h1 className="title">Reset Your Password</h1>
            <div className="auth-section-middle-content">
              <p>
                We have sent you an email with verification link, please click
                on that link to proceed further.
              </p>
              <div className="email-verification-wrapper">
                <img src={VerificationImg} alt="verification image" />
              </div>
              <p className="not-recive-text">
                If you didn’t receive the email please{" "}
                <a href="#" onClick={() => handleClick()}>Click Here</a>
              </p>
            </div>
          </div>
        </div>
      </EmailVerification>
      {
        loading && ( <Spin /> )
      }
    </>
  );
};

export default Index;
