import logger from 'redux-logger';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import authSlice from '../slices/auth-slice';
import bugSlice from '../slices/bug-slice';
import giftCardSlice from '../slices/gift-card-slice';
import productSlice from '../slices/product-slice';
import storeSlice from '../slices/store-slice';
import stripeSlice from '../slices/stripe-slice';
import subscriptionSlice from '../slices/subscription-slice';
import userSlice from '../slices/user-slice';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['auth', 'store', 'user']
};

const reducers = combineReducers({
  auth: authSlice,
  bug: bugSlice,
  giftCard: giftCardSlice,
  product: productSlice,
  store: storeSlice,
  stripe: stripeSlice,
  subscription: subscriptionSlice,
  user: userSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/LogOut') {
    state = undefined;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk, logger],
  devTools: true
});
