import styled from "styled-components";
const ReferralsWrapper = styled.div`
.balance-card-ui-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 24px;
    .balance-card {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    border: 1px solid #2a68cc;
    padding: 0px 8px;
    border-radius: 4px;
    h6 {
    margin: 0px;
    font-size: 24px;
    font-weight: 600;
    color: green;
    &.zero-amount {
    color: #000 !important;
}
}
p {
    margin: 0px;
    font-size: 24px;
}
}
  .page-title {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    margin-bottom: 0px;
    color: #272b41;
  }
  .ag-row.ag-row-no-animation {
  transform: none !important; /* removes the translation */
}
  .user-page-header {
    grid-gap: 16px;
  }
  .export-btn-users {
    background: #0057d3;
    color: #ffff;
    border: 1px solid #0057d3;
  }
  .d-flex.action-icon-wrapper {
    grid-gap: 16px;
    img{
      cursor: pointer;
    }
}
.pagination-wrapper.d-flex.align-items-center.position-relative {
    margin-top: 4px;
}
.filter-clear{
  color: #0057D3;
  &.disabled{
      color:#7C8092;
      cursor: not-allowed;
      pointer-events: none;
      svg{
          cursor: not-allowed;
          pointer-events: none;
      }
  }
}
}
`;
export { ReferralsWrapper };
