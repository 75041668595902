import Cookies from 'universal-cookie';
import axios from 'axios';

import Notification from '../component/notifications/notification';

import { LogOut } from '../redux/slices/auth-slice';

import store from '../redux/store'

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

axios.interceptors.response.use(config => config, (error) => {

  const cookie = new Cookies();

  const { response } = error;

  const originalRequest = error.config;
  if (response) {
    if (response.status === 401) {

      store.dispatch(LogOut());

      const token = cookie.get('qikFindsToken');

      if (token) {
        Notification({
          type: "error",
          title: "Error",
          description: 'You are login on a new device',
          key: 'You are login on a new device'
        });
      }
      
      cookie.remove('qikFindsToken', { path: '/'});
      cookie.remove('keepaKey', { path: '/'});
      cookie.remove('isStoreExists', { path: '/'});
      
      return Promise.reject(error);
    } else if (response?.data?.error?.includes('session has been expired')) {
      store.dispatch(LogOut());

      const token = cookie.get('qikFindsToken');

      if (token) {
        Notification({
          type: "error",
          title: "Error",
          description: 'Your session has been expired.',
          key: 'Your session has been expired.'
        });
      }
      
      cookie.remove('qikFindsToken', { path: '/'});
      cookie.remove('keepaKey', { path: '/'});
      cookie.remove('isStoreExists', { path: '/'});
      
      return Promise.reject(error);
    }

    if (response.status === 200 || response.status === 201){
      return Promise.resolve(originalRequest)
    }

    return Promise.reject(error);
  }
});

const axiosBaseUrl = () => {
  axios.defaults.baseURL = process.env.API_URL;

  return axios;
};

export {
  setAuthToken,
  axiosBaseUrl
};
