import React from "react";
import {
  BsSearch,
  BsArrowReturnRight,
} from "react-icons/bs";
import { Form } from "react-bootstrap";

import { InputWrapper } from "../style";
const Index = (props) => {
  const {
    label,
    type,
    placeholder,
    inputStatus,
    onChange,
    required,
    value,
    name,
    onBlur,
    autoComplete,
    disabled,
    as,
    rows,
    width,
    height,
    search,
    searchwitharrow,
    marginBottom,
    className
  } = props;

  return (
    <InputWrapper width={width} height={height} marginBottom={marginBottom} className={className}>
      <Form.Group className="position-relative input-wrapper login-input-wrapper">
        {label && <Form.Label>{label}</Form.Label>}
        <span className="position-relative input-container">
          <Form.Control
            autoComplete={autoComplete}
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            required={required}
            onBlur={onBlur}
            disabled={disabled}
            className={className}
          />
          {search && <BsSearch className="cursor-pointer search-icon" />}
          {searchwitharrow && (
            <BsArrowReturnRight className="cursor-pointer search-icon" />
          )}
        </span>
      </Form.Group>
    </InputWrapper>
  );
};

export default Index;
