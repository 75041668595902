import Styled from "styled-components";
const HeaderWrapper = Styled.div`
/* height:56px; */
position:relative;
padding:16px 35px;
z-index:1;
&:before{
    content:'';
    height:100%;
    width:100%;
    /* background: linear-gradient(90deg, #04949D 28.13%, #0057D3 100%); */
    background-color:#161924;
    position:absolute;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index:-1;
    top:0;
    left:0;
}

&.auth-header{
    .right-section{
        display: none!important;
    }
}
.profile-menu-item{
    gap:12px;
    h3{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #161924;
    margin-bottom:2px;
    }
    p{
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #7C8092;
    margin-bottom:0;
    }
}
.dropdown{
    .dropdown-menu{
    padding: 8px;
    margin-top: -1px;
    }
    .dropdown-item{
        padding:0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #000000;
        &:hover{
            background-color:transparent;
        }
        svg{
            font-size:16px;
            color:${({ theme }) => theme["link-color"]};
            position: relative;
            top: -1px;
        }
    }
    .dropdown-divider{
        margin-top:16px;
        margin-bottom:8px;
    }
    .dropdown-toggle{
    &.btn-primary{
        background:none;
        padding:0;
        border:none;
        .icon-chevron-down{
            position:absolute;
            display:none;
        }
    }
    &:after{
        display:none;
    }
}
}
.logo{
    /* background-color:${({ theme }) => theme["white-color"]};
    height:32px;
    width:32px;
    border-radius:12px; */
    cursor: pointer;
    img{
        width: 203px;
    }
}
.tab-list{
    margin-left:0;
    span{
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color:${({ theme }) => theme["white-color"]};
        font-size:${({ theme }) => theme["base-font-size"]};
        /* padding: 6px 25px; */
        padding: 6px 0px;
        padding-right: 0px;
        margin-right: 55px;
        letter-spacing: 0.05em;
        cursor:pointer;
        &:last-of-type{
            /* margin-right:0; */
        }
        &:hover,
        &.active{
            border-bottom:1px solid #ffffff;
        }
    }
}
.right-section{
    grid-column-gap:23px;
    svg{
        color:${({ theme }) => theme["white-color"]};
        font-size:18px;
    }
}

`;
export { HeaderWrapper };
