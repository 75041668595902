import Styled from "styled-components";
const OverlayProfile = Styled.div`
    height: calc(100vh - 82px);
    overflow: auto;
    padding-bottom: 16px;
`;
import Tile from "../../assets/images/tiles.svg";
const ManageAccountWrapper = Styled.div`
  height: calc(100vh - 79px);
    overflow: auto;
    .copy-referral-button {
    border: none;
    font-size: 12px;
    color: #0057D3;
    font-weight: 700;
    padding: 0 12px;
    cursor:pointer;
}
.content-overlay-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.btn{
    &:hover{
        background: #0057D3;
        color:#fff;
    }
}
.disable-input-wrapper {
    cursor: not-allowed;
}
    .actions-button-wrapper{
        display:flex;
        align-items:center;
        grid-gap:8px;
    }
.page-title{
    font-weight: 900;
font-size: 24px;
line-height: 28px;
/* identical to box height, or 117% */

letter-spacing: 0.02em;
}
.small-badges{
    grid-column-gap: 21px;
    align-items: end;
    margin-right:3px;
    margin-top:4px;
    &.userdashboard-badges {
        margin-bottom:9px;
    }
}
.selected-plain-wrapper-ui{
    position:relative;
    overflow:hidden ;
    span.bag-wrapper {
    position: absolute;
    right: -21px;
    top: 16px;
    -webkit-transform: rotate(39deg);
    -ms-transform: rotate(39deg);
    transform: rotate(39deg);
    background: green;
    color: #fff;
    padding: 4px 39px;
    font-weight: 700;
    border-radius: 4px;
    font-size: 13px;
}
}
.currently-used{
    position:absolute ;
    background-color:${({ theme }) => theme["white-color"]};
    padding:${({ theme }) => theme["base-padding"]};
    right:0;
    border-radius: 8px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);
    min-width:856px;
    top:35px;
    padding-bottom: 27px;
    z-index:1;
    .paragrpagh{
        letter-spacing: 0.03em;
        font-size:${({ theme }) => theme["xs-small-size"]};
        font-style: italic;
        font-weight:400;
        margin-left:-1px;
        color:${({ theme }) => theme["secondary-color"]} ;
    }
    .border{
        margin: 6px 0;
        margin-bottom:9px;
    }
    h3{
        letter-spacing: 0.04em;
        margin-bottom:7px;
    }
    .form-check{
        input[type="checkbox"]{
            width:12px;
            height:12px;
            left: -1px;
            top: 1px;
            margin-right:11px;
        }
    }
    button{
        margin-top:-1px;
        padding-left: 22px;
        padding-right: 22px;
    }
}
.daterange-dropdown{
    padding:0;
    .daterange-footer{
      text-align:right;
      padding:16px 24px;
      border-top:1px solid #E6E8F0;
      .btn.btn-link{
        border:0px;
        text-decoration:none;
      }
      button{
          padding:7px 24px;
          margin-bottom:0;
          margin-right:16px;
          &:last-of-type{
            margin-right:0;
          }
        }
    }
}
.filter-box{
  position:absolute ;
  background-color:${({ theme }) => theme["white-color"]};
  padding:10px 13px;
  right:0;
  border-radius: 8px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);
  min-width:192px;
  top:35px;
  z-index:1;
}
.header-divider{
    margin-top:5px;
    height: 1px;
    background-color: #DFE5EC;
    border: none;
}
.cards-box{
    margin-top:26px ;
    .cards{
    background-color:${({ theme }) => theme["white-color"]};
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding:22px 23px 16px 25px;
    margin-bottom:24px;
    .title{
        padding-left: 8px;
    padding-top: 4px;
    letter-spacing: 0.03rem;
    }
    .card-box-icon{
        margin-top:-5px;
    }
    .cards-info{
        margin-top:10px;
        .total-days{
        font-size:${({ theme }) => theme["base-font-size"]};
        &.sales-rating{
                font-size:11px;
                .days{
                    font-size:9px;
                }
        }
        .days{
            margin-left:6px;
            /* margin-top: -4px; */
            margin-top: -2px;
        }
        .rating {
            font-weight:bold;
            &.success{
            color:${({ theme }) => theme["success-color"]} ;
            }
            &.danger{
                color:${({ theme }) => theme["danger-color"]} ;
            }
            svg{
                font-size:14px;

            }
        }
    }
    h2{
        font-weight:900!important;
        color:${({ theme }) => theme["secondary-color"]} ;
    }
    }

}
}
.sales-report{
    .avg-ctr{
    background-color:${({ theme }) => theme["white-color"]};
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
}
}
.sales-ads{
    background-color:${({ theme }) => theme["white-color"]};
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding:21px 23px 17px 23px ;
    margin-bottom:24px;
    border: 1px solid #E6E8F0;
    .grey-box{
        background:#D9D9D9 ;
        width:24px;
        height:24px;
        border-radius: 50%;

    }
    .label-color{
        margin-left: 11px;
        letter-spacing: 0.04em;
    }
    .progress-details{
        position:absolute;
        top: 50px;
        left: 57px;
        .total-days{
        font-size:${({ theme }) => theme["base-font-size"]};
        margin-top:2px;
        letter-spacing: 0.02em;
        &.sales-rating{
                font-size:11px;
                .days{
                    font-size:9px;
                }
        }
        .days{
            margin-left:6px;
            margin-top: -5px;
        }
        .rating {
            font-weight:bold;
            &.success{
            color:${({ theme }) => theme["success-color"]} ;
            }
            &.danger{
                color:${({ theme }) => theme["danger-color"]} ;
            }
            svg{
                font-size:19px;

            }
        }
    }
    }

}
.spend-grpah{
    background-color:${({ theme }) => theme["white-color"]};
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);
    border: 1px solid #DFE5EC;
    border-radius: 8px;
    padding:24px ;
    margin-bottom:25px;
    .progress-details{
        margin-left:7px;
        .total-days{
        font-size:${({ theme }) => theme["base-font-size"]};
        margin-top:4px;
        letter-spacing: 0.02em;
        &.sales-rating{
                font-size:11px;
                .days{
                    font-size:9px;
                }
        }
        .days{
            margin-left:13px;
            margin-top: -5px;
        }
        .rating {
            font-weight:bold;
            margin-top:-3px;
            &.success{
            color:${({ theme }) => theme["success-color"]} ;
            }
            &.danger{
                color:${({ theme }) => theme["danger-color"]} ;
            }
            svg{
                font-size:19px;
                padding-left: 7px;

            }
        }
    }


}
}
.pie-charts{
    background-color:${({ theme }) => theme["white-color"]};
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);
    border: 1px solid #DFE5EC;
    border-radius: 8px;
    padding: 25px;

}

.summary{
    background-color:${({ theme }) => theme["white-color"]};
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);
    border: 1px solid #E6E8F0;
    border-radius: 8px;
    padding:25px 23px;
    /* padding:25px 23px 314px 22px; */
    margin-bottom: 24px;
    .title{
        color:${({ theme }) => theme["label-color"]};
        h6{
            margin-right: 7px;
            letter-spacing: 0.04em;
            font-weight:700;
        }
    }
    .summary-badges{
        margin-top:-2px;
    }
}
.table-box{
    background-color:${({ theme }) => theme["white-color"]};
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);
    border: 1px solid #E6E8F0;
    padding:26px 38px 35px 22px;
    border-radius: 8px;
    h3{
        letter-spacing: 0.04em;
    }
    .button-group{
        margin-right: 15px;
    margin-top: -2px;
    }
    &.space-change{
        padding:24px 38px 35px 22px;
    }
}
.type-badge{
    font-size:${({ theme }) => theme["xs-small-size"]};
    border-radius:50px;
    padding:3px 8px ;
    &.product{
    background-color:${({ theme }) => theme["malibu-color"]}05;
    border:1px solid ${({ theme }) => theme["malibu-color"]};
    color:${({ theme }) => theme["malibu-color"]};
    }
    &.brand{
    background-color:${({ theme }) => theme["saffron-color"]}05;
    border:1px solid ${({ theme }) => theme["saffron-color"]};
    color:${({ theme }) => theme["saffron-color"]};
    }
    &.video{
    background-color:${({ theme }) => theme["cinnabar-color"]}05;
    border:1px solid ${({ theme }) => theme["cinnabar-color"]};
    color:${({ theme }) => theme["cinnabar-color"]};
    }
    &.display{
    background-color:${({ theme }) => theme["dullLavender-color"]}05;
    border:1px solid ${({ theme }) => theme["dullLavender-color"]};
    color:${({ theme }) => theme["dullLavender-color"]};
    }

}
.search-input{
    margin-top:22px;
}
.filter-icon{
    margin-right: 5px;
    margin-top: 6px;
}
.mt-23{
    margin-top:23px;
}
.setting-heading{
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
}
.setting-page{
    background:${({ theme }) => theme["white-color"]};
    border: 1px solid #E6E8F0;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 24px 22px;
    margin-top:14px;
    a{
    text-decoration:none;
    }
    .settings-title-bar{
        button{
            padding:7px 23px;
        }
        h6{
            margin-bottom:0;
        }
    }
    .add-bottom-spacing{
        margin-bottom:16px;
    }
    .form-label{
        margin-bottom:8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
    }
    .profile-caption{
        color:${({ theme }) => theme["label-color"]};
        margin-left: 5px;
        font-size: 11px;
        letter-spacing: 0.02em;
        margin-top: 3px;

    }
    .mb-20{
        margin-bottom:20px;
    }
    .input-wrapper{
        margin-bottom:16px;
    }
    .setting-page-divider{
        margin-top:16px;
        margin-bottom:25px;
    }
        .authorize-card{
            max-width:392px;
            border-radius:12px;
            padding: 24px 23px 19px 21px;
            min-width:392px;
            border:1px solid #E6E8F0;
            background-image:url(${Tile});
            background-repeat: no-repeat;
            .authorize-card-header{
                margin-bottom:18px;
            }
            .icon-box{
                display: flex;
                grid-column-gap: 17px;
                padding-top: 5px;
                font-size:16px;
                .edit{
                    color:${({ theme }) => theme["link-color"]};    
                }
                .trash{
                    color:${({ theme }) => theme["danger-color"]};  
                }

            }
            .country-info{
                margin-bottom:4px;
                h2{ 
                    letter-spacing: 0.02em;  
                    margin-bottom:0;
                }
                h3{
                    color:${({ theme }) => theme["label-color"]};  
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.04em;  
                    margin-bottom:0;
                }
            }
            .badge{
                   border-radius:10px;
                   padding:6px 13px;
                   &.active{
                       background:${({ theme }) => theme["success-color"]} ;
                   }
                   &.inactive{ 
                       background:${({ theme }) => theme["warning-color"]} ;  
                   }
               }
               .store-name{
                margin-bottom:12px;
               }
            &.active{
                padding:23px 23px 12px 21px;
                background: linear-gradient(90deg, #1565D8 28.13%, #1565D8 100%);
                .icon-box{
                svg{
                    color:${({ theme }) => theme["white-color"]};  
                }
            }
            .country-info{
                h2,h3{
                    color:${({ theme }) => theme["white-color"]};  
                }
            }
            .store-name{
                   h2{
                    color:${({ theme }) => theme["white-color"]}; 
                    font-weight: 900;
                    font-size: 24px;
                    line-height: 28px;
                    letter-spacing: 0.02em;
                   }
               }
          
            }
            .store-name{
                    h2{
                    color:${({ theme }) => theme["white-color"]}; 
                    font-weight: 900;
                    font-size: 24px;
                    line-height: 28px;
                    letter-spacing: 0.02em;
                   }
            }
        } 
}
`;
const CardStylingWrapper = Styled.div`
.blur-effect {
    filter: blur(6px);
}
span svg {
    font-size: 18px;
    color: #fff;
    margin-left: 16px;
}

`;
export { ManageAccountWrapper, OverlayProfile, CardStylingWrapper };
