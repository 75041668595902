import 'antd/dist/antd.css';
import { notification } from 'antd';

const Notification = ({ type, title, description, key }) => {
    notification[type]({
      message: title,
      description: description,
      top: 65,
      key
    });
  };

export default Notification;
