import styled from "styled-components";
import { Alert } from "react-bootstrap";
import closeIcon from "../../assets/images/closeIcon.svg";

const ToasterWrapper = styled(Alert)`
  background-color: ${({ theme }) => theme["white-color"]};
  border: 1px solid ${({ theme }) => theme["label-color"]};
  border-radius: 4px;
  padding:8px 12px;
  margin-top:24px;
  p {
    margin-bottom: 0;
  }
  a{
    border-bottom:1px solid;
  }
  &.alert-danger {
    background-color: ${({ theme }) => theme["white-color"]};
    color:#FF4D4F;
    .btn-close{
        color:#FF4D4F;
    }
    a{
        border:none;
        color:#FF4D4F;
        display:inline-block;
        &:hover{
          text-decoration: underline;
        }
    }
  }
  .close-icon{
    position:absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    cursor: pointer;
  }
  .info-icon{
    margin-right:8px;
    margin-top:-1px;
  }
  .btn-close{
    display:none;
  }
`;
export { ToasterWrapper };
