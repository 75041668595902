import React from 'react';
import Form from 'react-bootstrap/Form';
import { BsSearch } from "react-icons/bs"

import { SearchInputWrapper } from "./style"

const Index = (props) => {
  const { placeholder, large, className, onChange, value } = props;
  return (
    <SearchInputWrapper className={className} large={large ? 1 : 0}>
      <Form.Control
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      <BsSearch className='link-text' />
    </SearchInputWrapper>
  )
}
export default Index;
