import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const DeleteBug = createAsyncThunk(
  'products/deleteBug',
  async (data, thunkAPI) => {
    try {
      const { bugId } = data;
      const response = await axios.post('/bug/delete-bug', {
        bugId
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const UpdateBugStatus = createAsyncThunk(
  'bugs/updateBugStatus',
  async (data, thunkAPI) => {
    try {
      const { bugId } = data;
      const response = await axios.post('/bug/update-bug-status', {
        bugId
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetUserBugs = createAsyncThunk(
  'products/getUserBugs',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/bug/get-user-bugs', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const bug = createSlice({
  name: 'bugs',
  initialState: {
    success: false,
    loading: false,
    bugs: [],
    totalBugs: 0,
    error: '',
    message: ''
  },
  reducers: {
    SetBugState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    clearState(state, action) {
      return {
        ...state
      };
    }
  },
  extraReducers: {
    [DeleteBug.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [DeleteBug.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message
    }),
    [DeleteBug.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [GetUserBugs.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetUserBugs.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      totalBugs: action.payload.totalBugs,
      bugs: action.payload.bugs
    }),
    [GetUserBugs.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [UpdateBugStatus.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [UpdateBugStatus.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message
    }),
    [UpdateBugStatus.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    })
  }
});

const { reducer, actions } = bug;

export const {
  clearState,
  SetBugState
} = actions;

export default reducer;
