import moment from 'moment';

import React, { useState } from "react";
import PropTypes from "prop-types";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

import DateRangeWrapper from "./styled";

const DateRangeFilter = ({ onChange, rangeColors, startDate, endDate }) => {
  const { startDay, startMonth, startYear } = startDate;
  let sDate = { day: startDay, month: startMonth - 1, year: startYear };
  let eDate = moment().endOf('day').toDate();
  const { endDay, endMonth, endYear } = endDate || {};
  if (endDay) {
    eDate = { day: endDay, month: endMonth - 1, year: endYear };
  }

  sDate = moment(sDate).startOf('day').toDate();
  eDate = moment(eDate).endOf('day').toDate();

  const [state, setState] = useState([
    {
      startDate: sDate,
      endDate: eDate,
      key: "selection",
    },
  ]);

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  };

  return (
    <DateRangeWrapper>
      <DateRangePicker
        onChange={handleOnChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        rangeColors={rangeColors}
        direction="horizontal"
        startDatePlaceholder={startDate}
        endDatePlaceholder={endDate}
      />
    </DateRangeWrapper>
  );
};

DateRangeFilter.propTypes = {
  onChange: PropTypes.func,
};

export default DateRangeFilter;
