import styled from "styled-components";

const BugsPageWrapper = styled.div`
    margin: 16px;
  .page-title {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #272b41;
  }
  .user-page-header {
    grid-gap: 16px;
  }
  .export-btn-users {
    background: #0057d3;
    color: #ffff;
    border: 1px solid #0057d3;
  }
  .d-flex.action-icon-wrapper {
    grid-gap: 16px;
    img {
      cursor: pointer;
    }
  }
  .bugs-sub-header {
    margin: 16px;
    svg {
      font-size: 19px;
      margin-top: 6px;
      margin-right: 8px;
    }
  }
  .secondary-url {
    color: #44a6c6;
    cursor: pointer;
  }
  .mark-as-resolved {
    padding: 3px;
    color: green;
    cursor: pointer;
    border: 1px solid grey;
    border-radius: 5px;

  }
`;
export { BugsPageWrapper };
