import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { GetUser } from '../redux/slices/user-slice';

const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    token,
    storeStatus,
    user,
    selectedPlan
  } = useSelector((state) => state.auth);

  const { user: userData } = useSelector(state => state.user);

  const [userStatus, setUserStatus] = useState('');

  const notCheckableRoutes = [
    '/auth/pricing',
    '/auth/pricing/',
    '/auth/sign-in',
    '/auth/sign-in/',
    '/auth/sign-up',
    '/auth/sign-up/',
    '/auth/payment',
    '/auth/payment/',
    '/auth/subscription-plan',
    '/auth/subscription-plan/'
  ];

  useEffect(() => {
    const route = location.pathname;
    if (!notCheckableRoutes.includes(route)) {
      dispatch(GetUser());
    }
  }, []);

  useEffect(() => {
    setUserStatus(userData?.status);
  }, [userData]);

  useEffect(() => {
    if (token) {
      if (user.role !== 'admin') {
        if (user && user.status === 'Registered' && user?.role !== 'sub-user' && user.role !== 'special-sub-user') {
          return history.push('/auth/verification');
        } else if (user && isEmpty(user?.payment) && location.pathname === '/auth/payment') {
          history.push(location.pathname);
        } else {
          history.push('/dashboard');
        }
      } else if (user.role === 'admin') {
        if (notCheckableRoutes.includes(location.pathname)) {
          history.push('/dashboard');
        } else {
          history.push(location.pathname);
        }
      }
    } else if (location.pathname === '/auth/pricing') {
      history.push('/auth/pricing')
    } else if (isEmpty(selectedPlan) && location.pathname === '/auth/sign-up') {
      history.push('/auth/pricing')
    } else if (location.pathname === '/auth/sign-up') {
      history.push('/auth/sign-up')
    } else {
      history.push('/auth/sign-in');
    }
  }, [token, userStatus, location.pathname]);

  return (
    <div>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </div>
  );
};

export default PublicRoute;
