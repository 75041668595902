import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";

import { axiosBaseUrl } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const CreateSubscription = createAsyncThunk(
  'subscription/createSubscription',
  async (data, { rejectWithValue }) => {
    try {
      const {
        impersonateUserId,
        priceId,
        subscriptionPlan,
        subscriptionCreatingFirstime,
        subscriptionType,
        couponCode,
        quantity,
        subscribedStores
      } = data || {};

      const response = await axios.post('/subscription/create-subscription-plan', {
        impersonateUserId,
        priceId,
        subscriptionPlan,
        subscriptionCreatingFirstime,
        subscriptionType,
        couponCode,
        quantity,
        subscribedStores
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetSubscriptionPlans = createAsyncThunk(
  'subscription/getSubscriptionPlans',
  async (data, { rejectWithValue }) => {
    try {
      const { productName } = data;

      const response = await axios.get('/subscription/get-subscription-plans', {
        params: {
          productName
        }
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetCouponDetails = createAsyncThunk(
  'subscription/getCouponDetails',
  async (data, { rejectWithValue }) => {
    try {
      const { couponCode } = data;

      const response = await axios.get('/subscription/get-coupon-details', {
        params: {
          couponCode
        }
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const subscription = createSlice({
  name: 'subscription',
  initialState: {
    error: '',
    loading: '',
    message: '',
    success: false,
    subscriptionPlans: [],
    coupon: {},
    subscriptionCreated: false
  },
  reducers: {
    SetSubscriptionState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [CreateSubscription.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [CreateSubscription.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message,
      subscriptionCreated: true
    }),
    [CreateSubscription.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [GetSubscriptionPlans.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [GetSubscriptionPlans.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      subscriptionPlans: action.payload.subscriptionPlans
    }),
    [GetSubscriptionPlans.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [GetCouponDetails.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [GetCouponDetails.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      coupon: action.payload,
      message: 'Coupon applied successfully!'
    }),
    [GetCouponDetails.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    })
  }
});

const { reducer, actions } = subscription;

export const { SetSubscriptionState } = actions;

export default reducer;
