import Cookies from 'universal-cookie';
import moment from 'moment';
import jwt from 'jsonwebtoken';
import { FaArrowLeft } from "react-icons/fa";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { FiAlertCircle } from 'react-icons/fi';
import ReCAPTCHA from "react-google-recaptcha"

import Button from "../../component/Button/Index";
import Input from "../../component/inputs/input/index";
import Notification from '../../component/notifications/notification';
import PasswordInput from "../../component/inputs/passwordInput/index";
import Spin from '../../component/Spin';

import SignUpLayout from "./layout";

import {
  SignUp,
  SetAuthState,
  keepaKeyValidation
} from '../../redux/slices/auth-slice';

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    error,
    loading,
    success,
    message,
    token,
    selectedPlan,
    keepaSuccess,
    keepaError,
    keepaValid, 
  } = useSelector((state) => state.auth);

  const queryParams = new URLSearchParams(useLocation().search);
  const queryToken = queryParams.get('token');
  const decodedToken = jwt.decode(queryToken, { complete: true });
  const {
    exp,
    email = ''
  } = decodedToken?.payload || {};

  if (exp && moment.unix(exp).isBefore(moment())) {
    history.push('/auth/sign-in');
  }

  const wrapperRef = useRef(null);

  const [userEmailFromInvitation, setUserEmailFromInvitation] = useState(email);
  const [userData, setUserData] = useState({
    firstName: 'N/A',
    lastName: 'N/A',
    email: 'N/A',
    password: '',
    company: 'N/A',
    keepaKey: 'N/A'
  });

  const captchaRef = useRef(null)

  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [keepaTooltip, setKeepaTooltip] = useState(false);
  const cookie = new Cookies();

  const handleChange = (field, value) => {
    setUserData({
      ...userData,
      [field]: value
    });
  };

  const isEmailValid = (email) => {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
  };

  const isPasswordValid = (password) => {
    const regEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    if (!regEx.test(password)) {
      return false;
    }
    return true;
  };

  const handleSignUp = async () => {
    const recaptchaVal = captchaRef.current.getValue();
    const partner_key = cookie.get('partnero_partner');
    const partnero_referral = cookie.get('partnero_referral');

    const {
      firstName,
      lastName,
      email,
      password,
      keepaKey
    } = userData || {};

    const emailVerify = userEmailFromInvitation || email;
    if ((!firstName || firstName === 'N/A')) {
      dispatch(SetAuthState({ field: 'error', value: 'firstName cannot be empty.' }));
    } else if ((!lastName || lastName === 'N/A')) {
      dispatch(SetAuthState({ field: 'error', value: 'lastName cannot be empty.' }));
    } else if (!emailVerify) {
      dispatch(SetAuthState({ field: 'error', value: 'email cannot be empty.' }));
    } else if (!isEmailValid(emailVerify)) {
      dispatch(SetAuthState({ field: 'error', value: 'Please Enter a valid email address.' }));
    } else if (!password) {
      dispatch(SetAuthState({ field: 'error', value: 'password cannot be empty.' }));
    } else if (password !== confirmPassword) {
      dispatch(SetAuthState({ field: 'error', value: 'Passwords donot match.' }));
    } else if (!isPasswordValid(password)) {
      dispatch(SetAuthState({ field: 'error', value: 'Password must contain 1 upper case letter, 1 lowercase letter, and minimum 8 characters long.' }));
    } else if (!recaptchaVal) {
      dispatch(SetAuthState({ field: 'error', value: "Captcha must not be empty!" }));
    } else {
      dispatch(SignUp({
        ...userData,
        email: userEmailFromInvitation || email,
        signUpViaInvite: userEmailFromInvitation ? true : false,
        partner_key,
        partnero_referral,
        selectedPlan
      }));
    }
  };

  const handleCloseFilterByClick = (wrapperRef, showGraphs) => {
    const interval = setInterval(() => {
      clearInterval(interval);
      document.addEventListener('mousedown', (e) => {
        const { shadowRoot } = e.target || {};
        let shadowRootContainer = e.target;
        let keepaImg;

        if (shadowRoot) {
          shadowRootContainer = shadowRoot;

          if (shadowRootContainer) {
            keepaImg = shadowRootContainer?.src || null;
          }
        }

        shadowRootContainer = e.target;
        if (!keepaImg && shadowRootContainer) {
          keepaImg = shadowRootContainer?.src || null;
        }

        const clickedInside = shadowRootContainer?.contains(wrapperRef?.current);

        if (clickedInside) {
          return;
        } else {
          setKeepaTooltip(false);
        }
      });
      document?.removeEventListener('mousedown', () => { });
    }, 50);
  };

  useEffect(() => {
    dispatch(SetAuthState({ field: "loading", value: false }));
  }, []);

  useEffect(() => {
    if (success && message) {
      // after sign up, user will go to payment page & then store connection flow and then set this cookie after 
      // successfull connection.

      const currentDate = new Date();
      const nextYear = new Date();

      nextYear.setFullYear(currentDate.getFullYear() + 1);
      cookie.set('qikFindsToken', token, {
        path: '/',
        expires: nextYear
      });

      const partner_key = cookie.get('partnero_partner');
      if (partner_key) {
        cookie.remove('partnero_partner', { path: '/' });
      }

      const partnero_referral = cookie.get('partnero_referral');
      if (partnero_referral) {
        cookie.remove('partnero_referral', { path: '/' });
      }

      history.push('/auth/payment');
      Notification({
        type: "success",
        title: "Success",
        description: 'Email verification link has been sent successfully.',
      });
      dispatch(SetAuthState({ field: "success", value: false }));
      dispatch(SetAuthState({ field: "message", value: "" }));
    }
  }, [success, message]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error
      });
      dispatch(SetAuthState({ field: "error", value: "" }));
    }
  }, [error]);

  return (
    <>
      <SignUpLayout>
        <h1 className="title">Account Sign Up</h1>
        <div className='subsription-status-wrapper'>
          <FaArrowLeft onClick={() => history.push('/auth/pricing')} /><p>Selected Plan: </p><p className='green'>
          {selectedPlan?.name} (${selectedPlan?.price}/{selectedPlan?.plan})
          </p>
        </div>
        <div className="auth-section-middle-content auth-section-signup-content">
          <Row>
            <Col md={6}>
              <Input
                label="First Name"
                placeholder="First Name"
                onChange={(e) => handleChange('firstName', e.target.value)}
                className={`validation-wrapper ${!userData.firstName ? 'mismatch-or-empty' : ''}`}
              />
            </Col>
            <Col md={6}>
              <Input
                label="Last Name"
                placeholder="Last Name"
                onChange={(e) => handleChange('lastName', e.target.value)}
                className={`validation-wrapper ${!userData.lastName ? 'mismatch-or-empty' : ''}`}
              />
            </Col>
            <Col md={12}>
            <label className='custom-label-wrapper-ui'>Email address</label>
              <Input
                placeholder="Enter email address"
                onChange={(e) => handleChange('email', e.target.value)}
                className={`${userData.email && userData.email !== 'N/A' &&!isEmailValid(userData.email) ? 'login-pg-input-email' : ''}`}
                value={userEmailFromInvitation ? userEmailFromInvitation : userData.email === 'N/A' ? '' : userData.email}
                disabled={userEmailFromInvitation ? true : false}
              />
            </Col>
            <Col md={12}>
              <PasswordInput
                barColors={[
                  "#0FB600",
                  "#424A47",
                  "#424A47",
                  "#424A47",
                  "#424A47",
                ]}
                strength
                label="Password"
                placeholder="Password Here"
                value={userData.password}
                onChange={(e) => handleChange('password', e.target.value)}
                className={`validation-wrapper ${(userData.password !== confirmPassword) ? 'mismatch-or-empty' : ''}`}
              />
            </Col>
            <Col md={12}>
              <PasswordInput
                label="Confirm Password"
                placeholder="Confirm Password Here"
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={`validation-wrapper ${(userData.password !== confirmPassword) ? 'mismatch-or-empty' : ''}`}
              />
            </Col>
            {/* <Col md={12}>
              <CheckBox
                name="terms"
                className="auth-checkbox"
                type="checkbox"
                marginBottom="32px"
                label={
                  <>
                    I agree to <Link to='#' href="#">terms &amp; conditions </Link>
                  </>
                }
                onClick={handleChangeTerm}
              />
            </Col> */}
          </Row>
        </div>
        <Col md={12} className='mb-3'>
          <ReCAPTCHA
              sitekey={process.env.RECAPTCHA_SITE_KEY}
              ref={captchaRef}
            />
          </Col>
        <div className="auth-footer auth-signup-footer">
          <Row>
            <Col md={12}>
              {/* <Button large="1" className="auth-btn" outlined width={"100%"} onClick={() => handleSignUp()} disabled={agreeToTerms ? false : true}> */}
              <Button large="1" className="auth-btn" outlined width={"100%"} onClick={() => handleSignUp()} >
                Register
              </Button>
            </Col>
            <Col md={12}>
              <div className="already-account align-items-center">
                <label>
                  Already have an account? <Link to="/auth/sign-in">Login</Link>
                </label>
              </div>
            </Col>
          </Row>
        </div>
      </SignUpLayout>
      {
        loading && (<Spin />)
      }
    </>
  );
};

export default Index;
