import { createGlobalStyle, ThemeConsumer } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #F9FAFC;
    font-family: 'Lato', sans-serif;
    font-size: ${({ theme }) => theme["base-font-size"]};
    letter-spacing: 0.02em;
  }

    h1{
        font-size:${({ theme }) => theme["base-font-lg"]};
        font-weight:${({ theme }) => theme["bold-weight"]};
        letter-spacing: 0.02em;
    }
    h3{
        font-size:${({ theme }) => theme["base-size"]};
        font-weight: 700;
    }
    .primary-text{
        color:${({ theme }) => theme["primary-color"]};
    }
    .link-text{
        color:${({ theme }) => theme["link-color"]};
    }
    .label-color{
        color:${({ theme }) => theme["label-color"]};
    }
    .success-color{
        color:${({ theme }) => theme["success-color"]};
    }
    .success-color{
        color:${({ theme }) => theme["success-color"]};
    }
    .danger-color{
        color:${({ theme }) => theme["danger-color"]};
    }
    .warning-color{
        color:${({ theme }) => theme["warning-color"]};
    }
    svg{
        cursor:pointer;
    }
    .highcharts-credits,.highcharts-axis-title{
        display:none;
    }
    .highcharts-legend highcharts-no-tooltip{
        font-family: 'Lato', sans-serif!important;

    }
    .del-icon-color{
        color:#FF1E1F;
    }
    .tooltip-product-image{
        width:100%;
        max-width: 120px;
        opacity: 1!important;
        .tooltip-image img{
          width: auto;
          height: 400px;
          max-width: 600px;
          transition: 0.3s all ease-in;
        }
      .tooltip-inner{
          max-width:120px;
          background-color:#ffffff;
          padding:0;
          box-shadow: 0px 0px 15px #cacaca;
        } 
      .tooltip-arrow:before{
        border-right-color: #ffffff;
      }
  }
  .cursor-pointer{
    cursor: pointer;
  }
`;
export default GlobalStyles;
