import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PrivateRoute from './private-route';

import Bugs from '../pages/Bugs/bugs';
// import DashboardLayout from '../dashboardLayout';
import DashboardLayout from '../layout';
import Dashboard from '../pages/Dashboard';
import EditStore from '../pages/Profile/edit-store';
import ManageAccount from '../pages/Profile/index';
import PageNotFound from '../pages/Other/page-not-found';
import SelectRegion from '../pages/OnBoarding/select-region';
import SupportedStore from '../pages/StoreBaseSubscription/store-based-subscription';
import UsersPage from '../pages/Users/UserPage';
import Referrals from '../pages/Referrals';
import GiftCards from '../pages/GiftCards'

import { setAuthToken } from '../config/axios-configuration';
import SubUsers from '../pages/subUsers';
import Orders from '../pages/Orders';

const Index = () => {
  const { token, user } = useSelector(state => state.auth);
  const { impersonateUserData } = useSelector(state => state.user);

  setAuthToken(token);

  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/dashboard' />
      </Route>
      <PrivateRoute exact path='/dashboard' component={Dashboard} withLayout={DashboardLayout}/>
      <PrivateRoute exact path='/profile' component={ManageAccount} withLayout={DashboardLayout}/>
      {(user?.role === 'user' || impersonateUserData?.userId) && <PrivateRoute exact path='/sub-users' component={SubUsers} withLayout={DashboardLayout}/>}
      {(user?.role === 'user' || impersonateUserData?.userId) && <PrivateRoute exact path='/referrals' component={Referrals} withLayout={DashboardLayout}/>}
      <PrivateRoute exact path='/users' component={UsersPage} withLayout={DashboardLayout}/>
      <PrivateRoute exact path='/bugs/:userId' component={Bugs}/>
      <PrivateRoute exact path='/select-region' component={SelectRegion}/>
      <PrivateRoute exact path='/edit-store/:storeId' component={EditStore}/>
      <PrivateRoute exact path='/store-based-subscription/:priceId/:subscriptionType/:couponCode/:subscriptionCreation' component={SupportedStore}  />
      <Route path='/not-found' component={PageNotFound} />
      <PrivateRoute exact path='/gift-cards' component={GiftCards} withLayout={DashboardLayout}/>
      <PrivateRoute exact path='/orders' component={Orders} withLayout={DashboardLayout}/>
      <Redirect from='*' to='/' />
    </Switch>
  )
}

export default Index;
