import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import defaultImg from "../../assets/images/gallery.png";

const Index = (props) => {
  const {
    title,
    asin,
    upc,
    imageUrl,
    website
  } = props;

  return (
    <div className="d-flex gap-2 align-items-center title-column">
      <div>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip className="tooltip-product-image image-ui-wrapper">
              <div className="tooltip-image">
                <img
                  width="36"
                  src={website !== 'VitaCost' ? imageUrl && !imageUrl.includes("data:") ? imageUrl : defaultImg : !imageUrl.includes('vitacost.com') ? `${imageUrl}`: imageUrl}
                  alt="no-product"
                />
              </div>
            </Tooltip>
          }
        >
          <img
            style={{width: '60px',height: '60px', objectFit: 'contain'
           }}
            src={website !== 'VitaCost' ? imageUrl && !imageUrl.includes("data:") ? imageUrl : defaultImg : !imageUrl.includes('vitacost.com') ? `${imageUrl}`: imageUrl}
            loading="lazy"
            alt="no-product"
          />
        </OverlayTrigger>
      </div>
      <div>
        <OverlayTrigger
          placement="top"
          overlay={
            title?.length > 35 ? (
              <Tooltip>
                <span className="title-content">{title}</span>
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="title-content">{title}</span>
        </OverlayTrigger>
        <div className="d-flex title-details">
          <div className="d-flex grid-column-gap-2 pe-2">
            <span>
              ASIN:<strong>{asin}</strong>
            </span>
          </div>
          <div className="d-flex grid-column-gap-2  pe-2">
            <span>
              UPC::<strong>{upc ? upc : "N/A"}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
