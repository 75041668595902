import React from "react";
import { Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";

import AppRoute from './routes/app-route';
import AuthRoute from './routes/auth-route';

import { theme } from './assets/themes';
import './assets/css/style.css';
import GlobalStyles from './assets/themes/global-styled';

const App = () => {
  return (
    <ThemeProvider theme={{ ...theme }}>
    <BrowserRouter>
      <GlobalStyles />
          <Switch>
            <AuthRoute path='/auth' />
            <AppRoute path='/' />
          </Switch>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
