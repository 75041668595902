import styled from "styled-components";

const StoreWrapper = styled.div`
  p.label-text-ui {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  h3.label-text-ui{
    margin-bottom: 8px;

  }
  .marketplace-region {
    h2 {
      letter-spacing: 2px;
      margin-bottom: 12px;
    }
    .market-place-item {
      display: flex;
      align-items: center;
      margin-left: -10px;
      h4 {
        letter-spacing: 2px;
        margin-bottom: 0px;
      }
    }
    .region-name {
      margin-top: 0px;
      margin-right: 0px;
      span {
        font-weight: 500;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        color: ${({ theme }) => theme["on-background-variant"]};
      }
    }

    .country-region {
      margin-top: 15px;
      grid-column-gap: 16px;
      img {
        background: ${({ theme }) => theme["background"]};
        border: 1px solid ${({ theme }) => theme["secondary-variant"]};
        border-radius: 20px;
        border-radius: 20px;
        width: 24px;
        height: 24px;
        margin-bottom: 0;
      }
    }
  }
  .add-button-space {
    margin-top: 40px;
    grid-gap:16px;
    button {
    padding: 8px 24px;
    min-width: fit-content;
    &:last-child{
      background: #1565d8 !important;
      color: #fff !important;
      border:none !important;
    }
}
  }
  .region-list-wrapper {
    margin-left: 21px;
  }
`;

export { StoreWrapper };
