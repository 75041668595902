import React from "react";
import { Row, Col } from "react-bootstrap";
 
import PageHeader from "../../../component/header";
import { AuthWrapper } from "../../authOnboarding-style";
import AuthImage from "../../../assets/images/qik-new-img.jpg";
import Header from "../../../component/header";
import { useSelector } from "react-redux";

const Index = (props) => {
  const { children } = props;
  const { user } = useSelector(state => state.user)
  return (
    <>
    {/* {user.status === 'Registered' ? <Header/> : null} */}
    <Header user={user}/> 
      <AuthWrapper>
        <Row className="align-items-center">
          <Col md={6} className="text-end auth-section-left">
          <div style={{display: "flex", justifyContent:'center', alignItems: 'center', flexDirection: 'column'}}>
          <a href="https://qiklists.podia.com/qikfinds-extension" target="_blank"><img src={AuthImage} alt="auth illustration" /></a>
          <h2 style={{color: '#1565d8', cursor: 'pointer'}} onClick={()=> window.open('https://qiklists.podia.com/qikfinds-extension')}>Learn More</h2>
          </div>
          </Col>
          <Col md={{ span: 4, offset: 1 }} className="auth-section-middle">
            <div className="auth-middle-content">{children}</div>
          </Col>
        </Row>
      </AuthWrapper>
    </>
  );
};

export default Index;
