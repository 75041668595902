import styled from "styled-components";

const SupportedStoreWrapper = styled.div`
.overlay-ui-wrapper{
padding:24px;
}
h1{

  font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #272B41;
    margin-bottom: 0;

}
.sub-header-ui {
      margin-top: 16px;
    margin-left: 24px;
  
}

.sub-header {
    display: flex;
    align-items: center;
    p{
      margin-bottom:0px ;
      font-weight: 900;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #272B41;
    margin-bottom: 0;
    }

}
.overlay-content-wrapper {
    padding-top:24px;
    display:flex;
    align-items:baseline;
    justify-content:center ;
    grid-gap:24px;

.overlay-content {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 163px;
    grid-row-gap: 0px;
    p{
      margin-bottom:0px ;
    }
}
    .overlay-content-item{
      display:flex;
      align-items:center;
      min-width:200px ;
    }
 
  }
.overlay-content-ui {

    .overlay-content-item{
      display:flex;
      align-items:center;
      justify-content:space-between ;
      p{
        margin-bottom:0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.02em;
        color: #272B41;
        margin-bottom: 0;
      }
    }
}

.submit-ui-button{
    background: #0057d3;
    color: #ffff;
    border: 1px solid #0057d3;
    margin: 2px;
    margin-top: 24px;
    margin-left: 24px;
    text-transform:capitalize ;
    &:hover{
      background: #0057d3;
    color: #ffff;
    }
}


`;
export { SupportedStoreWrapper };