import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const GetActiveMarketplaces = createAsyncThunk(
  'stores/get-active-marketplaces',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/store/get-active-marketplaces', {
        params: {
          storeId: data.storeId
        }
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetSPAPIToken = createAsyncThunk(
  'stores/getSPAPIToken',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/store/get-spapi-refresh-token', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetStore = createAsyncThunk(
  'stores/getStore',
  async (data, thunkAPI) => {
    try {
      const { storeId } = data;
      const response = await axios.get('/store/get-store', {
        params: {
          storeId
        }
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetStores = createAsyncThunk(
  'stores/getStores',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/store/get-stores', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const DeleteStore = createAsyncThunk(
  'stores/deleteStore',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/store/delete-store', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const EditStoreData = createAsyncThunk(
  'stores/editStoreData',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/store/edit-store-data', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const store = createSlice({
  name: 'stores',
  initialState: {
    tab: 'dashboard',
    success: false,
    spApiSuccess: false,
    loading: false,
    storesData: [],
    error: '',
    name: '',
    spApiState: '',
    spApiRegion: '',
    sellerId: '',
    amazonState: '',
    amazonUrl: '',
    message: '',
    spiApiTokenMsg: '',
    activeMarketplacesSuccess: false,
    activeMarketplaces: [],
    storeId: '',
    storeName: '',
    storeRegion: '',
    store: {},
    persistSorting:{
      sortByColumn:'title',
      sortType:'asc'
    },
    persistSource: {
      value: 'All',
      label: 'All'
    },
    persistSearchkeyword: '',
    persistStartDate: {
      startDay: 1,
      startMonth: 1,
      startYear: 2023,
    },
    persistEndDate: {
      endDay: '',
      endMonth: '',
      endYear: '',
    },
    productsByDateFilter: false
  },
  reducers: {
    SetStoreState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    amazonStoreInfo(state, action) {
      return {
        ...state,
        name: action.payload?.name,
        spApiState: action.payload.spApiState,
        spApiRegion: action.payload.spApiRegion
      };
    },
    setMarketplaceEdit(state, action) {
      return {
        ...state,
        marketplaceEditSuccess: action.payload.marketplaceEditSuccess
      };
    },
    clearState(state, action) {
      return {
        ...state,
        storeNames: []
      };
    }
  },
  extraReducers: {
    [GetActiveMarketplaces.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false,
      activeMarketplacesSuccess: false
    }),
    [GetActiveMarketplaces.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      activeMarketplaces: action.payload.data,
      activeMarketplacesSuccess: true
    }),
    [GetActiveMarketplaces.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [GetSPAPIToken.pending]: (state, action) => ({
      ...state,
      storeSuccess: false,
      storeId: '',
      loading: true,
      success: false,
      spiApiTokenMsg: null
    }),
    [GetSPAPIToken.fulfilled]: (state, action) => ({
      ...state,
      storeId: action.payload.data,
      message: action.payload.message,
      spiApiTokenMsg: action.payload.message,
      success: true,
      loading: false,
      storeSuccess: true
    }),
    [GetSPAPIToken.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      storeId: '',
      storeSuccess: false,
      spiApiTokenMsg: null,
      error: action.payload.err.message
    }),
    [GetStore.pending]: (state, action) => ({
      ...state
    }),
    [GetStore.fulfilled]: (state, action) => ({
      ...state,
      store: action.payload.store
    }),
    [GetStore.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message
    }),
    [GetStores.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetStores.fulfilled]: (state, action) => ({
      ...state,
      storesData: action.payload.stores,
      success: true,
      loading: false,
      storeSuccess: true
    }),
    [GetStores.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [DeleteStore.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [DeleteStore.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      loading: false
    }),
    [DeleteStore.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [EditStoreData.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [EditStoreData.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      loading: false
    }),
    [EditStoreData.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    })
  }
});

const { reducer, actions } = store;

export const {
  amazonStoreInfo,
  clearState,
  SetStoreState,
  setMarketplaceEdit
} = actions;

export default reducer;
