import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";
import { Tooltip } from "antd";

import {
  useHistory,
  useParams
} from 'react-router-dom';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiLeftArrowAlt } from 'react-icons/bi';

import IconTrash from "../../assets/images/icon-basket.svg";
import Table from "../../component/table/Index";
import TablePagination from "../../component/pagination/index";
import Modal from "../../component/modal/index";
import Notification from '../../component/notifications/notification';
import Spin from '../../component/Spin';
import Header from "../../component/header";

import { BugsPageWrapper } from "./style";

import {
  DeleteBug,
  GetUserBugs,
  SetBugState,
  UpdateBugStatus
} from '../../redux/slices/bug-slice';

const UserPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { userId } = useParams();

  const {
    loading,
    totalBugs,
    bugs,
    error,
    message
  } = useSelector(state => state.bug);

  const [tableRows, setTableRows] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [bugId, setBugId] = useState('');
  const [popupDelete, setPopupDelete] = useState(false);

  const defaultColDefs = {
    resizable: true,
    sortable: true,
  };

  const handleDeleteModal = (id, website) => {
    setPopupDelete(true);
  };

  const pageOptions = [10, 25, 50, 100];
  const columnDefinitions = [
    {
      headerName: "Website",
      minWidth: 315,
      flex: 1,
      field: "poNumber",
      cellRenderer: ({ data }) => {
        const { website } = data || {};

        return website || 'N/A';
      },
    },
    {
      headerName: "Bug Reported At",
      minWidth: 100,
      flex: 1,
      field: "user",
      cellRenderer: ({ data }) => {
        const { bugReportedAt } = data || {};

        return bugReportedAt ? moment(bugReportedAt).format('lll') : 'N/A';
      },
    },
    {
      headerName: "Bug Description",
      minWidth: 315,
      flex: 1,
      field: "poNumber",
      cellRenderer: ({ data }) => {
        const { bugText } = data || {};

        return  <OverlayTrigger
        placement="top"
        overlay={
          bugText?.length > 50 ? (
            <Tooltip>
              <span className="">{bugText}</span>
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        <span className="">{bugText}</span>
      </OverlayTrigger> || 'N/A';
      },
    },
    {
      headerName: "Secondary URL",
      minWidth: 315,
      flex: 1,
      field: "secondaryUrl",
      cellRenderer: ({ data }) => {
        const { secondaryUrl } = data || {};

        return secondaryUrl ?  <OverlayTrigger
        placement="top"
        overlay={
          secondaryUrl?.length > 30 ? (
            <Tooltip>
              <span className="">{secondaryUrl}</span>
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        <span className="secondary-url" onClick={() => window.open(secondaryUrl, '_blank')}>{secondaryUrl}</span>
      </OverlayTrigger> : 'N/A';
      },
    },
    {
      headerName: "Actions",
      minWidth: 190,
      flex: 1,
      field: "wareHouse",
      cellRenderer: ({ data }) => {
        const {
          _id: bugId,
        } = data || {};

        return (
          <div className="d-flex action-icon-wrapper">
            <Tooltip placement='top' title='Delete'>
            <span>
              <RiDeleteBinLine
                className="del-icon-color"
                onClick={() => {
                  setBugId(bugId)
                  handleDeleteModal(bugId)
                }}
              />
            </span>
            </Tooltip>
            <Tooltip placement='top' title='Mark as Resolved'>
            <span className="mark-as-resolved" onClick={() => {
                dispatch(UpdateBugStatus({
                    bugId
                  }))
                }} >
              Mark as Resolved
            </span>
            </Tooltip>
          </div>
        )
      }
    },
  ];

  const handleOnPageChange = (current, pageSize) => {
    setPageNumber(current);
    setPageLimit(pageSize);
  };

  useEffect(() => {
    dispatch(GetUserBugs({
      userId,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
    }));
  }, []);

  const handleDeleteBug = () => {
    dispatch(DeleteBug({ bugId }));
    setPopupDelete(false);
  };

  useEffect(() => {
    dispatch(GetUserBugs({
      userId,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
    }));
  }, [pageLimit, pageNumber]);

  useEffect(() => {
    if (bugs && bugs.length) {
      const data = bugs.map(
        (
          {
            _id,
            website,
            bugReportedAt,
            bugText,
            secondaryUrl
          },
          index
        ) => {
          return {
            _id,
            website,
            bugReportedAt,
            bugText,
            secondaryUrl
          };
        }
      );

      setTableRows(data);
    }
  }, [bugs]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error,
      });
      dispatch(SetBugState({ field: "error", value: "" }));
      return;
    }

    if (message) {
      Notification({
        type: "success",
        title: "Success",
        description: message,
      });
      dispatch(SetBugState({ field: "message", value: "" }));
      dispatch(SetBugState({ field: "success", value: false }));

      if (totalBugs && totalBugs > 0) {
        const remainingBugsOnCurrentPage = bugs.length - 1;
        if (remainingBugsOnCurrentPage > 0) {
          dispatch(GetUserBugs({
            userId,
            limit: pageLimit,
            skip: (pageNumber - 1) * pageLimit
          }));

          if (remainingBugsOnCurrentPage === 0) {
            dispatch(GetUserBugs({
              userId,
              limit: pageLimit,
              skip: (1 - 1) * pageLimit
            }));
          }
        } else {
          const nextPage = pageNumber - 1;
          setPageNumber(nextPage === 0 ? 1 : nextPage);
          history.goBack();
        }
      }
      return;
    }
  }, [error, message]);

  return (
    <>
    <Header/>
    <BugsPageWrapper>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex bugs-sub-header">
        <BiLeftArrowAlt onClick={() => history.goBack()}/>
        <h1 className="page-title">Bugs</h1>
        </div>
        {/* <div className="d-flex user-page-header">
        <SearchInput placeholder="Search Users" onChange={(e) => handleSearch(e.target.value)}/>
          <Button
            className="export-btn-users"
            onClick={() => setInviteUserModal(true)}
          >
            Invite User
          </Button>
          <Button
            className="export-btn-users"
            onClick={() => dispatch(ExportUsers({}))}
          >
            Export Sheet
          </Button>
        </div> */}
      </div>
      <Table
        height="240px"
        headerHeight={50}
        columnDefinitions={columnDefinitions}
        defaultColDef={defaultColDefs}
        rowData={tableRows}
        rowHeight={67}
      />
      <TablePagination
        background="#F8F8F8"
        records={totalBugs}
        options={pageOptions}
        onChange={handleOnPageChange}
        pageNumber={pageNumber}
        pageLimit={pageLimit}
      />
      <Modal
        className="cancel-modal"
        open={popupDelete}
        saveText="Delete"
        closeText="Cancel"
        onSave={() => handleDeleteBug()}
        onClose={() => setPopupDelete(false)}
      >
        <div className="category-modal-wrapper">
          <img src={IconTrash} alt="no/" className="icon-popup" />
          <h2>Are you sure want to delete this bug?</h2>
          <p>
            The selected bug will be deleted and it will be removed from our
            system.
          </p>
        </div>
      </Modal>
      {loading && <Spin />}
    </BugsPageWrapper>
    </>
  
  );
};

export default UserPage;
