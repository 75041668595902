/*global chrome*/
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Radio, Space } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { BsCalendar4Week } from 'react-icons/bs';
import { TiDelete } from "react-icons/ti";
import { AiOutlineSync } from 'react-icons/ai';
import { CiFilter } from 'react-icons/ci';
import { RiDeleteBinLine } from 'react-icons/ri';
import { debounce } from 'lodash';

import IconTrash from "../../assets/images/icon-basket.svg";
import infoIcon from '../../assets/images/infoicon.svg';
import SearchInput from '../../component/searchInput/Index';
import Table from '../../component/table/Index';
import TablePagination from '../../component/pagination/index';
import DateRangeFilter from '../../component/dateRange/dateRange';
import Modal from '../../component/modal/index';
import Button from '../../component/Button/Index';
import Spin from '../../component/Spin';
import Notification from '../../component/notifications/notification';
import ToasterNotification from '../../component/alert';
import AmazonIcon from '../../assets/images/amazon-img-black.svg';
import Select from '../../component/select/index';

import TableTitle from './table-title';
import { DashboardWrapper } from './style';

import {
  SetProductState,
  ExportDashboardProducts,
  DeleteFavouriteProduct,
  GetFavouriteProducts,
  GetSingleProductDetail,
  SaveProductNote
} from '../../redux/slices/product-slice';

import { SetStoreState } from '../../redux/slices/store-slice';

import { FormatAmountWithCurrency } from "../../utils/helpers";

import { CURRENCY_SYMBOLS_WRT_MARKETPLACE, allStores } from "../../constants/constants";

const Index = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);
  const {
    persistSource,
    persistSearchKeyword,
    persistStartDate,
    persistEndDate,
    productsByDateFilter,
  } = useSelector(state => state.store);

  const {
    error,
    loading,
    success,
    message,
    totalFavouriteProducts,
    favouriteProducts: fProducts,
    singleProductDetail,
  } = useSelector((state) => state.product);

  const { impersonateUserData } = useSelector(state => state.user);

  const [favouriteProducts, setFavouriteProducts] = useState([]);
  const [range, setRange] = useState(false);
  const [popupDelete, setPopupDelete] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [productId, setProductId] = useState("");
  const [website, setWebsite] = useState("");
  const [upc, setUpc] = useState("");
  const [asin, setAsin] = useState("");
  const [productNote, setProductNote] = useState("");
  const [fvtProductUserId, setFvtProductUserId] = useState("");
  const [noteId, setNoteId] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [keepaGraphModal, setkeepaGraphModal] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState();
  const [clearFilterDisableClass, setClearFilterDisableClass] = useState('filter-clear');
  const [externalFilters, setExternalFilters] = useState(null);
  const [exportModal, setExportModal] = useState(null);
  const [persistSorting, setPersistSorting] = useState({
    sortByColumn:'createdAt',
    sortType:'des'
  })
  const [exportFilter, setExportFilter] = useState("");
  const [selectedAsins, setSelectedAsins] = useState([])

  const handleDeleteProduct = () => {
    dispatch(DeleteFavouriteProduct({
      productId,
      website,
      upc,
      asin,
      userId: fvtProductUserId
    }));
    setPopupDelete(false);
  };

  const handleDeleteModal = (id, website, upc, asin, userId) => {
    setPopupDelete(true);
    setProductId(id);
    setWebsite(website);
    setUpc(upc);
    setAsin(asin);
    setFvtProductUserId(userId)
  };

  const handleGetSingleProduct = (productId, website, asin, buyCost, userId, relatedUPCS) => {
    dispatch(GetSingleProductDetail({
      impersonateUserId: impersonateUserData.userId,
      userId,
      productId,
      website,
      asin,
      buyCost,
      upc: relatedUPCS
    }));
  };

  const pageOptions = [10, 25, 50, 100];

  const columnDefinition = [
    {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        maxWidth: 50,
        minWidth: 50,
    },
    {
      headerName:'Date',
      field: 'markAsFavouriteDate',
      maxWidth: 100,
      minWidth: 100,
      resizable:true,
      flex: 1,
      cellRenderer: ({ data }) => {
        const { markAsFavouriteDate } = data || {};
        return moment(markAsFavouriteDate).format('MM/DD/YY') || 0;
      },
    },
    {
      headerName:'Product Name',
      field: 'title',
      width: 315,
      minWidth: 315,
      flex: 1,
      cellRendererFramework: ({ data }) => {
        const {
          title,
          asin,
          relatedUPCS,
          imageUrlOnStore,
          imageUrl,
          website,
          upc
        } = data || {};

        return (
          <TableTitle
            title={title}
            asin={asin}
            upc={relatedUPCS?.length ? relatedUPCS[0] : upc ? upc : 'N/A'}
            imageUrl={imageUrl || imageUrlOnStore}
            website={website}
          />
        );
      },
    },
    {headerName:'Featured FBA Sellers',
      field: 'fbaSellers',
      maxWidth: 115,
      minWidth: 115,
      flex: 1,
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ data }) => {
        const { fbaSellers } = data || {};
        return fbaSellers || 0;
      },
    },
    {
      headerName:'Featured FBM Sellers',
      field: 'fbmSellers',
      maxWidth: 115,
      minWidth: 115,
      flex: 1,
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ data }) => {
        const { fbmSellers } = data || {};
        return fbmSellers || 0;
      },
    },
    {
      headerName:'Store Link',
      field: 'website',
      width: 180,
      minWidth: 180,
      flex: 1,
      cellRenderer: ({ data }) => {
        const {
          website,
          productPageUrl
        } = data || {};
        let url = productPageUrl;
        if (website === 'Macy' && !productPageUrl.includes('macys.com')) {
          url = `https://www.macys.com${url}`;
        } else if (website === 'Woot' && !productPageUrl.includes('woot.com')) {
          url = `https://www.woot.com${url}`;
        }

        return (
          <div className="d-flex flex-column links-ui-wrapper">
            <a href={`${url}`} target='_blank' style={{ paddingBottom: '9px' }}><span>{website}</span></a>
          </div>
        )
      },
    },
    {
      headerName:'Amazon Link',
      field: 'website',
      width: 180,
      minWidth: 180,
      flex: 1,
      cellRenderer: ({ data }) => {
        const {
          asin,
        } = data || {};
        return (
          <div className="d-flex flex-column links-ui-wrapper">
            <a href={`${`https://www.amazon.com/dp/${asin}`}`} target='_blank'><img src={AmazonIcon} style={{ width: '50px' }} alt="no-img" /></a>
          </div>
        )
      },
    },
    {
      headerName: "Sale Price",
      field: "listPrice",
      width: 170,
      minWidth: 170,
      flex: 1,
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ data }) => {
        const {
          listPrice,
          marketplaceId,
        } = data || {};

        return listPrice
          ? FormatAmountWithCurrency(listPrice, marketplaceId)
          : "$0";
      },
    },
    {
      headerName:"Buy Cost",
      field: "costPrice",
      minWidth: 170,
      flex: 1,
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ data }) => {
        const { buyCost, marketplaceId = 'n/a', costPrice } = data || {};
        return (buyCost && marketplaceId)
          ? FormatAmountWithCurrency(buyCost, marketplaceId) : costPrice ? FormatAmountWithCurrency(costPrice, marketplaceId) : "$0"
      },
    },
    {
      field: "otherCost",
      minWidth: 170,
      flex: 1,
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ data }) => {
        const { otherCost, marketplaceId } = data || {};
        return otherCost
          ? FormatAmountWithCurrency(otherCost, marketplaceId)
          : "$0";
      },
    },
    {
      field: "profit",
      width: 170,
      minWidth: 170,
      flex: 1,
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ data }) => {
        const { profit, marketplaceId } = data || {};
        return (profit && marketplaceId)
          ? profit > 0
            ? `${CURRENCY_SYMBOLS_WRT_MARKETPLACE[marketplaceId]}${profit}`
            : `-${CURRENCY_SYMBOLS_WRT_MARKETPLACE[marketplaceId]}${profit * -1
            }`
          : profit ? profit > 0 ? profit: `-$${profit * -1}` : "$0";
      },
    },
    {
      field: "roi",
      minWidth: 170,
      flex: 1,
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ data }) => {
        const { roi } = data || {};
        return roi ? <OverlayTrigger
        placement="top"
        overlay={
          roi.toString()?.length > 5 ? (
            <Tooltip>
              <span className="title-content">{`${roi}%`}</span>
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        <span className="title-content">{roi}%</span>
      </OverlayTrigger> : "0%"
      },
    },
    {
      field: "margin",
      minWidth: 170,
      flex: 1,
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ data }) => {
        const { margin } = data || {};
        return margin ? `${margin}%` : "0%";
      },
    },
    {
      field: "note",
      minWidth: 200,
      flex: 1,
      cellRenderer: ({ data }) => {
        const { note, id, productId: pId, website, userId } = data || {};
        return <OverlayTrigger
          placement="top"
          overlay={
            note?.length > 7 ? (
              <Tooltip>
                <span className="title-content">{`${note}`}</span>
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
            <p
            onClick={() => {
              setEditNote(true);
              setProductNote(note);
              setFvtProductUserId(userId);
              if (pId) {
                setProductId(pId);
              } else {
                setNoteId(id);
              }
              setWebsite(website);
            }}
            className="title-content note-text-wrapper"
          >
            {note && note.length > 45 ? `${note.substring(0, 45)}...` : note || "N/A"}
          </p>
        </OverlayTrigger>
      },
    },
    {
      headerName:"Graph",
      field: "asin",
      minWidth: 170,
      flex: 1,
      cellRendererFramework: ({ data }) => {
        const { asin } = data;
        return (
          <>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip className="tooltip-keepa-main">
                  <div className="tooltip-keepa">
                    <img
                      src={`https://graph.keepa.com/pricehistory.png?asin=${asin}&domain=com&new=1&salesrank=1&range=365&bb=1&fba=1&fbm=1&width=1000&height=400`}
                      alt="keepa img"
                    />
                  </div>
                </Tooltip>
              }
            >
              <img
                width="101"
                onClick={() => window.open(`https://keepa.com/#!product/1-${asin}`, '_blank')}
                src={`https://graph.keepa.com/pricehistory.png?asin=${asin}&domain=com&new=1&salesrank=1&range=365&bb=1&fba=1&fbm=1&width=1000&height=400`} alt="keepa graph" className="cursor-pointer" />
            </OverlayTrigger>
          </>
        );
      },
    },
    ... (user?.role !== 'sub-user' && user?.role !== 'special-sub-user') ? [{
      headerName:'Actions',
      minWidth: 170,
      flex: 1,
      pinned:'right',
      cellRenderer: ({ data }) => {
        const {
          productId,
          website,
          asin,
          relatedUPCS,
          buyCost,
          userId,
          upc
        } = data;
        return (
          <div className="table-actions d-flex">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip placement='top' title='Sync Product Details'>
                <span>
                Sync Product Details
                </span>
                </Tooltip>
              }
            >
             <span>
              <AiOutlineSync
                onClick={() => handleGetSingleProduct(productId, website, asin, buyCost, userId, upc || relatedUPCS[0])}
              />
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                <span>
                Delete
                </span>
                </Tooltip>
              }
            >
            <span>
              <RiDeleteBinLine
                className="del-icon-color"
                onClick={() => handleDeleteModal(productId, website, upc || relatedUPCS[0], asin, userId)}
              />
            </span>
            </OverlayTrigger>
          </div>
        );
      },
    }] : []
  ];

  const defaultColDefs = {
    resizable: true,
    sortable: true,
    filter:true,
    editable:false,
    comparator:()=>0,
  };

  const onSortChanged = ({ api: { sortController } }) => {
    const sortModel = sortController.getSortModel();
    if (sortModel.length > 0) {
      
      const { colId: sortByColumn, sort: sortType } = sortModel[0];
      setPersistSorting({
        sortByColumn, sortType
      })
    }
  };

  const onFilterChanged = ({ api }) => {
    dispatch(SetProductState({ field: "loading", value: true }));
    const filterModel = api.getFilterModel();
    setExternalFilters(filterModel)
  };

  const onChange = (dateRange) => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    const formatedStartDate = moment(startDate).format("L");
    const formatedEndDate = moment(endDate).format("L");

    const checkStart = moment(formatedStartDate);
    const startDay = checkStart.format("D");
    const startMonth = checkStart.format("M");
    const startYear = checkStart.format("YYYY");
    dispatch(SetStoreState({ field: 'persistStartDate', value: { startDay, startMonth, startYear } }));

    const checkEnd = moment(formatedEndDate);
    const endDay = checkEnd.format("D");
    const endMonth = checkEnd.format("M");
    const endYear = checkEnd.format("YYYY");
    dispatch(SetStoreState({ field: 'persistEndDate', value: { endDay, endMonth, endYear } }));
    setClearFilterDisableClass('filter-clear');
  };

  const handleChange = (value) => {
    dispatch(SetStoreState({ field: 'persistSearchKeyword', value }));

    if (!value && !persistEndDate.endDay && (persistSource.value === 'All' || !persistSource?.value)) {
      setClearFilterDisableClass('filter-clear disabled disable-class');
    } else {
      setClearFilterDisableClass('filter-clear');
    }

    setPageNumber(1);
  };

  const handleGetProducts = () => {
    setPageNumber(1);

    if (pageNumber === 1) {
      dispatch(
        GetFavouriteProducts({
          impersonateUserId: impersonateUserData.userId,
          startDate: persistStartDate,
          endDate: persistEndDate,
          searchKeyword: persistSearchKeyword,
          source: persistSource?.value,
          limit: pageLimit,
          persistSorting,
          skip: (pageNumber - 1) * pageLimit,
        })
      );
    }

    dispatch(SetStoreState({ field: 'productsByDateFilter', value: true }));
    setRange(!range);
  };

  const handleOnPageChange = (current, pageSize) => {
    setPageNumber(current);
    setPageLimit(pageSize);
  };

  const handleSourceChange = (e) => {
    if (!e) {
      dispatch(SetStoreState({
        field: 'persistSource', value: {
          value: 'All',
          label: 'Please Select Source'
        }
      }));

      if (!persistSearchKeyword && !persistEndDate.endDay) {
        setClearFilterDisableClass('filter-clear disabled disable-class');
      }
    } else {
      dispatch(SetStoreState({ field: 'persistSource', value: e }));
      setClearFilterDisableClass('filter-clear');
    }

    setPageNumber(1);
    setPageLimit(10);
  };

  const handleClearFilter = () => {
    dispatch(SetStoreState({
      field: 'persistSource', value: {
        value: 'All',
        label: 'Please Select Source'
      }
    }));

    dispatch(SetStoreState({ field: 'persistSearchKeyword', value: '' }));
    dispatch(SetStoreState({
      field: 'persistStartDate', value: {
        startDay: 1,
        startMonth: 1,
        startYear: 2023,
      }
    }));
    dispatch(SetStoreState({
      field: 'persistEndDate', value: {
        endDay: '',
        endMonth: '',
        endYear: '',
      }
    }));

    dispatch(SetStoreState({ field: 'productsByDateFilter', value: false }));
    setPageNumber(1);
    setPageLimit(10);
    setClearFilterDisableClass('filter-clear disabled disable-class');
  };

  const handleCancelDateFilter = () => {
    if (!productsByDateFilter) {
      dispatch(SetStoreState({
        field: 'persistStartDate', value: {
          startDay: 1,
          startMonth: 1,
          startYear: 2023
        }
      }));

      dispatch(SetStoreState({
        field: 'persistEndDate', value: {
          endDay: '',
          endMonth: '',
          endYear: '',
        }
      }));
      setClearFilterDisableClass('filter-clear disabled disable-class');
    }

    setRange(!range);
  };

  const updateProductNote = () => {
    dispatch(SaveProductNote({
      updateNote: true,
      productId,
      website,
      noteId,
      note: productNote,
      productNoteUserId: fvtProductUserId
    }));
    
    setEditNote(false);
    setNoteId("");
    setProductId("");
    setFvtProductUserId("")
  }

  const exportModalOnChange = (event) => {
    setExportFilter(event.target.value)
  }
  
  const exportData = () => {
    if (exportFilter === 'selectedAsins') {
      if (selectedAsins?.length > 0) {
        dispatch(ExportDashboardProducts({
          impersonateUserId: impersonateUserData.userId,
          userId: user.userId,
          role: user.role,
          exportFilter,
          selectedAsins,
        }));
      } else {
        Notification({
          type: "error",
          title: "Error",
          description: "Select some asins first.",
        });
      }
    } else if (exportFilter === 'currentPage') {
      const asins = tableRows?.map(({ asin }) => asin);
      dispatch(ExportDashboardProducts({
        impersonateUserId: impersonateUserData.userId,
        userId: user.userId,
        role: user.role,
        exportFilter,
        selectedAsins: asins,
      }));
    } else if (exportFilter === 'allAsins') {
      dispatch(ExportDashboardProducts({
        impersonateUserId: impersonateUserData.userId,
        userId: user.userId,
        role: user.role,
        exportFilter,
        selectedAsins: [],
      }));
    } else {
      Notification({
        type: "error",
        title: "Error",
        description: "Select One of the give options",
      });
    }
    setExportFilter('');
    setExportModal(false);
  }

  const onSelectionChanged = (event) => {
   const rows = event.api.getSelectedRows()
   if (rows?.length) {
    const asins = rows.map(({ asin }) => asin);
    setSelectedAsins(prev => [... new Set([...prev, ...asins])])
   }
  }
  const isRowSelectable = (node) => {
    if (selectedAsins?.includes(node.data?.asin)) {
      node.setSelected(true)
    }
    return true;
  }
  useEffect(() => {
    if (!chrome.runtime) return setIsExtensionInstalled("NO");
    chrome.runtime.sendMessage("hakjiagadepdajkkhjcplkdinlkgakal", { message: "isInstalled" },
      (res) => {
        if (res && res.success) {
          setIsExtensionInstalled(true)
        } else {
          setIsExtensionInstalled("NO");
        }
      });
    // dispatch(GetFavouriteProducts({
    //   impersonateUserId: impersonateUserData.userId,
    //   startDate: persistStartDate,
    //   endDate: persistEndDate,
    //   searchKeyword: persistSearchKeyword,
    //   source: persistSource?.value,
    //   limit: pageLimit,
    //   persistSorting,
    //   skip: (pageNumber - 1) * pageLimit,
    // }));

    if (persistSource.value === 'All' && !persistSearchKeyword && !persistEndDate.endDay) {
      setClearFilterDisableClass('filter-clear disabled disable-class');
    }
  }, []);

  useEffect(() => {
    if (fProducts && fProducts.length) {
      setFavouriteProducts(fProducts);
    } else {
      setFavouriteProducts([]);
    }
  }, [fProducts]);

  useEffect(() => {
    const data = favouriteProducts.map(
      (
        {
          _id,
          title,
          asin,
          upc,
          marketplaceId,
          listPrice,
          buyBoxPrice,
          amzBuyBoxPrice,
          fbaBuyBoxPrice,
          lowestFBAPrice,
          lowestFBMPrice,
          costPrice,
          otherCost,
          imageUrl,
          imageUrlOnStore,
          productPageUrl,
          website,
          relatedUPCS,
          roi,
          margin,
          profit,
          fbaSellers,
          fbmSellers,
          note,
          avg90DayFBAPrice,
          userId,
          productId,
          markAsFavouriteDate
        },
        index
      ) => {
        return {
          id: _id,
          title,
          asin,
          upc,
          marketplaceId,
          imageUrl,
          imageUrlOnStore,
          productPageUrl,
          website,
          relatedUPCS,
          listPrice,
          buyBoxPrice,
          amzBuyBoxPrice,
          fbaBuyBoxPrice,
          lowestFBAPrice,
          lowestFBMPrice,
          costPrice,
          otherCost,
          roi,
          margin,
          profit,
          fbaSellers,
          fbmSellers,
          note,
          avg90DayFBAPrice,
          userId,
          productId,
          markAsFavouriteDate
        };
      }
    );

    setTableRows(data);
  }, [favouriteProducts]);

  useEffect(() => {
    if (!isEmpty(singleProductDetail)) {
      const products = favouriteProducts.map((product) => {
        if (product._id === singleProductDetail._id) {
          return {
            ...singleProductDetail,
          };
        } else {
          return {
            ...product,
          };
        }
      });
      setFavouriteProducts(products);
    }
  }, [singleProductDetail]);

  useEffect(() => {
      const getData = setTimeout(() => {
        dispatch(
          GetFavouriteProducts({
            impersonateUserId: impersonateUserData.userId,
            startDate: persistStartDate,
            endDate: persistEndDate,
            searchKeyword: persistSearchKeyword,
            source: persistSource?.value,
            limit: pageLimit,
            persistSorting,
            skip: (pageNumber - 1) * pageLimit,
            externalFilters
          })
        );
      }, 1100);
      return () => clearTimeout(getData);
  }, [persistSearchKeyword, pageLimit, pageNumber, persistSource, persistSorting, externalFilters]);

  useEffect(() => {
    if (success && message) {
      Notification({
        type: "success",
        title: "Success",
        description: message,
      });
      dispatch(SetProductState({ field: "message", value: "" }));
      dispatch(SetProductState({ field: "success", value: false }));
      if (totalFavouriteProducts && totalFavouriteProducts > 0) {
        const remainingFavProductOnCurrentPage = fProducts.length - 1;
        if (remainingFavProductOnCurrentPage >= 0) {
          dispatch(GetFavouriteProducts({
            impersonateUserId: impersonateUserData.userId,
            startDate: persistStartDate,
            endDate: persistEndDate,
            searchKeyword: persistSearchKeyword,
            source: persistSource?.value,
            limit: pageLimit,
            persistSorting,
            skip: (pageNumber - 1) * pageLimit
          }));

          if (remainingFavProductOnCurrentPage === 0) {
            dispatch(GetFavouriteProducts({
              impersonateUserId: impersonateUserData.userId,
              startDate: persistStartDate,
              endDate: persistEndDate,
              searchKeyword: persistSearchKeyword,
              source: persistSource?.value,
              limit: pageLimit,
              persistSorting,
              skip: (1 - 1) * pageLimit
            }));
          }
        } else {
          const nextPage = pageNumber - 1;
          setPageNumber(nextPage === 0 ? 1 : nextPage);
        }
      }

      return;
    }
  }, [success, message]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error,
      });
      dispatch(SetProductState({ field: "error", value: "" }));
      return;
    }
  }, [error]);
  return (
    <>
      <DashboardWrapper>
        <div className="d-flex justify-content-between">
          <h1 className="page-title">Favorite List</h1>
          <div className="titlebar-filter d-flex align-items-center ">
           {user?.role !== 'sub-user' && <Button
              className="export-btn-users"
              // onClick={() => dispatch(ExportDashboardProducts({
              //   impersonateUserId: impersonateUserData.userId,
              //   userId: user.userId,
              //   role: user.role
              // }))}
              onClick={() => setExportModal(true)}
            >
              Export
            </Button>}
            <Select
              isClearable={persistSource?.value === 'All' || !persistSource?.value ? false : true}
              placeholder="Please Select"
              options={allStores}
              width={'256px'}
              onChange={(e) => handleSourceChange(e)}
              value={persistSource}
            />
            {range && (
              <div className="currently-used daterange-dropdown">
                <DateRangeFilter
                  rangeColors={["#0057D3", "#0057D3", "#0057D3"]}
                  onChange={onChange}
                  startDate={persistStartDate}
                  endDate={persistEndDate}
                />
                <div className="daterange-footer">
                  <Button
                    outlined
                    className="btn-link"
                    onClick={() => handleCancelDateFilter()}
                  >
                    Cancel
                  </Button>
                  <Button
                    outlined
                    width="84px"
                    onClick={() => handleGetProducts()}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            )}
            <div className="filter-search">
              <SearchInput
                placeholder="Search Products"
                onChange={(e) =>
                  handleChange(e.target.value)
                }
                value={persistSearchKeyword}
              />
            </div>
            {/* Use class 'disabled' along with filter-clear class to implement disabled state  */}
            {/* <div className={clearFilterDisableClass}>
              <CiFilter onClick={(e) => handleClearFilter()} style={{ width: '21px', height: '21px' }} />
            </div> */}
            <div className='filter-wrapper'>
              {(persistStartDate.startDay && persistEndDate.endDay)&&<><p className='bag-wrapper'><span>{`${persistStartDate.startDay}-${persistStartDate.startMonth}-${persistStartDate.startYear}`}</span><span>-</span><span>{`${persistEndDate.endDay}-${persistEndDate.endMonth}-${persistEndDate.endYear}`}</span></p> 
          <TiDelete onClick={(e) => handleClearFilter()}/></>}
            <BsCalendar4Week
              className="calendar-icon"
              onClick={() => setRange(!range)}
            />
            </div>
          </div>
        </div>

        {isExtensionInstalled == "NO" && (
          <ToasterNotification
            onClose={() => setIsExtensionInstalled(true)}
            variant="danger"
            content={
              <p>
                The Qikfinds Chrome Extension is required for Qikfinds to work.
                Please Click <a href="https://chrome.google.com/webstore/detail/qik-finds/hakjiagadepdajkkhjcplkdinlkgakal" target='_blank'> Here</a> to install it.
              </p>
            }
            icon={infoIcon}
          />
         )}
        <div className="table-box">
          <Table
            height={isExtensionInstalled == "NO" ? '291px' : '204px'}
            headerHeight={50}
            columnDefinitions={columnDefinition}
            defaultColDef={defaultColDefs}
            rowSelection='multiple'
            onSortChange={onSortChanged}
            rowData={tableRows}
            rowHeight={67}
            onFilterChanged ={onFilterChanged}
            loading = {loading}
            onSelectionChanged = {onSelectionChanged}
            isRowSelectable = {isRowSelectable}
          />
          <TablePagination
            background="#F8F8F8"
            records={totalFavouriteProducts}
            options={pageOptions}
            onChange={handleOnPageChange}
            pageNumber={pageNumber}
            pageLimit={pageLimit}
          />
        </div>
        <Modal
          className="cancel-modal export-modal"
          open={exportModal}
          saveText="Export Sheet"
          closeText="Cancel"
          onSave={() => {exportData()}}
          onClose={() => {setExportModal(false); setExportFilter('')}}
        >
          <h6>Export Data</h6>
          <Radio.Group onChange={exportModalOnChange}>
            <Space direction="vertical">
              <Radio value={"selectedAsins"}>Export Selected Asin</Radio>
              <Radio value={"allAsins"}>Export All Asins</Radio>
              <Radio value={"currentPage"}>Export Current Page</Radio>
            </Space>
          </Radio.Group>
          
        </Modal>
        <Modal
          className="cancel-modal"
          open={popupDelete}
          saveText="Delete"
          closeText="Cancel"
          onSave={() => handleDeleteProduct()}
          onClose={() => setPopupDelete(false)}
        >
          <div className="category-modal-wrapper">
            <img src={IconTrash} alt="no/" className="icon-popup" />
            <h2>Are you sure want to delete this list?</h2>
            <p>
              The selected list will be deleted and it will be removed from our
              system.
            </p>
          </div>
        </Modal>
        <Modal
          className="cancel-modal"
          open={keepaGraphModal}
          saveText="Delete"
          closeText="Cancel"
          onClose={() => setkeepaGraphModal(false)}
        >
          <div className="category-modal-wrapper">
            <iframe
              src="https://keepa.com/#!product/1-B082Y3T3TH"
              name="keepa iframe"
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </Modal>
        <Modal
          className="edit-modal-wrapper"
          open={editNote}
          saveText="Save"
          closeText="Cancel"
          onSave={() => updateProductNote(true)}
          onClose={() => setEditNote(false)}
          >
            <h6 className='edit-note-modal-text'>Edit Note</h6>
            <textarea className='text-area-note-modal' value={productNote} onChange={e => setProductNote(e.target.value) } placeholder="Edit Note"/>
        </Modal>
      </DashboardWrapper>
      {loading && <Spin />}
    </>
  );
};

export default Index;
