import React from "react";
import { Pagination } from 'antd';
import { PaginationWrapper } from "./style";

const TablePagination = (props) => {
  const {
    background,
    records,
    options,
    pageNumber
  } = props;

  const onShowSizeChange = (current, pageSize) => {
    props.onChange(current, pageSize)
  };

  return (
    <PaginationWrapper background={background} className="pagination-wrapper d-flex align-items-center position-relative">
      <div className="pagination-wrapper d-flex justify-content-between align-items-center">
        <span>{records} {records > 1 ? 'records' : 'record'}</span>
        <div className="d-flex justify-content-between align-items-center">
          <Pagination size="sm" className="table-pagination"
            pageSizeOptions={options}
            onChange={onShowSizeChange}
            current={pageNumber}
            total={records}
          />
        </div>
      </div>
    </PaginationWrapper>
  );
};
export default TablePagination;
