import US from '../assets/images/flags/US.svg';
import CA from '../assets/images/flags/CA.svg';
import MX from '../assets/images/flags/MX.svg';
import BR from '../assets/images/flags/BR.svg';
import EG from '../assets/images/flags/EG.svg';
import FR from '../assets/images/flags/FR.svg';
import GB from '../assets/images/flags/GB.svg';
import IN from '../assets/images/flags/IN.svg';
import IT from '../assets/images/flags/IT.svg';
import NL from '../assets/images/flags/NL.svg';
import PL from '../assets/images/flags/PL.svg';
import SE from '../assets/images/flags/SE.svg';
import SA from '../assets/images/flags/SA.svg';
import TR from '../assets/images/flags/TN.svg';
import SG from '../assets/images/flags/SG.svg';
import AU from '../assets/images/flags/AU.svg';
import JP from '../assets/images/flags/JP.svg';
import AE from '../assets/images/flags/AE.svg';
import DE from '../assets/images/flags/DE.svg';
import ES from '../assets/images/flags/ES.svg';

import { CURRENCY_SYMBOLS_WRT_MARKETPLACE } from '../constants/constants';

export const FormatAmount = (amount) => {
  let newAmount = parseFloat(parseFloat(amount || 0).toFixed(2));
  newAmount = newAmount.toLocaleString(undefined, { minimumFractionDigits: 2 });
  return newAmount;
};

export const FormatNumber = (number) => number.toLocaleString();

export const FormatAmountWithCurrency = (amount = 0, marketplaceId) => {
  
  if (marketplaceId === 'n/a') {
    return `$${amount}`
  }

  if (amount >= 0) {
    return `${CURRENCY_SYMBOLS_WRT_MARKETPLACE[marketplaceId]}${FormatAmount(amount)}`;
  } else {
    return `-${CURRENCY_SYMBOLS_WRT_MARKETPLACE[marketplaceId]}${FormatAmount(amount * -1)}`;
  }
};

export const marketplaceFlags = (region) => {
  if (region === 'na') {
    return [
      {
        name: "US",
        country: US,
      },
      {
        name: "CA",
        country: CA,
      },
      {
        name: "MX",
        country: MX,
      },
      {
        name: "BR",
        country: BR,
      },
    ];
  }
  if (region === 'eu') {
    return [
      {
        name: "AE",
        country: AE,
      },
      {
        name: "DE",
        country: DE,
      },
      {
        name: "IT",
        country: IT,
      },
      {
        name: "EG",
        country: EG,
      },
      {
        name: "ES",
        country: ES,
      },
      {
        name: "FR",
        country: FR,
      },
      {
        name: "GB",
        country: GB,
      },
      {
        name: "IN",
        country: IN,
      },
      {
        name: "NL",
        country: NL,
      },
      {
        name: "PL",
        country: PL,
      },
      {
        name: "SE",
        country: SE,
      },
      {
        name: "SA",
        country: SA,
      },
      {
        name: "TR",
        country: TR,
      },
    ];
  }
  if (region === 'fe') {
    return [
      {
        name: "SG",
        country: SG,
      },
      {
        name: "AU",
        country: AU,
      },
      {
        name: "JP",
        country: JP,
      },
    ];
  }
}

export const isContainSpecialCharacter = ({ searchKeyword }) => {
  const spChars = ['[', ']', '{', '}', '(', ')', '/', '\\', '?', '+', '$', '^', '*', '|', '.'];
  if (spChars.some(specialChar => searchKeyword?.includes(specialChar))) {
    return true;
  } else {
    return false;
  }
}

export const plansData = {
  Lite: {
    monthly: {
      price: 9,
      originalPrice: 9,
      text: 'Mo'
    },
    yearly: {
      price: 90,
      originalPrice: 90,
      text: 'Yr (Save 17%)'
    }
  },
  Standard: {
    monthly: {
      price: 49,
      originalPrice: 49,
      text: 'Mo'
    },
    yearly: {
      price: 490,
      originalPrice: 490,
      text: 'Yr (Save 17%)'
    }
  },
  Growth: {
    monthly: {
      price: 99,
      originalPrice: 99,
      text: 'Mo'
    },
    yearly: {
      price: 594,
      originalPrice: 594,
      text: 'Yr (Save 50%)'
    }
  },
  Team: {
    monthly: {
      price: 199,
      originalPrice: 199,
      text: 'Mo'
    },
    yearly: {
      price: 1194,
      originalPrice: 1194,
      text: 'Yr (Save 50%)'
    }
  },
}