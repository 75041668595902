import React  from 'react';

import { SelectPlanWrapper } from '../../Auth/style'
import Header from '../../../component/header';
import SubscriptionPlanCard from '../../../component/subscriptionCard';

const SelectPlan = () => {

  const data = [
    {
      text: 'ASIN'
    },
    {
      text: 'Amazon Product Title'
    },
    {
      text: 'Amazon Product Image'
    },
    {
      text: 'Meltable / Restriction / HAZMAT'
    },
    {
      text: 'Sales Rank & Category'
    },
    {
      text: 'Brand and Manufacturer'
    },
    {
      text: 'Store Discounts/Promos'
    },
    {
      text: 'Profit Calculation'
    },
    {
      text: 'Keepa Graph'
    },
    {
      text: 'Ratings & Reviews'
    },
    {
      text: 'Sold History'
    },
    {
      text: 'On-Hand Inventory'
    },
    {
      text: 'Rank Drops (30/90/180)'
    },
    {
      text: 'FBA / FBM Offers'
    },
    {
      text: 'Keepa New Offer Count Graph'
    },
    {
      text: 'Google Product Search'
    },
    {
      text: 'Multipack Finders'
    },
    {
      text: 'Research Criteria Filters'
    },
    {
      text: 'Custom Notes'
    },
    {
      text: 'Add Favorites'
    },
    {
      text: 'Data Sync With Dashboard'
    },
    {
      text: 'Non-UPC Product Search'
    },
  ]

  return (
    <>
      <Header/>
    <SelectPlanWrapper>
    <div className="right-side-content">
      <div className='heading-wrapper'><h2>Subscription Plans</h2>
          <p>Choose any plan to continue using QikFinds.</p>
         </div>
         </div>
      <div className='cards-overlay-wrapper'>
      <SubscriptionPlanCard className="card-overaly" heading="Lite" labelText="All Supported UPC Stores" data={[
            {
              text: 'UPC'
            },
            {
              text: 'Search title on Amazon'
            },
            {
              text: 'Optional (API required):'
            },
            {
              text: 'ASIN'
            },
            {
              text: 'Keepa Graph'
            }
          ]} />
        <SubscriptionPlanCard className="card-overaly" heading="Standard" labelText="50 Popular Store List (UPC only)" data={data} />
        <SubscriptionPlanCard className="card-overaly" heading="Growth" labelText="100 Selected UPC & Non-UPC Stores" data={data} />
        <SubscriptionPlanCard className="card-overaly" heading="Team" labelText="All Store List" data={data} />
     </div>
    </SelectPlanWrapper>
    </>
  )
}

export default SelectPlan
