import styled from "styled-components";

const SubscriptionPlanWrapper = styled.div`
  background: #f9fafc;
  display: flex;
  .left-side-content {
    width: 100%;
  }

  span.see-more-button-wrapper {
    font-size: 16px;
    font-weight: 600;
    color: #0057d3;
    border-bottom: 2px solid #0057d3;
    margin-top: 12px;
    text-transform: capitalize;
    cursor: pointer;
}
  .strike-price{
    color: red;
    font-size: 15px;
    margin-top: 15px;
    span{
      text-decoration:line-through;
      font-weight: 700;
    }
  }
  .coupon-button {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    border: 1px solid rgb(0, 87, 211);
    padding: 7px 22px;
    border-radius: 4px;
    font-weight: 700;
    background: transparent;
    color: rgb(0, 87, 211);
    &:hover{
      background-color: rgb(0, 87, 211);
      color:#fff;
    }
}
      .yearly-toggle-menu{
      position: absolute;
      right: 6px;
      top: 6px;
      display: flex;
      align-items: end;
      grid-gap:4px;
      span{
        margin: 0;
        color: rgb(13, 22, 27);
        font-size: 12px;
        font-weight:700;
      }
    }
    .ant-switch-small {
    margin-top: 0px !important;
}
  .subscription-header-overlay-wrapper {
    display: flex;
    grid-gap: 0px;
    position: relative;
    justify-content: center;
    padding-top: 24px;
    width: 100%;
    .yearly-toggle-menu{
      position: absolute;
      right: -14px;
      top: -14px;
      display: flex;
      align-items: end;
      grid-gap:4px;
      span{
        margin: 0;
        color: rgb(13, 22, 27);
        font-size: 12px;
        font-weight:700;
      }
    }
    .ant-switch-small {
    margin-top: 0px !important;
}
  }
  .subscription-header-wrapper {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    grid-row-gap: 0px;
    position: relative;
    justify-content: center;
    align-items: center;
    span.coupon-ui-wrapper {
      position: absolute;
    -webkit-transform: rotate(39deg);
    -ms-transform: rotate(39deg);
    -webkit-transform: rotate(39deg);
    -ms-transform: rotate(39deg);
    transform: rotate(310deg);
    color: #fff;
    padding: 4px 39px;
    font-weight: 700;
    border-radius: 4px;
    font-size: 13px;
    top: 0px;
    left: -163px;
    padding: 4px 49px;
    background: #0057d3;
}
}
  .right-side-content {
    width: 100%;
    margin-left: 80px;
    margin-top: 62px;
}
.unsubscription-overlay-wrapper {
  background: #fff;
    margin: 0px;
    width: 100%;
}
.right-side-content.unsubscription-wrapper {
    margin: 0px;
}
  button.ant-btn.ant-btn-default.subscription-plan-btn {
    margin: auto;
    text-align: center;
    display: flex;
    
  }
  .main-wrapper {
    height: 100vh;
    background: #fbe9f4;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    img {
      padding-left: 0px;
      padding-top: 52px;
    }
  }
  button.ant-btn.ant-btn-default.subscription-plan-btn {
    border: 1px solid blue;
    background: transparent;
    border-radius: 4px;
    color: blue;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.subscription-overlay-wrapper {
    display: flex;
    align-items: flex-start;
    margin-top:16px;
}
  .content-area h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    margin-top: 36px;
    padding: 24px 53px 0px;
  }
  .new-cards-ui{
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 16px;
  }
  .subscription-overlay-wrapper{
    .subscription-plan-item-wrapper {
    max-width: 220px;
    padding: 16px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    margin-left: 106px;

  }
  }
  .subscription-item-header {
    border: 2px solid #efefef;
    padding: 16px 70px;
    border-radius: 4px;
    position: relative;
    overflow:hidden;
    /* span.bag-wrapper {
    background: #34C38F;
    color: #fff;
    font-weight: 600;
    padding: 2px 14px;
    position: absolute;
    top: 18px;
    font-size: 10px;
    right: -23px;
    transform: rotate(48deg);
} */
span.coupon-ui-wrapper {
      position: absolute;
    -webkit-transform: rotate(39deg);
    -ms-transform: rotate(39deg);
    -webkit-transform: rotate(39deg);
    -ms-transform: rotate(39deg);
    transform: rotate(310deg);
    color: #fff;
    padding: 4px 39px;
    font-weight: 700;
    border-radius: 4px;
    font-size: 13px;
    top: 39px;
    left: -57px;
    padding: 4px 49px;
    background: #0057d3;
}
h5{
    margin: 0px;
    color: rgb(13, 22, 27);
    font-size: 24px;
    margin-bottom:4px ;
    span{
      font-size: 12px;
      font-style: italic;
    }
}
.sub-text-item{
  margin-top: 8px;
    margin-bottom: 4px;
}
h4{
    margin-top: 16px;
    color: rgb(13, 22, 27);
    font-size: 14.4px;
    font-weight:700 ;
    margin-bottom:4px ;
}
h6{
    margin: 0;
    color: rgb(13, 22, 27);
    font-size: 14.4px;
    font-weight:700 ;
    margin-bottom:4px ;
}
p{
    font-weight: 400 !important;
    margin-bottom:0px;
}
button{
  margin-top:16px ;
  margin-bottom:0px  ;
}
}
.subscription-overlay-wrapper .subscription-item-header button {
    margin-top: 16px;
}

  .subscription-plan-wrapper {
    margin-left: 30px;
    margin-top: 80px;
    width: 100%;
    .subscription-plan-tile-wrapper {
      margin-top: 40px;
      .subscription-plan-item-wrapper {
        border: 2px solid #e3e6ef;
        padding: 24px 20px;
        border-radius: 10px;
        margin: auto;
        display: flex;
        overflow: hidden;
        transition: 0.3s all ease-in;
        &:hover {
          border: 2px solid #5f63f2;
          cursor: pointer;
          transition: 0.3s all ease-in;
          .ant-btn {
            background: #5f63f2;
            color: #fff;
            transition: 0.3s all ease-in;
          }
        }
      }
    }
  }
  .subscription-item-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 288px;
    padding: 20px;
    &.basic-plain-wrapper{
      height: 260px;
    }
    .new-deal-label {
      position: absolute;
      top: -31px;
      right: -39px;
      background: #34c38f;
      transform: rotate(45deg);
      padding: 0 24px;
      color: #fff;
      font-size: 8px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
    }
    h3 {
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0px;
      padding: 8px 16px;
      text-align: center;
      background: #5f63f21f;
      mix-blend-mode: normal;
      border-radius: 16px;
      color: #5f63f2;
      position: relative;
      top: -2px;
      min-width:132px ;
    }
    p {
      span.doller-sign {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        color: #adb4d2;
        margin-right: 1px;
        margin-top: 9px;
      }

      .price-text {
        font-size: 36px;
        font-weight: 400;
        line-height: 44px;
        letter-spacing: 0px;
        text-align: left;
        color: #272b41;
      }
      .month-text {
        align-self: end;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: left;
        position: relative;
        top: -7px;
        color: #adb4d2;
      }
    }
  }
  .plans-list-wrapper {
    li {
      font-size: 12px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0px;
      text-align: left;
      color: #4b5664;
      margin-left: -2px;
      svg {
        color: #20c997;
        margin-right: 12px;
      }
    }
  }
`;

export { SubscriptionPlanWrapper };
