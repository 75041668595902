import React from "react";
import { Route, Routes, Switch } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isEmpty } from "lodash";

import SignUp from "../pages/Auth/sign-up";
import ResetPasswordVerification from "../pages/Auth/reset-password-confirmation";
import SignIn from "../pages/Auth/sign-in";
import ForgetPassword from "../pages/Auth/forget-password";
import ResetPassword from "../pages/Auth/reset-password";
import RegisterSubUser from "../pages/Auth/register-sub-user";
import WaitingScreen from "../pages/OnBoarding/waiting-screen";
import Congratulations from "../pages/OnBoarding/congratulation";
import SubscriptionPlan from "../pages/PaymentsScreen/SubscriptionPlan";
import PaymentScreen from '../pages/PaymentsScreen';

import PublicRoute from './public-route';
import PrivateRoute from './private-route';

import { setAuthToken } from '../config/axios-configuration';
import Verification from "../pages/Auth/verification";
import ConfirmEmailVerification from "../pages/Auth/confirm-email-verification";
import SelectPlan from "../pages/Auth/select-plan";

const Index = () => {
  const { token } = useSelector(state => state.auth);
  setAuthToken(token);

  return (
    <Switch>
      <PublicRoute exact path="/auth/pricing" component={SelectPlan} />
      <PublicRoute exact path="/auth/sign-up" component={SignUp} />
      <PublicRoute exact path="/auth/sign-in" component={SignIn} />
      <PublicRoute exact path='/auth/payment' component={PaymentScreen} />
      <Route exact path="/auth/forget-password" component={ForgetPassword} />
      <PrivateRoute exact path="/auth/verification" component={Verification} />
      <Route exact path="/auth/email-verification/:userId" component={ConfirmEmailVerification} />
      <Route exact path="/auth/reset-password/:userId" component={ResetPassword} />
      <Route exact path="/auth/register-sub-user/:userId" component={RegisterSubUser} />
      <Route exact path="/auth/verify-reset-password" component={ResetPasswordVerification} />
      <PrivateRoute exact path="/auth/sp-api/" component={WaitingScreen} />
      <PrivateRoute exact path="/auth/congratulations" component={Congratulations} />
      <PrivateRoute exact path="/auth/subscription-plan" component={SubscriptionPlan} />
    </Switch>
  );
};

export default Index;
