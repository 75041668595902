import styled from "styled-components";

const StoresStyleWrapper = styled.div`
&.drawer-wrapper-store{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 12vh);
  .ag-root-wrapper.ag-layout-normal {
    height: 78vh;
}
}

.brand-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header{
  background-color: transparent;
  padding:0px;
  h1{
    font-weight: 900;
    font-size: 24px;
    color: #272B41;
    margin-bottom: 0;
    height:43px;
  }
}
span.ant-avatar.ant-avatar-lg.ant-avatar-square {
    background: #0057d3 ;
    cursor: pointer;
}
.modal-inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 0px;
    margin-top: 32px;
    svg {
    font-size: 60px;
    color: red;
    margin-bottom: 16px;
}
p {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    margin-top: 4px;
}
}
footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    grid-gap: 21px;
}

footer button {
    background: #0057d3 !important;
    color: #fff !important;
}

footer button:nth-child(1) {
    background: transparent !important;
    border: 1px solid #0057d3 !important;
    color: #0057d3 !important;
}
.header-drawer{
  display:flex;
  align-items: center;
  justify-content: space-between;
  .ant-select{
    width:230px;
  }
}
.drawer-footer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-top: 16px;
    .amount-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 24px;
    justify-content: end;
    h2 {
    margin: 0;
    font-size: 16px;
}
    p {
    margin: 0;
    font-size: 16px;
}
}
button {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    grid-gap: 8px;
    margin-top: 16px;
    background: #0057d3;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
    text-align: center;
    justify-content: center;
    font-size: 18px;
}
}
.filter-search{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  div{
  .link-text{
    svg {
    position: absolute;
    top: 8px;
    right: 24px;
}
  }
  }
}
button.ant-btn.ant-btn-default.active-class {
    background: green;
    color: #fff;
}
h6{
  font-weight: 600;
  font-size:24px ;
}
input{
  border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 14px;
    max-width: 100px;
}
button.button-close{
  display: block;
  margin-left: auto;
  margin-top: 24px;
}
button{
    background:  #2589ff;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
    border: none;
}
.modal-table-box {
    margin-top: 24px;
    p{
      margin: 0;
    }
}
.table-box{
  margin-top: 24px;
  p.store-name {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: #2589ff;
    cursor: pointer;
}
p{
  margin: 0;
}
}
`;
export { StoresStyleWrapper };
