import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const ExportDashboardProducts = createAsyncThunk(
  'user/exportDashboardProducts',
  async (data, { rejectWithValue }) => {
    try {
      const getQueryString = (p) => {
        const esc = encodeURIComponent;
        return Object.keys(p)
          .map(k => `${esc(k)}=${esc(data[k])}`)
          .join('&');
      };

      const url = (process.env.NODE_ENV === 'development')
        ? `http://localhost:4000/v1/auth/export-dashboard-products?${getQueryString(data)}`
        : `${process.env.API_URL}/auth/export-dashboard-products?${getQueryString(data)}`;

      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetFavouriteProducts = createAsyncThunk(
  'products/getFavouriteProducts',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/product/get-favourite-products', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetSingleProductDetail = createAsyncThunk(
  'products/getSingleProductDetail',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/product/get-single-product-detail', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const SaveProductNote = createAsyncThunk(
  'products/saveProductNote',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/product/save-product-note', {
        ...data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const DeleteFavouriteProduct = createAsyncThunk(
  'products/deleteFavouriteProduct',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/product/delete-favourite-product', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const product = createSlice({
  name: 'products',
  initialState: {
    success: false,
    loading: true,
    favouriteProducts: [],
    totalFavouriteProducts: 0,
    singleProductDetail: {},
    error: '',
    name: '',
    message: ''
  },
  reducers: {
    SetProductState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    clearState(state, action) {
      return {
        ...state,
        storeNames: []
      };
    }
  },
  extraReducers: {
    [ExportDashboardProducts.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [ExportDashboardProducts.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true
    }),
    [ExportDashboardProducts.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      err: action.payload.err?.message
    }),
    [GetFavouriteProducts.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetFavouriteProducts.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      totalFavouriteProducts: action.payload.totalFavouriteProducts,
      favouriteProducts: action.payload.favouriteProducts
    }),
    [GetFavouriteProducts.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [GetSingleProductDetail.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetSingleProductDetail.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      singleProductDetail: action.payload.singleProductDetail
    }),
    [GetSingleProductDetail.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [SaveProductNote.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [SaveProductNote.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message,
    }),
    [SaveProductNote.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [DeleteFavouriteProduct.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [DeleteFavouriteProduct.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message
    }),
    [DeleteFavouriteProduct.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    })
  }
});

const { reducer, actions } = product;

export const {
  clearState,
  SetProductState
} = actions;

export default reducer;
