import styled from "styled-components";

const SubSuersWrapper = styled.div`
.sub-user-check{
  font-size: 20px;
    color: green;
    cursor: auto;
    text-align: right;
    margin-left: auto;
    display: block;
    width: 118px;
}
.sub-user-not-check{
  font-size: 20px;
    color: red;
    cursor: auto;
    text-align: right;
    margin-left: auto;
    display: block;
    width: 118px;
}
  h1 {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #272b41;
    margin-bottom: 0;
  }
  .trash-icon{
    width:16px;
    cursor: pointer;
    margin-right: 16px;
  }
  .ag-center-cols-container{
    width:100% ;
  }

  .filter-search {
    input {
      min-width: 252px;
      width: 252px;
      padding-right: 35px;
    }
  }
  .right-side-action {
    align-items: center;
    grid-gap: 16px;
    margin-bottom: 24px;
    input{
      padding-right: 32px;
    }
    button {
      margin: 0px;
      &:hover{
        background-color: #0057d3;
        color:#fff;
      }
    }
  }
`;

export { SubSuersWrapper };
