import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';
const axios = axiosBaseUrl();

export const CancelSubscription = createAsyncThunk(
  'user/cancelSubscription',
  async (data, { rejectWithValue }) => {
    try {
      const {
        impersonateUserId,
        userId
      } = data || {};

      const response = await axios.post('/user/cancel-user-subscription', {
        impersonateUserId,
        userId
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const ExportUsers = createAsyncThunk(
  'user/exportUsers',
  async (data, { rejectWithValue }) => {
    try {
      const getQueryString = (p) => {
        const esc = encodeURIComponent;
        return Object.keys(p)
          .map(k => `${esc(k)}=${esc(data[k])}`)
          .join('&');
      };

      const url = (process.env.NODE_ENV === 'development')
        ? `http://localhost:4000/v1/auth/export-users?${getQueryString(data)}`
        : `${process.env.API_URL}/auth/export-users?${getQueryString(data)}`;

      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetUser = createAsyncThunk(
  'user/getUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user/get-user', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetUsers = createAsyncThunk(
  'user/getUsers',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user/get-users', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetSubUsers = createAsyncThunk(
  'user/getSubUsers',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user/get-sub-users', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetReferralUsers = createAsyncThunk(
  'user/getReferralUsers',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user/get-referral-users', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetUserInvoices = createAsyncThunk(
  'user/getUserInvoices',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user/get-user-invoices', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const InviteUser = createAsyncThunk(
  'user/inviteUser',
  async (data, { rejectWithValue }) => {
    try {
      const { email } = data;

      const response = await axios.post('/user/invite-user', {
        email
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const UpdateProfileInfo = createAsyncThunk(
  'user/updateProfileInfo',
  async (data, { rejectWithValue }) => {
    try {
      const {
        impersonateUserId,
        name,
        newPassword,
        oldPassword
      } = data;

      const response = await axios.post('/user/update-profile-info', {
        impersonateUserId,
        name,
        newPassword,
        oldPassword
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const UpdateUserPassword = createAsyncThunk(
  'user/updateUserPassword',
  async (data, { rejectWithValue }) => {
    try {
      const {
        userId,
        password
      } = data;

      const response = await axios.post('/user/update-user-password', {
        userId,
        password
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const DeleteUser = createAsyncThunk(
  'user/deleteUser',
  async (data, { rejectWithValue }) => {
    try {
      const { userId } = data;

      const response = await axios.post('/user/delete-user', {
        userId
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const AddSubUser = createAsyncThunk(
  'user/addSubUser',
  async (data, { rejectWithValue }) => {
    try {
      const {
        email,
        name,
        userId,
        specialSubUser
      } = data;
      const response = await axios.post('/user/add-sub-user', {
        email,
        name,
        userId,
        specialSubUser
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const user = createSlice({
  name: 'user',
  initialState: {
    error: '',
    loading: true,
    message: '',
    success: false,
    token: '',
    user: {},
    subscriptionData: {},
    totalUsers: 0,
    users: [],
    totalReferredUsers: 0,
    balance: 0,
    referrals: [],
    invoices: [],
    impersonateUserData: {
      userId: '',
      name: '',
      email: ''
    },
    subscriptionCancelled: false,
    userSuccess: false
  },
  reducers: {
    SetUserState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [CancelSubscription.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [CancelSubscription.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message,
      subscriptionCancelled: true
    }),
    [CancelSubscription.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      error: action.payload.err?.message
    }),
    [ExportUsers.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [ExportUsers.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true
    }),
    [ExportUsers.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      error: action.payload.err?.message
    }),
    [GetUser.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true,
      userSuccess: false
    }),
    [GetUser.fulfilled]: (state, action) => ({
      ...state,
      user: action.payload.user,
      success: true,
      userSuccess: true,
      loading: false
    }),
    [GetUser.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    }),
    [GetUsers.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [GetUsers.fulfilled]: (state, action) => ({
      ...state,
      totalUsers: action.payload.totalUsers,
      users: action.payload.users,
      success: true,
      loading: false
    }),
    [GetUsers.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    }),
    [GetSubUsers.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [GetSubUsers.fulfilled]: (state, action) => ({
      ...state,
      totalUsers: action.payload.totalUsers,
      users: action.payload.users,
      success: true,
      loading: false
    }),
    [GetSubUsers.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    }),
    [GetReferralUsers.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [GetReferralUsers.fulfilled]: (state, action) => ({
      ...state,
      totalReferredUsers: action.payload.totalReferredUsers,
      referrals: action.payload.referrals,
      balance: action.payload.balance,
      success: true,
      loading: false
    }),
    [GetReferralUsers.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    }),
    [GetUserInvoices.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [GetUserInvoices.fulfilled]: (state, action) => ({
      ...state,
      invoices: action.payload.invoices,
      success: true,
      loading: false
    }),
    [GetUserInvoices.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    }),
    [UpdateProfileInfo.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [UpdateProfileInfo.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      success: true,
      loading: false
    }),
    [UpdateProfileInfo.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    }),
    [UpdateUserPassword.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [UpdateUserPassword.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      success: true,
      loading: false
    }),
    [UpdateUserPassword.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    }),
    [DeleteUser.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [DeleteUser.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      success: true,
      loading: false
    }),
    [DeleteUser.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    }),
    [InviteUser.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [InviteUser.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      success: true,
      loading: false
    }),
    [InviteUser.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    }),
    [AddSubUser.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [AddSubUser.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      success: true,
      loading: false
    }),
    [AddSubUser.rejected]: (state, action) => ({
      ...state,
      error: action.payload.err.message,
      success: true,
      loading: false
    })
  }
});

const { reducer, actions } = user;

export const { SetUserState, LogOut } = actions;

export default reducer;
