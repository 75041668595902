import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory,
  useParams
} from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import ArrowLeft from "../../assets/images/arrow-left.svg";
import Button from "../../component/Button/Index";
import Input from "../../component/inputs/input/index";
import PageHeader from "../../component/header";
import Spin from '../../component/Spin';
import Notification from '../../component/notifications/notification';

import {
  EditStoreData,
  GetActiveMarketplaces,
  GetStore,
  SetStoreState
} from '../../redux/slices/store-slice';

import { StoreConnectivity } from "../authOnboarding-style";

import { REGIONS } from '../../constants/constants';

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { storeId } = useParams();
  const {
    store,
    activeMarketplaces,
    storeName: name,
    storeRegion,
    error,
    loading,
    success,
    message
  } = useSelector(state => state.store);

  const [storeName, setStoreName] = useState(name || '');
  const [regionName, setRegionName] = useState('North America Region');
  const [marketplaceIdsArray, setMarketplaceIdsArray] = useState([]);

  const handleMarketplaceChecked = (marketplaceId) => {
    const id = marketplaceIdsArray.find(a => a === marketplaceId);
    if (id) {
      const filtered = marketplaceIdsArray.filter(a => a !== marketplaceId);
      setMarketplaceIdsArray(filtered);
    } else {
      const idsArray = [...marketplaceIdsArray, marketplaceId];
      setMarketplaceIdsArray(idsArray);
    }
  };

  const handleEditStoreData = () => {
    if (storeName) {
      dispatch(EditStoreData({
        storeId,
        storeName,
        marketplaces: marketplaceIdsArray
      }));
    } else {
      dispatch(SetStoreState({ field: 'error', value: 'Store Name cannot be empty'}));
    }
  };

  const handleBack = () => {
    dispatch(SetStoreState({ field: 'tab', value: 'dashboard' }));
    history.push('/dashboard');
  };

  useEffect(() => {
    if (storeId) {
      dispatch(GetStore({ storeId }));
      dispatch(GetActiveMarketplaces({ storeId }));
      if (storeRegion === 'na') {
        setRegionName('North America Region')
      } else if (storeRegion === 'eu') {
        setRegionName('Europe Region')
      } else {
        setRegionName('Far East Region')
      }
    } else {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    if (store) {
      const { marketplaces } = store || {};
      const marketplaceIds = marketplaces?.map(item => item.marketplaceId);
      setMarketplaceIdsArray(marketplaceIds);
    }
  }, [store]);

  useEffect(() => {
    if (success && message) {
      Notification({
        type: 'success',
        title: 'Success',
        description: message
      });
      dispatch(SetStoreState({ field: 'success', value: false }));
      dispatch(SetStoreState({ field: 'message', value: '' }));

      history.push('/profile');
      return;
    }

    if (error) {
      Notification({
        type: 'error',
        title: 'Error',
        description: error
      });
      dispatch(SetStoreState({ field: 'error', value: '' }));
      return;
    }
  }, [error, message, success]);

  return (
    <>
      <PageHeader />
      { loading ? <Spin /> : <>
      <StoreConnectivity>
        <div className="onboarding-title ">
          <h1 className="d-flex align-items-center">
            <img width='32px' height='32px' src={ArrowLeft} alt='no-img' onClick={() => handleBack()}/>
            Edit Marketplaces
          </h1>
        </div>

        <div className="store-advertising">
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
            <div className="edit-store-search-input">
              <Input
                  placeholder="Type Store Name Here"
                  onChange={(e) => setStoreName(e.target.value)}
                  value={storeName}
                />
              </div>
              <div className="marketplace-region">
                <h3>MarketPlace Region</h3>
                <h5>{regionName}</h5>
                <div className="region-list">
                  {(activeMarketplaces && activeMarketplaces.length) && REGIONS[storeRegion].filter(markeplace => activeMarketplaces.some(activeMarketplace => markeplace.marketplaceId === activeMarketplace))?.map((market, index) => {
                    return (
                      <Form.Check
                      checked={marketplaceIdsArray.findIndex(a => a === market.marketplaceId) > -1 ? true : false}
                      onChange={() => handleMarketplaceChecked(market.marketplaceId)}
                      label={
                        <>
                          <img src={market.image} alt="country flag"/> <span>{market.name}</span>
                        </>
                      }
                    />
                    )
                  })}
                  <Button width={'196px'} outlined="1" onClick={() => handleEditStoreData()}>Save</Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </StoreConnectivity>
      </>}
    </>
  );
};

export default Index;