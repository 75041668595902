import styled from "styled-components";

export const OrdersWrapper = styled.div`
.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height {
    display: flex;
    align-items: center;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 24px;
  h1{
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #272B41;
    margin-bottom: 0;
  }
  .action {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    .calendar-icon{
      color:${({ theme }) => theme["link-color"]};
      font-size:16px;
      border: 1px solid ${({ theme }) => theme["link-color"]};
      width: 32px;
      height: 32px;
      padding: 5px;
      border-radius: 4px;
    }
    .date-wrapper {
    position: absolute;
    right: 0;
    top: 64px;
    background: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 16px;
    border-radius: 8px;
    z-index: 103455;
    .buttons{
      display:flex;
      justify-content: end;
      grid-gap: 16px;
      button{
          border-color: #0b5ed7;
          background-color: #0b5ed7;
          color: #fff;
        &:not(:last-child){
          border-color: #0b5ed7;
          background-color: #fff;
          color: #0b5ed7;
        }
      }
    }
}
}
}
`;
