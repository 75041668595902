import styled from "styled-components";
import { Modal } from "react-bootstrap";

const ModalWrapper = styled(Modal)`
  .select-address {
    .modal-header {
      border-bottom: none;
    }
  }
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 24px;
  }
  .modal-header {
    padding-top: 26px;
    border-bottom: 0px;
    padding-bottom: 27px;
  }
  .modal-dialog {
    max-width: 660px;
  }
  &.shipment-modal {
    .modal-dialog {
      max-width: 620px;
    }
    .modal-footer {
      padding-top: 0px;
    }
  }
  &.cancel-modal {
    .modal-dialog {
      max-width: 416px;
    }
    .modal-content {
      text-align: center;
      padding-left: 27px;
      padding-right: 27px;
      .icon-popup {
        font-size: 65px;
        color: ${({ theme }) => theme["danger-color"]};
        margin-bottom: 32px;
      }
      h2 {
        font-weight: 900;
        font-size: 24px;
        line-height: 28px;
        color: #272b41;
        letter-spacing: 0.02em;
        margin-bottom: 16px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #5a5f7d;
        margin-bottom: 0;
      }
      .icon-exclamation {
        font-size: 64px;
        color: ${({ theme }) => theme["danger-color"]};
      }
      .modal-body {
        padding-bottom: 16px !important;
        padding-top: 24px !important;
      }
    }
    .modal-footer {
      display: flex;
      padding: 24px;
      justify-content: center;
      /* flex-direction: row-reverse; */
      padding-top: 8px;
      gap: 16px;
      .btn-cancel {
        margin-right: 0;
        color: ${({ theme }) => theme["label-color"]};
        border: 1px solid transparent;
        border-radius: 4px;
        margin: 0;
        padding: 7px 16px;
        height: 32px;
      }
      .btn-save {
        color: ${({ theme }) => theme["link-color"]};
        border: 1px solid ${({ theme }) => theme["link-color"]};
        background: transparent;
        border-radius: 4px;
        margin: 0;
        padding: 7px 16px;
        height: 32px;
      }
    }
  }
  &.graph-modal {
    .graph-modal-wrapper {
      position:relative;
      .graphImage {
        width: 100%;
        border-radius: 0.5rem;
      }
      .close-icon{
        position:absolute;
        right:12px;
        top:12px;
        cursor: pointer;
      }
    }
    .modal-footer {
      display: none;
    }
    .modal-body{
      padding:0!important;
    }
    .modal-dialog{
      max-width:817px;
    }
  }
  .modal-body {
    padding-top: 2px !important;
    padding-bottom: 0 !important;
    .row {
      margin-left: -15px;
      margin-right: -15px;
    }
    [class*="col-"] {
      padding-left: 15px;
      padding-right: 15px;
    }
    .input-wrapper {
      padding-bottom: 9px;
      line-height: 12px;
    }
    .form-label {
      font-size: ${({ theme }) => theme["base-font-size"]};
      line-height: 12px;
    }
  }
  .modal-title {
    font-size: ${({ theme }) => theme["base-font-size"]};
    line-height: 20px;
  }
  .modal-footer {
    border-top: 0px;
    padding-top: 18px;
    padding-left: 23px;
    padding-right: 22px;
    padding-bottom: 18px;
    a {
      max-width: 93px;
      margin-right: 12px;
      padding: 8px 24px;
      font-weight: 700;
    }
    .btn {
      width: auto;
      margin: 0;
      padding: 8px 24px;
    }
  }
`;
export { ModalWrapper };
