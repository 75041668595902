import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Cookies from 'universal-cookie';

import VerificationImage from "../../assets/images/verification.svg"
import { VerificationWrapper } from "./style";
import SignUpLayout from "./layout";

import { SendEmailVerification, SetAuthState, ConfirmEmailVerification, LogOut } from "../../redux/slices/auth-slice";
import Notification from "../../component/notifications/notification";
import Spin from '../../component/Spin/index';

const ConfirmEmailVerifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams();
  const cookie = new Cookies();

  const {
    loading,
    user,
    error,
    success,
    message
  } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(ConfirmEmailVerification({ userId }))
  }, []);

  const handleLogout = () => {
    cookie.remove('qikFindsToken', { path: '/'});
    cookie.remove('keepaKey', { path: '/'});
    cookie.remove('isStoreExists', { path: '/'});

    dispatch(LogOut());
    history.push('/auth/sign-in')
  };
  
  useEffect(() => {
    if (success && message){
      Notification({
        type: "success",
        title: "Success",
        description: message,
      });
      dispatch(SetAuthState({ field: "success", value:false }));
      handleLogout();
    }
  },[success, message])
  return (
   <>
    {loading ? <Spin/>: <SignUpLayout>
      <VerificationWrapper>
       Verifying Email address
      </VerificationWrapper>
    </SignUpLayout>}
    {/* {loading && <Spin />} */}
   </>
  );
};

export default ConfirmEmailVerifications;
