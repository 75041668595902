import Styled from "styled-components";
const ContentWrapper = Styled.div`
padding:36px 36px 0;
`;

const DashboardWrapper = Styled.div`
h1{
font-weight: 900;
font-size: 24px;
line-height: 28px;
letter-spacing: 0.02em;
color: #272B41;
margin-bottom:0;
}
.export-btn-users {
    background: #0057d3;
    color: #ffff;
    border: 1px solid #0057d3;
    margin: 2px;
    &:hover{
        background: transparent;
        color:#0057d3;
    }
  }
  .filter-wrapper {
    border-radius: 4px;
    padding: 4px 8px;
    border-radius:25px;
    margin-left:auto;
    border:1px solid #0057d3;
    width:fit-content;
    display: flex;
    align-items:center;
    grid-gap: 4px;
    .bag-wrapper {
    color: #161924;
    padding: 2px 4px;
    margin:0px;
    display: flex;
    align-items:center;
    grid-gap: 4px;
    span{
        font-weight:500;
    }
}
svg {
font-size: 24px;
color: red;
}
}
.table-box{
    margin-top: 24px;
    h3{
        letter-spacing: 0.04em;
    }
}
.titlebar-filter{
    position:relative;
    gap:20px;
    .calendar-icon{
        color:${({ theme }) => theme["link-color"]};
        font-size:16px;
    }
}
.filter-search{
    input{
        min-width:252px;
        padding-right:35px;
    }
}
.filter-clear{
    color: #0057D3;
    &.disabled{
        color:#7C8092;
        cursor: not-allowed;
        svg{
            cursor: not-allowed;
        }
    }
}

.filter-clear{
    color: #0057D3;
    &.disabled{
        color:#7C8092;
        cursor: not-allowed;
        pointer-events: none;
        svg{
            cursor: not-allowed;
            pointer-events: none;
        }
    }
  }
.title-column{
    .title-content{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #303F45;
        display: block;
        margin-bottom:6px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 250px;
        white-space: nowrap;
    }
    .title-details{
        font-weight: 400;
        font-size: 9px;
        line-height: 12px;
        strong{
            color:${({ theme }) => theme["link-color"]};
            font-weight: 600;
        }
    }
}
.table-actions{
    gap:15px;
    font-size:18px;
    color:${({ theme }) => theme["link-color"]};
}
.currently-used{
    position:absolute ;
    background-color:${({ theme }) => theme["white-color"]};
    padding:${({ theme }) => theme["base-padding"]};
    right:0;
    border-radius: 8px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);
    min-width:856px;
    top:50px;
    padding-bottom: 27px;
    z-index:10;

}
.daterange-dropdown{
    padding:0;
    .daterange-footer{
      text-align:right;
      padding:16px 24px;
      border-top:1px solid #E6E8F0;
      .btn.btn-link{
        border:0px;
        text-decoration:none;
      }
      button{
          padding:7px 24px;
          margin-bottom:0;
          margin-right:16px;
          &:last-of-type{
            margin-right:0;
          }
        }
    }
}

`;
export { DashboardWrapper, ContentWrapper };
