import Cookies from 'universal-cookie';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Notification from '../../component/notifications/notification';
import PageHeader from "../../component/header";
import Loader from '../../component/loader/index'

import { SetAuthState } from '../../redux/slices/auth-slice';
import {
  GetSPAPIToken,
  amazonStoreInfo,
  SetStoreState
} from '../../redux/slices/store-slice';

import { StoreConnectivity } from "../authOnboarding-style";

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const cookie = new Cookies();

  const { impersonateUserData } = useSelector(state => state.user);
  const {
    spApiState,
    name,
    spApiRegion,
    error,
    loading,
    success,
    message,
    spiApiTokenMsg
  } = useSelector(state => state.store);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    console.log('params', {
      authCode: params.get('spapi_oauth_code'),
      sellerId: params.get('selling_partner_id'),
      state: params.get('state'),
      name,
      region: spApiRegion
    });

    if (params.get('selling_partner_id')) {
      if (spApiState === params.get('state')) {
        dispatch(GetSPAPIToken({
          impersonateUserId: impersonateUserData.userId,
          authCode: params.get('spapi_oauth_code'),
          sellerId: params.get('selling_partner_id'),
          region: spApiRegion,
          name
        }));
      } else {
        dispatch(SetStoreState({ field: 'error', value: 'Error in Store Connection' }));
      }
    } else {
      dispatch(SetStoreState({ field: 'error', value: 'Error in Store Connection' }));
    }

    return () => {
      dispatch(amazonStoreInfo({
        name: '',
        spApiState: '',
        spApiRegion: ''
      }));
    };
  }, []);

  useEffect(() => {
    if (success && spiApiTokenMsg) {
      dispatch(SetStoreState({ field: 'success', value: false }));
      dispatch(SetStoreState({ field: 'message', value: '' }));
      dispatch(SetStoreState({ field: 'spiApiTokenMsg', value: '' }));
      dispatch(SetAuthState({ field: 'storeStatus', value: 'Connected'}))

      const currentDate = new Date();
      const nextYear = new Date();

      nextYear.setFullYear(currentDate.getFullYear() + 1);
      cookie.set('isStoreExists', true, {
        path: '/',
        expires: nextYear
      });
      Notification({
        type: 'success',
        title: 'Success',
        description: 'New Store added successfully!'
      });
      history.push('/auth/payment');
      return;
    }

    if (error) {
      Notification({
        type: 'error',
        title: 'Error',
        description: error
      });
      dispatch(SetStoreState({ field: 'error', value: '' }));

      history.push('/select-region');
      return;
    }
  }, [error, message, success, spiApiTokenMsg]);

  return (
    <>
      <PageHeader />
      <StoreConnectivity>
        <Loader />
      </StoreConnectivity>
    </>
  );
};

export default Index;