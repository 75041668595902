import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AiOutlineCloseCircle,
  AiOutlineSync,
  AiOutlineClockCircle,
  AiOutlineDollarCircle,
  AiOutlineCar,
  AiOutlineWarning,
  AiOutlineCheckCircle
} from "react-icons/ai";
import { BsCalendar4Week } from 'react-icons/bs';
import moment from 'moment';
import { Drawer, Select, Tag, Tooltip } from 'antd';

import Button from '../../component/Button/Index';
import DateRangeFilter from '../../component/dateRange/dateRange';
import TablePagination from '../../component/pagination';
import Table from '../../component/table/Index';

import ViewIcon from "../../assets/images/view-icon.svg"

import { GetBrands, GetOrders } from '../../redux/slices/gift-card-slice';

import { OrdersWrapper } from './style';

const Orders = () => {
  const dispatch = useDispatch();

  const {
    brands,
    orders,
    totalOrders,
    loading
  } = useSelector(state => state.giftCard);

  const [isProductDetails, setIsProductDetails] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [brandsOption, setBrandOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [rowData, setRowData] = useState([]);
  const [drawerRowData, setDrawerRowData] = useState([]);
  const [range, setRange] = useState({
    startDate: {
      startDay: moment().format("D"),
      startMonth: moment().format("M"),
      startYear: moment().format("YYYY"),
    },
    endDate: {
      endDay: moment().format("D"),
      endMonth: moment().format("M"),
      endYear: moment().format("YYYY"),
    },
  });

  const { Option } = Select;

  const columnDefinitions = [
    {
      headerName: 'Date',
      field: 'date',
      minWidth: 200,
      flex: 1,
      filter: true,
      cellRenderer: (params) => moment(params.data.date).format('LLL')
    },
    {
      headerName: 'Status',
      cellRenderer: (params) => {
        const { status } = params.data;
      
        const tagConfig = {
          Pending: {
            icon: <AiOutlineClockCircle />,
            color: "warning",
            text: "Pending",
          },
          Processing: {
            icon: <AiOutlineSync spin />,
            color: "processing",
            text: "Processing...",
          },
          PaymentPending: {
            icon: <AiOutlineDollarCircle />,
            color: "warning",
            text: "Payment Pending",
          },
          Shipping: {
            icon: <AiOutlineCar />,
            color: "blue",
            text: "Shipping",
          },
          ShippingFailed: {
            icon: <AiOutlineWarning />,
            color: "error",
            text: "Shipping Failed",
          },
          Closed: {
            icon: <AiOutlineCheckCircle />,
            color: "success",
            text: "Closed",
          },
          Canceled: {
            icon: <AiOutlineCloseCircle />,
            color: "error",
            text: "Canceled",
          }
        };
      
        const config = tagConfig[status];
      
        return config ? (
          <Tag style={{ display: 'flex', alignItems: 'center', gap: 4 }} icon={config.icon} color={config.color}>
            {config.text}
          </Tag>
        ) : null;
      },
      minWidth: 200,
      flex: 1,
      filter: true
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      minWidth: 200,
      flex: 1,
      filter: true
    },
    {
      headerName: 'Price',
      field: 'value',
      minWidth: 200,
      flex: 1,
      filter: true
    },
    {
      headerName: 'Action',
      cellRenderer: (params) => {
        return (
          <Tooltip title="View Products Details">
            <img onClick={() => { setIsProductDetails(true);  setDrawerRowData(params.data.items); }} className='cursor-pointer' src={ViewIcon} />
          </Tooltip>
        )
      },
      sortable: true,
      minWidth: 200,
      flex: 1,
      filter: true
    },
  ];

  const drawerColumnDefinitions = [
    {
      headerName: 'Brand',
      field: 'brand',
      flex: 1,
      filter: true
    },
    {
      headerName: 'Value',
      field: 'value',
      flex: 1,
      filter: true
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      flex: 1,
      filter: true
    },
  ];

  const handleChangeBrand = (value) => {
    setSelectedBrand(value);
    setPageNumber(1);
  }

  const handleSelectChange = (value) => {
    setSelectedStatus(value);
  };

  const handleRangeChange = (dateRange) => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    const formattedStartDate = moment(startDate).format("L");
    const formattedEndDate = moment(endDate).format("L");

    const checkStart = moment(formattedStartDate);
    const startDay = checkStart.format("D");
    const startMonth = checkStart.format("M");
    const startYear = checkStart.format("YYYY");

    const checkEnd = moment(formattedEndDate);
    const endDay = checkEnd.format("D");
    const endMonth = checkEnd.format("M");
    const endYear = checkEnd.format("YYYY");
  
    setRange({
      ...range,
      startDate: {
        ...startDate,
        startDay,
        startMonth,
        startYear
      },
      endDate: {
        ...endDate,
        endDay,
        endMonth,
        endYear
      }
    })
  };

  const handleGetOrders = () => {
    setPageNumber(1);
  
    if (pageNumber === 1) {
      dispatch(GetOrders({
        ...range,
        brand: selectedBrand,
        status: selectedStatus,
        skip: (pageNumber - 1) * pageLimit,
        limit: pageLimit
      }));
    }
  }

  const handleOnPageChange = (newPageNumber, newPageLimit) => {
    setPageNumber(newPageNumber);
    setPageLimit(newPageLimit);
  };

  useEffect(() => {
    if (orders.length) {
      setRowData(orders);
    } else setRowData([]);
  }, [orders, loading]);

  useEffect(() => {
    setBrandOptions(
      brands.length 
        ? brands.map((i) => ({ value: i, label: i })) 
        : []
    );
  }, [brands]);

  useEffect(() => {
    dispatch(GetBrands())
  }, [])

  useEffect(() => {
    dispatch(GetOrders({
      ...range,
      brand: selectedBrand,
      status: selectedStatus,
      skip: (pageNumber - 1) * pageLimit,
      limit: pageLimit
    }));
  }, [pageNumber, pageLimit, selectedBrand, selectedStatus])

  return (
    <OrdersWrapper>
      <div className='header'>
        <h1>Orders</h1>
        <div className='action'>
          <Select
            style={{ width: 200 }}
            allowClear
            showSearch
            placeholder="Select a brand"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={brandsOption}
            onChange={(e) => handleChangeBrand(e)}
            onClear={() => setSelectedBrand('')}
          />
          <Select
            style={{ width: 200 }}
            placeholder="Select a Status"
            onChange={handleSelectChange}
            allowClear
            onClear={() => selectedStatus('')}
          >
            <Option value="Pending">Pending</Option>
            <Option value="Processing">Processing</Option>
            <Option value="PaymentPending">PaymentPending</Option>
            <Option value="Shipping">Shipping</Option>
            <Option value="ShippingFailed">ShippingFailed</Option>
            <Option value="Closed">Closed</Option>
            <Option value="Canceled">Canceled</Option>
          </Select>
          <BsCalendar4Week
            className="calendar-icon"
            onClick={() => setIsVisible(!isVisible)}
          />
          {isVisible &&
            <div className='date-wrapper'>
              <DateRangeFilter
                onChange={handleRangeChange}
                rangeColors={["#3d91ff"]}
                startDate={range.startDate}
                endDate={range.endDate}
              />
              <div className='buttons'>
                <Button onClick={() => setIsVisible(false)}>Cancel</Button>
                <Button onClick={() => { setIsVisible(false); handleGetOrders(); }}>Apply</Button>
              </div>
            </div>
          }
        </div>
      </div>
      <div>
        <Table
          height={'200px'}
          gridReady={(params) => console.log('Grid Ready:', params)}
          defaultColDef={{ resizable: true }}
          rowSelection="single"
          columnDefinitions={columnDefinitions}
          rowData={rowData}
          sidebarOpen={false}
          rowHeight={50}
          onSortChange={(event) => console.log('Sort Changed:', event)}
          headerHeight={60}
          onFilterChanged={(event) => console.log('Filter Changed:', event)}
          loading={loading}
          onSelectionChanged={(event) => console.log('Selection Changed:', event)}
          setSelected={(selectedRows) => console.log('Selected Rows:', selectedRows)}
          isRowSelectable={(node) => node.data.age > 20}
        />
        <TablePagination
          background="#F8F8F8"
          records={totalOrders}
          onChange={handleOnPageChange}
          pageNumber={pageNumber}
          pageLimit={pageLimit}
        />
      </div>
      <Drawer
        placement='right'
        title="Products Details"
        open={isProductDetails}
        width={700}
        onClose={() => setIsProductDetails(false)}
      >
        <Table
          height={'105px'}
          gridReady={(params) => console.log('Grid Ready:', params)}
          defaultColDef={{ resizable: true }}
          rowSelection="single"
          columnDefinitions={drawerColumnDefinitions}
          rowData={drawerRowData}
          sidebarOpen={false}
          rowHeight={50}
          onSortChange={(event) => console.log('Sort Changed:', event)}
          headerHeight={60}
          onFilterChanged={(event) => console.log('Filter Changed:', event)}
          loading={false}
          onSelectionChanged={(event) => console.log('Selection Changed:', event)}
          setSelected={(selectedRows) => console.log('Selected Rows:', selectedRows)}
          isRowSelectable={(node) => node.data.age > 20}
        />

      </Drawer>
    </OrdersWrapper>
  );
}

export default Orders;
