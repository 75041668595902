import Cookies from 'universal-cookie';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { MdLogout } from "react-icons/md";

import Button from '../../component/Button/Index';
import Logo from "../../assets/images/logo_qikfinds_extension.svg";
import UserAvatar from '../../assets/images/user-avatar.png';

import { HeaderWrapper } from "./style";

import { LogOut } from '../../redux/slices/auth-slice';
import { SetStoreState } from '../../redux/slices/store-slice';
import { SetUserState } from '../../redux/slices/user-slice';
import Stepper from '../../pages/Auth/stepper';

const Header = (props) => {
  const { state, children, className } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const cookie = new Cookies();

  const { user } = useSelector(state => state.auth);
  const { impersonateUserData } = useSelector(state => state.user);

  const handleLogout = () => {
    cookie.remove('qikFindsToken', { path: '/'});
    cookie.remove('keepaKey', { path: '/'});
    cookie.remove('isStoreExists', { path: '/'});

    dispatch(LogOut());
  };

  const handleEndImpersonation = () => {
    dispatch(SetUserState({ field: 'impersonateUserData', value: {
      userId: '',
      name: '',
      email: ''
    }}));

    dispatch(SetStoreState({ field: 'tab', value: 'users' }));
    history.push('/users');
  };

  return (
    <>
    <HeaderWrapper className={className}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="logo" onClick={() => window.open('https://qiklists.podia.com/qikfinds-extension')}>
        <a href="https://qiklists.podia.com/qikfinds-extension" target="_blank"><img src={Logo} alt="logo" /></a>
        </div>
        <div className="tab-list">{children}</div>
        <div className="right-section d-flex align-items-center">
          {impersonateUserData.userId ?
          <Button
            type="primary"
            className="mb-0 "
            large outlined
            onClick={() => handleEndImpersonation()}
          >
            {/* End Impersonation<br /><span>{impersonateUserData.name}<br />{impersonateUserData.email}</span> */}
            End Impersonation
          </Button>
          : null}
         {user?.name && <Dropdown>
            <Dropdown.Toggle
              className="position-relative d-flex justify-content-center"
              id="dropdown-basic"
            >
              <img width='32px' height='32px' src={UserAvatar} alt="no-user" />
              <span className="icon-chevron-down"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <div className="profile-menu-item d-flex">
                  <div>
                    <img width='36px' src={UserAvatar} alt="no-user" />
                  </div>
                  <div>
                    <h3>{user.name}</h3>
                    <p>{user.email}</p>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => handleLogout()}><MdLogout /> Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>}
        </div>
      </div>
    </HeaderWrapper>
      {/* <Stepper/> */}
    </>
  );
};
export default Header;
