import Cookies from 'universal-cookie';
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha"

import Button from "../../component/Button/Index";
import Input from "../../component/inputs/input/index";
import Notification from '../../component/notifications/notification';
import PasswordInput from "../../component/inputs/passwordInput/index";
import Spin from '../../component/Spin';

import { Row, Col } from "react-bootstrap";
import SignUp from "./layout";

import {
  SignIn,
  SetAuthState
} from '../../redux/slices/auth-slice';
import { LoginStyleWrapper } from './style';

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cookie = new Cookies();

  const {
    error,
    loading,
    success,
    message,
    token,
    storeStatus,
    storesCount,
    user
  } = useSelector(
    (state) => state.auth
  );

  const [userData, setUserData] = useState({
    email: '',
    password: ''
  });
  const captchaRef = useRef(null)

  const handleChange = (field, value) => {
    setUserData({
      ...userData,
      [field]: value
    });
  };

  const handleSignIn = () => {
    const recaptchaVal = captchaRef.current.getValue();
    if (!userData.email) {
      dispatch(SetAuthState({ field: "error", value: "Enter your email" }));
      return;
    } else if (!userData.password) {
      dispatch(SetAuthState({ field: "error", value: "Enter your password" }));
      return;
    } else if (!recaptchaVal) {
      dispatch(SetAuthState({ field: "error", value: 'Captcha must not be empty!' }));
      return;
    } else {
      dispatch(SignIn(userData));
    }
  }
  useEffect(() => {
    if (success && message) {
      const currentDate = new Date();
      const nextYear = new Date();

      nextYear.setFullYear(currentDate.getFullYear() + 1);
      if (token || user.role === 'admin' || (storeStatus === 'Connected' && user.status === 'Active')) {
        cookie.set('qikFindsToken', token, {
          path: '/',
          expires: nextYear
        });

        const { keepaKey } = user || {};
        if (keepaKey) {
          cookie.set('keepaKey', keepaKey, {
            path: '/',
            expires: nextYear
          });
        }

        if (storesCount > 0) {
          cookie.set('isStoreExists', true, {
            path: '/',
            expires: nextYear
          });
        }

        let { name } = user || {};
        if (name) {
          name = name.split(' ');
          let shortName = name[0][0];
          if (name.length > 1) {
            shortName = `${shortName}${name[1][0]}`;
          }

          cookie.set('name', shortName, {
            path: '/',
            expires: nextYear
          });
        }
        if (user?.role === 'special-sub-user') {
          cookie.set('isStoreExists', true, {
            path: '/',
            expires: nextYear
          });
        }
      }
      // else if (user.status === 'InActive') {
      //   Notification({
      //     type: "error",
      //     title: "Error",
      //     description: "Clear Your Pending Due's in Order to Login."
      //   });
      //   dispatch(LogOut({}));
      //   return;
      // }

      history.push('/dashboard');
      dispatch(SetAuthState({ field: "success", value: false }));
      dispatch(SetAuthState({ field: "message", value: "" }));
    }
  }, [success, message]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error
      });
      dispatch(SetAuthState({ field: "error", value: "" }));
    }
  }, [error]);

  return (
    <>
      <SignUp>
        <div className="auth-content-centered auth-wrapper-login">
          <h1 className="title">
            Account Login
          </h1>
          <div className="auth-section-middle-content auth-section-signup-content">
            <Row>
              <Col md={12}>
                <Input label="Username" placeholder="Username" onChange={(e) => handleChange('email', e.target.value)}
                />
              </Col>
              <Col md={12}>
                <LoginStyleWrapper>
                <PasswordInput
                  barColors={[
                    "#0FB600",
                    "#424A47",
                    "#424A47",
                    "#424A47",
                    "#424A47",
                  ]}
                  label="Password"
                  placeholder="Password Here"
                  onChange={(e) => handleChange('password', e.target.value)}
                />
                <label className="d-flex forgot-link justify-content-end">
                  <Link to="/auth/forget-password">Forgot Password!</Link>
                </label>
                </LoginStyleWrapper>
              </Col>
            </Row>
          </div>
          <Col md={12} className='mb-3'>
              <ReCAPTCHA
                  sitekey={process.env.RECAPTCHA_SITE_KEY}
                  ref={captchaRef}
                />
             </Col>
          <div className="auth-footer auth-signup-footer">
            <Row>
              <Col md={12}>
                <Button large="1" className="auth-btn" outlined width={"100%"} onClick={() => handleSignIn()}>
                  Login
                </Button>
              </Col>
              <Col md={12}>
                <div className="already-account align-items-center">
                  <label>
                 Don't have an account yet? <Link to="/auth/sign-up">Sign Up</Link>
                  </label>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </SignUp>
      {
        loading && ( <Spin /> )
      }
    </>
  );
};

export default Index;
