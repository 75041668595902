import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Route, useHistory } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';

import {
  GetUser,
  SetUserState
} from '../redux/slices/user-slice';

const PrivateRoute = ({
  component: Component,
  withLayout: Layout,
  ...rest
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    user,
    token,
    storeStatus,
    selectedPlan
  } = useSelector((state) => state.auth);

  const { user: userData, error } = useSelector(state => state.user);

  const [userStatus, setUserStatus] = useState('');

  const renderRoute = () => (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );

  const notCheckableRoutes = [
    '/auth/sign-in',
    '/auth/sign-in/',
    '/auth/sign-up',
    '/auth/sign-up/',
    '/auth/payment',
    '/auth/payment/',
    '/auth/subscription-plan',
    '/auth/subscription-plan/',
    '/auth/sp-api',
    '/auth/sp-api/'
  ];

  useEffect(() => {
    const route = location.pathname;
    if (!notCheckableRoutes.includes(route)) {
      dispatch(GetUser());
    }
  }, []);

  useEffect(() => {
    if(userData){
      setUserStatus(userData.status);
      dispatch(SetUserState({ field: 'userSuccess', value: false }));
    }
  }, [userData]);

  useEffect(() => {
    if (!token || token === '') {
      history.push('/auth/sign-in');
      return;
    } else if (token) {
      console.log('path data===>', storeStatus, location.pathname)
      if (user?.role !== 'admin' && user.role !== 'sub-user' && user.role !== 'special-sub-user') {
        if (user && user.status === 'Registered') {
          return history.push('/auth/verification');
        } else if (user && isEmpty(user.payment) && (storeStatus !== 'Connected' && location.pathname !== '/auth/sp-api/' && location.pathname !== '/select-region')) {
          return history.push('/auth/subscription-plan');
        } else if (!isEmpty(user?.payment) && selectedPlan?.name === 'Lite') {
          return history.push('/dashboard')          
        } else if (user && ( storeStatus !== 'Connected' && location.pathname !== '/auth/sp-api/')) {
          return history.push('/select-region');
        } else if (storeStatus === 'Connected' && location.pathname !== '/select-region' && isEmpty(user.payment)) {
          return history.push('/auth/payment');
        } else if (storeStatus === 'Connected'  && !isEmpty(user.payment) && !user?.subscriptionId) {
          return history.push('/auth/subscription-plan');
        } else {
          if ((!storeStatus || storeStatus !== 'Connected') && storeStatus !== 'In-Progress' && location.pathname !== '/auth/sp-api/') {
            return history.push('/select-region');
          } else {
            return history.push(location.pathname);
          }
        }
      } else {
        return history.push(location.pathname);
      }
    }
  }, [token, userStatus]);

  return token ? (
    Layout ? (<Layout>{renderRoute()}</Layout>) : (<>{renderRoute()}</>)
  ) : (
    <Backdrop open={true} style={{ zIndex: '9' }}>
      <CircularProgress color='inherit' />
    </Backdrop>
  )
};

export default PrivateRoute;
