import Styled from "styled-components";

const SidebarWrapper = Styled.div`
.MuiPaper-root.MuiPaper-elevation{
  border-right:none;
}

.end-impersonate {
  &:hover{
    background: transparent !important;
  }
  svg {
    color: blue !important;
}
span {
    color: blue !important;
}
}
.side-bar-list {
    height: 100vh;
    border-right:1px solid rgba(0, 0, 0, 0.12);
}
.log-out-class {
    position: absolute;
    bottom: 16px;
    width: 100%;
}
  .MuiListItemButton-root{
     font-family: ${({ theme }) => theme["font-family"]};
     margin-bottom:3px;
    &.Mui-selected{
           background-color:#161924;
        .MuiListItemIcon-root{
             color: ${({ theme }) => theme["white-color"]};
    }
    .MuiListItemText-root{
        color: ${({ theme }) => theme["white-color"]};
      }
         &:hover{
       background-color:#161924;
    }
    }
       &:hover {
            background-color:#161924;
           .MuiListItemIcon-root,.MuiListItemText-root,.MuiSvgIcon-root{
             color: ${({ theme }) => theme["white-color"]};
    }
    }
      .MuiListItemIcon-root,.MuiListItemText-root{
        color: ${({ theme }) => theme["secondary-color"]};
        span{
        font-size:1rem;
         font-family: ${({ theme }) => theme["font-family"]};
         font-weight:500
        }
      }
}
.side-bar-header-wrapper.css-fq3gi9 {
    height: 39px !important;
    min-height: 64px !important;
    padding: 0px 16px;
    background: #161924;
    img{
      max-width:85%;
    }
    .icon-button{
      width: 40px;
      height: 40px;
      svg{
        font-size:20px;
        color:#fff;
      }
    }
}
.user-bag {
    background: #fff;
    padding: 11px 8px;
    margin: 0px;
    width:38px;
    height:38px;
    text-align:center;
    border-radius: 30px;
    color: #161924 !important;
    font-weight: 600 !important;
    text-transform: uppercase;
}
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #161924;
    color: #fff;
    padding: 12.4px 24px;
    a.link {
    text-decoration: none;
    color: #000;
    font-size: 13px;
}
  }
  .header-items {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    .card-overlay-ui {
    display: flex;
    flex-direction: column;
    grid-row-gap:3px;
    p {
    font-size: 12px !important;
    margin: 0px !important;
}
  }

    .divider {
      width: 100%;
      height: 1px;
      background: #fff;
      opacity: 0.2;
    }
    p {
      color: var(--White, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
    }
  }
  .dropdown-wrapper{
    display:flex;
    grid-gap:8px ;
    align-items:center ;
  }
  .page-ui-wrapper{
    padding:15px 16px 0px;
    /* height: 100vh; */
    overflow: hidden;
    position: relative;
  }
  .css-11o2879-MuiDrawer-docked .MuiDrawer-paper {
    /* -webkit-transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms; */
    overflow-x: hidden;
    width: 73px;
}
`;
export default SidebarWrapper;
