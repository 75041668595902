import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Main = styled.main`
  display: flex;
`;

const CardContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 500px;
`;

const Card = styled.div`
  background: linear-gradient(to right, rgba(0, 128, 128, 0.6), rgba(0, 0, 255, 0.9));
  border-radius: 1rem;
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.6);
  width: 24rem;
  height: 15rem;
  padding: 1rem 2rem;
  color: white;
`;

const ChipImage = styled.img`
  width: 3rem;
  height: 3rem;
`;

const CreditCard = ({payment}) => {
  const { user, users, impersonateUserData, invoices } = useSelector(state => (state.user));
  if (!payment && impersonateUserData?.email) {
    payment = users?.find(user => user.email === impersonateUserData.email)?.payment
  }
  
  const {
    cardExpMonth,
    cardExpYear,
    cardLast4Digits,
    name
  } = payment || {}
  return (
      <Main>
        <CardContainer>
          <Card>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ fontWeight: 'bold' }}>Credit Card</p>
              <div style={{ position: 'relative', display: 'flex' }}>
                <div style={{ backgroundColor: 'red', width: '2rem', height: '2rem', borderRadius: '50%', position: 'absolute', right: '1rem', top: '0.5rem' }}></div>
                <div style={{ backgroundColor: 'orange', width: '2rem', height: '2rem', borderRadius: '50%', position: 'absolute', right: '0', top: '0.5rem' }}></div>
              </div>
            </div>
            <ChipImage alt="Icons8 flat sim card chip" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Icons8_flat_sim_card_chip.svg/512px-Icons8_flat_sim_card_chip.svg.png" />
            {cardLast4Digits ?<div style={{ fontWeight: 'bold', fontSize: '1.5rem', display: 'flex', justifyContent: 'space-between', textShadow: '2px 2px 4px rgba(0,0,0,0.3)' }}>
             <p>XXXX</p>
              <p>XXXX</p>
              <p>XXXX</p>
              <p>{cardLast4Digits}</p>
            </div> : 'Card not available'}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
             { <div>
                <label style={{ fontSize: '0.75rem', textTransform: 'uppercase' }}>Card Holder</label>
                {name ? <p style={{ fontSize: '1rem' }}>{name}</p> : ': n/a'}
              </div>}
              {<div>
                <label style={{ fontSize: '0.75rem', textTransform: 'uppercase' }}>Valid Till</label>
                {(cardExpMonth && cardExpYear) ? <p style={{ fontSize: '1rem' }}>{cardExpMonth+"/"+cardExpYear}</p> : ': n/a'}
              </div>}
            </div>
          </Card>
        </CardContainer>
      </Main>
  );
};

export default CreditCard;
