import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory} from "react-router-dom";
import { debounce } from "lodash";

import { ReferralsWrapper } from "./style";
import SearchInput from "../../component/searchInput/Index";
import Table from "../../component/table/Index";
import TablePagination from "../../component/pagination/index";
import Notification from '../../component/notifications/notification';
import Spin from '../../component/Spin';

import { isContainSpecialCharacter } from "../../utils/helpers";
import {
  SetUserState,
  GetReferralUsers,
} from '../../redux/slices/user-slice';

const Referrals = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loading,
    totalReferredUsers,
    balance,
    referrals,
    impersonateUserData,
    users,
    user,
    error,
    message
  } = useSelector(state => state.user);

  const [tableRows, setTableRows] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');

  const defaultColDefs = {
    resizable: true,
    sortable: true,
    flex: 1,
  };

  const pageOptions = [10, 25, 50, 100];
  const columnDefinitions = [
    {
      headerName: "Name",
      minWidth: 315,
      flex: 1,
      field: "name",
      cellRenderer: ({ data }) => {
        const { name } = data || {};

        return name || 'N/A';
      },
    },
    {
      headerName: "Email",
      minWidth: 313,
      flex: 1,
      field: "email",
      cellRenderer: ({ data }) => {
        const { email } = data || {};

        return email || 'N/A';
      },
    },
    {
      headerName: "Status",
      minWidth: 313,
      flex: 1,
      field: "status",
      cellRenderer: ({ data }) => {
        const {
          status,
          userActiveTill,
          lastInvoicePaidAt,
          subscriptionType
        } = data || {};
        return ((lastInvoicePaidAt || userActiveTill) && subscriptionType !== 'Lite') ? <span style={{color: 'green'}}>Subscribed</span> : "Signed Up" || 'N/A';
      },
    },
    {
      headerName: "Joining Date",
      minWidth: 190,
      flex: 1,
      field: "createdAt",
      cellRenderer: ({ data }) => {
        const { createdAt } = data || {};

        return createdAt ? moment(createdAt).format('lll') : 'N/A';
      },
    }
  ];

  const handleSearch = debounce((e) => {
    setSearchKeyword(e.target.value);
    setPageNumber(1);
  }, 100);

  const handleOnPageChange = (current, pageSize) => {
    setPageNumber(current);
    setPageLimit(pageSize);
  };

  useEffect(() => {
    dispatch(GetReferralUsers({
      userId: impersonateUserData?.userId ? impersonateUserData.userId : user?._id,
      searchKeyword,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
    }));
  }, []);

  useEffect(() => {
    if (!isContainSpecialCharacter({ searchKeyword })) {
      dispatch(GetReferralUsers({
        userId: impersonateUserData?.userId ? impersonateUserData.userId : user?._id,
        searchKeyword,
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit,
      }));
    } else {
      dispatch(SetUserState({ field: 'totalReferredUsers', value: 0 }));
      dispatch(SetUserState({ field: 'referrals', value: [] }));
    }
  }, [searchKeyword, pageLimit, pageNumber]);

  useEffect(() => {
    if (referrals && referrals.length) {
      const data = referrals.map(
        (
          {
            _id,
            name,
            email,
            createdAt,
            status,
            lastInvoicePaidAt,
            userActiveTill,
            subscriptionType
          },
          index
        ) => {
          return {
            _id,
            name,
            email,
            createdAt,
            status,
            lastInvoicePaidAt,
            userActiveTill,
            subscriptionType
          };
        }
      );

      setTableRows(data);
    } else {
      setTableRows([])
    }
  }, [referrals]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error,
      });
      dispatch(SetUserState({ field: "error", value: "" }));
      return;
    }

    if (message) {
      Notification({
        type: "success",
        title: "Success",
        description: message,
      });
      dispatch(SetUserState({ field: "message", value: "" }));
      dispatch(SetUserState({ field: "success", value: false }));

      if (totalReferredUsers && totalReferredUsers > 0) {
        const remainingReferralsOnCurrentPage = referrals.length - 1;
        if (remainingReferralsOnCurrentPage > 0) {
          dispatch(GetReferralUsers({
            userId: impersonateUserData?.userId ? impersonateUserData.userId : user?._id,
            limit: pageLimit,
            skip: (pageNumber - 1) * pageLimit,
            searchKeyword
          }));
        } else {
          const nextPage = pageNumber - 1;
          setPageNumber(nextPage === 0 ? 1 : nextPage);
          history.push('/referrals');
          dispatch(GetReferralUsers({
            userId: impersonateUserData?.userId ? impersonateUserData.userId : user?._id,
            limit: pageLimit,
            skip: (1 - 1) * pageLimit,
            searchKeyword
          }));
        }
      }
      if (totalUsers === 0) {
        dispatch(GetReferralUsers({
          userId: impersonateUserData?.userId ? impersonateUserData.userId : user?._id,
          limit: pageLimit,
          skip: (1 - 1) * pageLimit,
          searchKeyword
        }));
      }
      return;
    }
  }, [error, message]);

  const gridOptions = {
    rowClass: 'ag-row',
  };
  return (
    <ReferralsWrapper>
      <div className="d-flex justify-content-between align-items-center mb-4">
       <div className="balance-card-ui-wrapper">
       <h1 className="page-title">Referrals</h1>
       <div className="balance-card">
         <p>Balance:</p>{balance ? <h6>{`$${balance}`}</h6> : <h6 className="zero-amount">0</h6>}
       </div>
       </div>
        <div className="d-flex user-page-header">
        <SearchInput placeholder="Search Referrals" onChange={(e) => handleSearch(e)}/>
        </div>
      </div>
      <Table
        height="205px"
        headerHeight={50}
        columnDefinitions={columnDefinitions}
        defaultColDef={defaultColDefs}
        rowData={tableRows}
        rowHeight={67}
        gridOptions={gridOptions}
        loading = {loading}
      />
      <TablePagination
        background="#F8F8F8"
        records={totalReferredUsers}
        options={pageOptions}
        onChange={handleOnPageChange}
        pageNumber={pageNumber}
        pageLimit={pageLimit}
      />
       {loading && <Spin />}
    </ReferralsWrapper>
  );
};

export default Referrals;
