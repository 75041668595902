import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";

import { axiosBaseUrl } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const CreateCustomer = createAsyncThunk(
  'stripe/create-customer',
  async (data, { rejectWithValue }) => {
    try {
      const {
        token,
        name,
        address
      } = data;
      const response = await axios.post('/stripe/create-customer', {
        token,
        name,
        address
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const UpdateCustomer = createAsyncThunk(
  'stripe/update-customer',
  async (data, { rejectWithValue }) => {
    try {
      const { token, name, address, stripeUserId } = data;
      const response = await axios.post('/stripe/update-customer', {
        token,
        stripeUserId,
        name,
        address
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err?.response?.status === 401 ? "" : err.response.data?.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const stripe = createSlice({
  name: 'stripe',
  initialState: {
    error: '',
    loading: '',
    message: '',
    success: false,
    customerCreated: false,
    customerUpdated: false
  },
  reducers: {
    SetStripeState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [CreateCustomer.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [CreateCustomer.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message,
      customerCreated: true
    }),
    [CreateCustomer.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [UpdateCustomer.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [UpdateCustomer.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message,
      customerUpdated: true
    }),
    [UpdateCustomer.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    })
  }
});

const { reducer, actions } = stripe;

export const { SetStripeState } = actions;

export default reducer;
