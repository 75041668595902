import Usa from '../assets/images/regions-flags/north-america/usa.svg';
import Canada from '../assets/images/regions-flags/north-america/canada.svg';
import Mexico from '../assets/images/regions-flags/north-america/mexico.svg';
import Brazil from '../assets/images/regions-flags/north-america/brazil.svg';
import UnitedKingdom from '../assets/images/regions-flags/europe/united-kingdom.svg';
import Germany from '../assets/images/regions-flags/europe/germany.svg';
import France from '../assets/images/regions-flags/europe/france.svg';
import Italy from '../assets/images/regions-flags/europe/italy.svg';
import Spain from '../assets/images/regions-flags/europe/spain.svg';
import Netherlands from '../assets/images/regions-flags/europe/netherlands.svg';
import Sweden from '../assets/images/regions-flags/europe/sweden.svg';
import Poland from '../assets/images/regions-flags/europe/poland.svg';
import Turkey from '../assets/images/regions-flags/europe/turkey.svg';
import India from '../assets/images/regions-flags/europe/india.svg';
import Australia from '../assets/images/regions-flags/far-east/australia.svg';
import Japan from '../assets/images/regions-flags/far-east/japan.svg';
import Singapore from '../assets/images/regions-flags/far-east/singapore.svg';

export const APP_ID = 'amzn1.sp.solution.76a26c46-c86f-40c9-bba7-497e29e2c580';

export const MARKETPLACE_TO_REGEION_MAPPING = {
  tr: 'eu',
  sa: 'eu',
  ae: 'eu',
  in: 'eu',
  eg: 'eu',
  jp: 'fe',
  au: 'fe',
  sg: 'fe',
  na: 'na',
  eu: 'eu'
};

export const CURRENCY_SYMBOLS_WRT_MARKETPLACE = {
  ATVPDKIKX0DER: '$',
  A2EUQ1WTGCTBG2: 'C$',
  A1AM78C64UM0Y8: 'MX$',
  A2Q3Y263D00KWC: 'R$',
  A1F83G8C2ARO7P: '£',
  A1PA6795EUMFR9: '€',
  A13V1IB3VIYZZH: '€',
  APJ6JRA9NG5V4: '€',
  A1RKKUPIHCS9HS: '€',
  A1PA6795UKMFR9: '€',
  A1805IZSGTT6HS: 'SEK',
  A2NODRKZP88ZB9: 'zł',
  A1C3SOZRARQ6R3: '₺',
  A33AVAJ2PDY3EV: 'د.إ',
  A2VIGQ35RCS4UG: '₹',
  A21TJRUUN4KGV: 'S$',
  A19VAU5U5O7RUS: 'A$',
  A39IBJ37TRP1C6: '¥',
  A1VC38T7YXB528: 'Kč'
};

export const REGIONS = {
  na: [
    // Usa, Canada, Brazil, Mexico
    {
      marketplaceId: 'ATVPDKIKX0DER',
      name: 'USA',
      image: Usa,
      domain: 'Amazon.com'
    },
    {
      marketplaceId: 'A2EUQ1WTGCTBG2',
      name: 'Canada',
      image: Canada,
      domain: 'Amazon.ca'
    },
    {
      marketplaceId: 'A2Q3Y263D00KWC',
      name: 'Brazil',
      image: Brazil,
      domain: 'Amazon.com.br'
    },
    {
      marketplaceId: 'A1AM78C64UM0Y8',
      name: 'Mexico',
      image: Mexico,
      domain: 'Amazon.com.mx'
    }
  ],
  eu: [
    // UK, Germany, France, Italy, Spain, Netherlands, Sweden, Poland, Turkey, India
    {
      marketplaceId: 'A1F83G8C2ARO7P',
      name: 'United Kindom',
      image: UnitedKingdom,
      domain: 'Amazon.co.uk'
    },
    {
      marketplaceId: 'A1PA6795UKMFR9',
      name: 'Germany',
      image: Germany,
      domain: 'Amazon.de'
    },
    {
      marketplaceId: 'A13V1IB3VIYZZH',
      name: 'France',
      image: France,
      domain: 'Amazon.fr'
    },
    {
      marketplaceId: 'APJ6JRA9NG5V4',
      name: 'Italy',
      image: Italy,
      domain: 'Amazon.it'
    },
    {
      marketplaceId: 'A1RKKUPIHCS9HS',
      name: 'Spain',
      image: Spain,
      domain: 'Amazon.es'
    },
    {
      marketplaceId: 'A1805IZSGTT6HS',
      name: 'Netherlands',
      image: Netherlands,
      domain: 'Amazon.nl'
    },
    {
      marketplaceId: 'A2NODRKZP88ZB9',
      name: 'Sweden',
      image: Sweden,
      domain: 'Amazon.se'
    },
    {
      marketplaceId: 'A1C3SOZRARQ6R3',
      name: 'Poland',
      image: Poland,
      domain: 'Amazon.pl'
    },
    {
      marketplaceId: 'A33AVAJ2PDY3EV',
      name: 'Turkey',
      image: Turkey,
      domain: 'Amazon.tr'
    },
    {
      marketplaceId: 'A21TJRUUN4KGV',
      name: 'India',
      image: India,
      domain: 'Amazon.in'
    }
  ],
  fe: [
    {
      country: 'singapore',
      marketplaceId: 'A19VAU5U5O7RUS',
      countryCode: 'SG',
      image: Singapore,
      domain: 'Amazon.sg'
    },
    {
      country: 'australia',
      marketplaceId: 'A39IBJ37TRP1C6',
      countryCode: 'AU',
      image: Australia,
      domain: 'Amazon.com.au'
    },
    {
      country: 'japan',
      marketplaceId: 'A1VC38T7YXB528',
      countryCode: 'JP',
      image: Japan,
      domain: 'Amazon.co.jp'
    }
  ],
  all: [
    {
      marketplaceId: 'ATVPDKIKX0DER',
      name: 'USA',
      image: Usa,
      domain: 'Amazon.com'
    },
    {
      marketplaceId: 'A2EUQ1WTGCTBG2',
      name: 'Canada',
      image: Canada,
      domain: 'Amazon.ca'
    },
    {
      marketplaceId: 'A2Q3Y263D00KWC',
      name: 'Brazil',
      image: Brazil,
      domain: 'Amazon.com.br'
    },
    {
      marketplaceId: 'A1AM78C64UM0Y8',
      name: 'Mexico',
      image: Mexico,
      domain: 'Amazon.com.mx'
    },
    {
      marketplaceId: 'A1F83G8C2ARO7P',
      name: 'United Kindom',
      image: UnitedKingdom,
      domain: 'Amazon.co.uk'
    },
    {
      marketplaceId: 'A1PA6795UKMFR9',
      name: 'Germany',
      image: Germany,
      domain: 'Amazon.de'
    },
    {
      marketplaceId: 'A13V1IB3VIYZZH',
      name: 'France',
      image: France,
      domain: 'Amazon.fr'
    },
    {
      marketplaceId: 'APJ6JRA9NG5V4',
      name: 'Italy',
      image: Italy,
      domain: 'Amazon.it'
    },
    {
      marketplaceId: 'A1RKKUPIHCS9HS',
      name: 'Spain',
      image: Spain,
      domain: 'Amazon.es'
    },
    {
      marketplaceId: 'A1805IZSGTT6HS',
      name: 'Netherlands',
      image: Netherlands,
      domain: 'Amazon.nl'
    },
    {
      marketplaceId: 'A2NODRKZP88ZB9',
      name: 'Sweden',
      image: Sweden,
      domain: 'Amazon.se'
    },
    {
      marketplaceId: 'A1C3SOZRARQ6R3',
      name: 'Poland',
      image: Poland,
      domain: 'Amazon.pl'
    },
    {
      marketplaceId: 'A33AVAJ2PDY3EV',
      name: 'Turkey',
      image: Turkey,
      domain: 'Amazon.tr'
    },
    {
      marketplaceId: 'A21TJRUUN4KGV',
      name: 'India',
      image: India,
      domain: 'Amazon.in'
    }
  ]
};

export const COUNTRIES = [ 
  {name: 'Afghanistan', code: 'AF'}, 
  {name: 'Åland Islands', code: 'AX'}, 
  {name: 'Albania', code: 'AL'}, 
  {name: 'Algeria', code: 'DZ'}, 
  {name: 'American Samoa', code: 'AS'}, 
  {name: 'AndorrA', code: 'AD'}, 
  {name: 'Angola', code: 'AO'}, 
  {name: 'Anguilla', code: 'AI'}, 
  {name: 'Antarctica', code: 'AQ'}, 
  {name: 'Antigua and Barbuda', code: 'AG'}, 
  {name: 'Argentina', code: 'AR'}, 
  {name: 'Armenia', code: 'AM'}, 
  {name: 'Aruba', code: 'AW'}, 
  {name: 'Australia', code: 'AU'}, 
  {name: 'Austria', code: 'AT'}, 
  {name: 'Azerbaijan', code: 'AZ'}, 
  {name: 'Bahamas', code: 'BS'}, 
  {name: 'Bahrain', code: 'BH'}, 
  {name: 'Bangladesh', code: 'BD'}, 
  {name: 'Barbados', code: 'BB'}, 
  {name: 'Belarus', code: 'BY'}, 
  {name: 'Belgium', code: 'BE'}, 
  {name: 'Belize', code: 'BZ'}, 
  {name: 'Benin', code: 'BJ'}, 
  {name: 'Bermuda', code: 'BM'}, 
  {name: 'Bhutan', code: 'BT'}, 
  {name: 'Bolivia', code: 'BO'}, 
  {name: 'Bosnia and Herzegovina', code: 'BA'}, 
  {name: 'Botswana', code: 'BW'}, 
  {name: 'Bouvet Island', code: 'BV'}, 
  {name: 'Brazil', code: 'BR'}, 
  {name: 'British Indian Ocean Territory', code: 'IO'}, 
  {name: 'Brunei Darussalam', code: 'BN'}, 
  {name: 'Bulgaria', code: 'BG'}, 
  {name: 'Burkina Faso', code: 'BF'}, 
  {name: 'Burundi', code: 'BI'}, 
  {name: 'Cambodia', code: 'KH'}, 
  {name: 'Cameroon', code: 'CM'}, 
  {name: 'Canada', code: 'CA'}, 
  {name: 'Cape Verde', code: 'CV'}, 
  {name: 'Cayman Islands', code: 'KY'}, 
  {name: 'Central African Republic', code: 'CF'}, 
  {name: 'Chad', code: 'TD'}, 
  {name: 'Chile', code: 'CL'}, 
  {name: 'China', code: 'CN'}, 
  {name: 'Christmas Island', code: 'CX'}, 
  {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
  {name: 'Colombia', code: 'CO'}, 
  {name: 'Comoros', code: 'KM'}, 
  {name: 'Congo', code: 'CG'}, 
  {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
  {name: 'Cook Islands', code: 'CK'}, 
  {name: 'Costa Rica', code: 'CR'}, 
  {name: 'Cote D\'Ivoire', code: 'CI'}, 
  {name: 'Croatia', code: 'HR'}, 
  {name: 'Cuba', code: 'CU'}, 
  {name: 'Cyprus', code: 'CY'}, 
  {name: 'Czech Republic', code: 'CZ'}, 
  {name: 'Denmark', code: 'DK'}, 
  {name: 'Djibouti', code: 'DJ'}, 
  {name: 'Dominica', code: 'DM'}, 
  {name: 'Dominican Republic', code: 'DO'}, 
  {name: 'Ecuador', code: 'EC'}, 
  {name: 'Egypt', code: 'EG'}, 
  {name: 'El Salvador', code: 'SV'}, 
  {name: 'Equatorial Guinea', code: 'GQ'}, 
  {name: 'Eritrea', code: 'ER'}, 
  {name: 'Estonia', code: 'EE'}, 
  {name: 'Ethiopia', code: 'ET'}, 
  {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
  {name: 'Faroe Islands', code: 'FO'}, 
  {name: 'Fiji', code: 'FJ'}, 
  {name: 'Finland', code: 'FI'}, 
  {name: 'France', code: 'FR'}, 
  {name: 'French Guiana', code: 'GF'}, 
  {name: 'French Polynesia', code: 'PF'}, 
  {name: 'French Southern Territories', code: 'TF'}, 
  {name: 'Gabon', code: 'GA'}, 
  {name: 'Gambia', code: 'GM'}, 
  {name: 'Georgia', code: 'GE'}, 
  {name: 'Germany', code: 'DE'}, 
  {name: 'Ghana', code: 'GH'}, 
  {name: 'Gibraltar', code: 'GI'}, 
  {name: 'Greece', code: 'GR'}, 
  {name: 'Greenland', code: 'GL'}, 
  {name: 'Grenada', code: 'GD'}, 
  {name: 'Guadeloupe', code: 'GP'}, 
  {name: 'Guam', code: 'GU'}, 
  {name: 'Guatemala', code: 'GT'}, 
  {name: 'Guernsey', code: 'GG'}, 
  {name: 'Guinea', code: 'GN'}, 
  {name: 'Guinea-Bissau', code: 'GW'}, 
  {name: 'Guyana', code: 'GY'}, 
  {name: 'Haiti', code: 'HT'}, 
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
  {name: 'Holy See (Vatican City State)', code: 'VA'}, 
  {name: 'Honduras', code: 'HN'}, 
  {name: 'Hong Kong', code: 'HK'}, 
  {name: 'Hungary', code: 'HU'}, 
  {name: 'Iceland', code: 'IS'}, 
  {name: 'India', code: 'IN'}, 
  {name: 'Indonesia', code: 'ID'}, 
  {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
  {name: 'Iraq', code: 'IQ'}, 
  {name: 'Ireland', code: 'IE'}, 
  {name: 'Isle of Man', code: 'IM'}, 
  {name: 'Israel', code: 'IL'}, 
  {name: 'Italy', code: 'IT'}, 
  {name: 'Jamaica', code: 'JM'}, 
  {name: 'Japan', code: 'JP'}, 
  {name: 'Jersey', code: 'JE'}, 
  {name: 'Jordan', code: 'JO'}, 
  {name: 'Kazakhstan', code: 'KZ'}, 
  {name: 'Kenya', code: 'KE'}, 
  {name: 'Kiribati', code: 'KI'}, 
  {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
  {name: 'Korea, Republic of', code: 'KR'}, 
  {name: 'Kuwait', code: 'KW'}, 
  {name: 'Kyrgyzstan', code: 'KG'}, 
  {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
  {name: 'Latvia', code: 'LV'}, 
  {name: 'Lebanon', code: 'LB'}, 
  {name: 'Lesotho', code: 'LS'}, 
  {name: 'Liberia', code: 'LR'}, 
  {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
  {name: 'Liechtenstein', code: 'LI'}, 
  {name: 'Lithuania', code: 'LT'}, 
  {name: 'Luxembourg', code: 'LU'}, 
  {name: 'Macao', code: 'MO'}, 
  {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
  {name: 'Madagascar', code: 'MG'}, 
  {name: 'Malawi', code: 'MW'}, 
  {name: 'Malaysia', code: 'MY'}, 
  {name: 'Maldives', code: 'MV'}, 
  {name: 'Mali', code: 'ML'}, 
  {name: 'Malta', code: 'MT'}, 
  {name: 'Marshall Islands', code: 'MH'}, 
  {name: 'Martinique', code: 'MQ'}, 
  {name: 'Mauritania', code: 'MR'}, 
  {name: 'Mauritius', code: 'MU'}, 
  {name: 'Mayotte', code: 'YT'}, 
  {name: 'Mexico', code: 'MX'}, 
  {name: 'Micronesia, Federated States of', code: 'FM'}, 
  {name: 'Moldova, Republic of', code: 'MD'}, 
  {name: 'Monaco', code: 'MC'}, 
  {name: 'Mongolia', code: 'MN'}, 
  {name: 'Montserrat', code: 'MS'}, 
  {name: 'Morocco', code: 'MA'}, 
  {name: 'Mozambique', code: 'MZ'}, 
  {name: 'Myanmar', code: 'MM'}, 
  {name: 'Namibia', code: 'NA'}, 
  {name: 'Nauru', code: 'NR'}, 
  {name: 'Nepal', code: 'NP'}, 
  {name: 'Netherlands', code: 'NL'}, 
  {name: 'Netherlands Antilles', code: 'AN'}, 
  {name: 'New Caledonia', code: 'NC'}, 
  {name: 'New Zealand', code: 'NZ'}, 
  {name: 'Nicaragua', code: 'NI'}, 
  {name: 'Niger', code: 'NE'}, 
  {name: 'Nigeria', code: 'NG'}, 
  {name: 'Niue', code: 'NU'}, 
  {name: 'Norfolk Island', code: 'NF'}, 
  {name: 'Northern Mariana Islands', code: 'MP'}, 
  {name: 'Norway', code: 'NO'}, 
  {name: 'Oman', code: 'OM'}, 
  {name: 'Pakistan', code: 'PK'}, 
  {name: 'Palau', code: 'PW'}, 
  {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
  {name: 'Panama', code: 'PA'}, 
  {name: 'Papua New Guinea', code: 'PG'}, 
  {name: 'Paraguay', code: 'PY'}, 
  {name: 'Peru', code: 'PE'}, 
  {name: 'Philippines', code: 'PH'}, 
  {name: 'Pitcairn', code: 'PN'}, 
  {name: 'Poland', code: 'PL'}, 
  {name: 'Portugal', code: 'PT'}, 
  {name: 'Puerto Rico', code: 'PR'}, 
  {name: 'Qatar', code: 'QA'}, 
  {name: 'Reunion', code: 'RE'}, 
  {name: 'Romania', code: 'RO'}, 
  {name: 'Russian Federation', code: 'RU'}, 
  {name: 'RWANDA', code: 'RW'}, 
  {name: 'Saint Helena', code: 'SH'}, 
  {name: 'Saint Kitts and Nevis', code: 'KN'}, 
  {name: 'Saint Lucia', code: 'LC'}, 
  {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
  {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
  {name: 'Samoa', code: 'WS'}, 
  {name: 'San Marino', code: 'SM'}, 
  {name: 'Sao Tome and Principe', code: 'ST'}, 
  {name: 'Saudi Arabia', code: 'SA'}, 
  {name: 'Senegal', code: 'SN'}, 
  {name: 'Serbia and Montenegro', code: 'CS'}, 
  {name: 'Seychelles', code: 'SC'}, 
  {name: 'Sierra Leone', code: 'SL'}, 
  {name: 'Singapore', code: 'SG'}, 
  {name: 'Slovakia', code: 'SK'}, 
  {name: 'Slovenia', code: 'SI'}, 
  {name: 'Solomon Islands', code: 'SB'}, 
  {name: 'Somalia', code: 'SO'}, 
  {name: 'South Africa', code: 'ZA'}, 
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
  {name: 'Spain', code: 'ES'}, 
  {name: 'Sri Lanka', code: 'LK'}, 
  {name: 'Sudan', code: 'SD'}, 
  {name: 'Suriname', code: 'SR'}, 
  {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
  {name: 'Swaziland', code: 'SZ'}, 
  {name: 'Sweden', code: 'SE'}, 
  {name: 'Switzerland', code: 'CH'}, 
  {name: 'Syrian Arab Republic', code: 'SY'}, 
  {name: 'Taiwan, Province of China', code: 'TW'}, 
  {name: 'Tajikistan', code: 'TJ'}, 
  {name: 'Tanzania, United Republic of', code: 'TZ'}, 
  {name: 'Thailand', code: 'TH'}, 
  {name: 'Timor-Leste', code: 'TL'}, 
  {name: 'Togo', code: 'TG'}, 
  {name: 'Tokelau', code: 'TK'}, 
  {name: 'Tonga', code: 'TO'}, 
  {name: 'Trinidad and Tobago', code: 'TT'}, 
  {name: 'Tunisia', code: 'TN'}, 
  {name: 'Turkey', code: 'TR'}, 
  {name: 'Turkmenistan', code: 'TM'}, 
  {name: 'Turks and Caicos Islands', code: 'TC'}, 
  {name: 'Tuvalu', code: 'TV'}, 
  {name: 'Uganda', code: 'UG'}, 
  {name: 'Ukraine', code: 'UA'}, 
  {name: 'United Arab Emirates', code: 'AE'}, 
  {name: 'United Kingdom', code: 'GB'}, 
  {name: 'United States', code: 'US'}, 
  {name: 'United States Minor Outlying Islands', code: 'UM'}, 
  {name: 'Uruguay', code: 'UY'}, 
  {name: 'Uzbekistan', code: 'UZ'}, 
  {name: 'Vanuatu', code: 'VU'}, 
  {name: 'Venezuela', code: 'VE'}, 
  {name: 'Viet Nam', code: 'VN'}, 
  {name: 'Virgin Islands, British', code: 'VG'}, 
  {name: 'Virgin Islands, U.S.', code: 'VI'}, 
  {name: 'Wallis and Futuna', code: 'WF'}, 
  {name: 'Western Sahara', code: 'EH'}, 
  {name: 'Yemen', code: 'YE'}, 
  {name: 'Zambia', code: 'ZM'}, 
  {name: 'Zimbabwe', code: 'ZW'} 
];

export const CURRENCY_MAPPING = {
  USD: '$',
  GBP: '€'
};


export const FIFTY_UPC_STORES = [
  "Academy",
  "AceHardware",
  "AcmeTools",
  "Als",
  "Barnesandnoble",
  "Belk",
  "BestBuy",
  "Bjs",
  "BloomingDales",
  "Bluemercury",
  "Boscovs",
  "ChampsSports",
  "Chewy",
  "Columbia",
  "Corelle",
  "Cvs",
  "Dillards",
  "Dsw",
  "ECosmetics",
  "EntirelyPetsPharmacy",
  "EverythingKitchens",
  "Farmandfleet",
  "FleetFeet",
  "GameStop",
  "Homedepot",
  "Iherb",
  "Kohls",
  "LookFantastic",
  "Lowes",
  "Macys",
  "Menards",
  "Michaels",
  "MyOtcStore",
  "Nike",
  "OfficeSupply",
  "Petco",
  "Puritan",
  "RiteAid",
  "SaksOff5th",
  "SamsClub",
  "SkinStore",
  "Staples",
  "SunAndSki",
  "SwansonVitamins",
  "Target",
  "ToolNut",
  "VitaCost",
  "Walgreens",
  "Walmart",
  "Zoro"
]

export const  HUNDERED_UPC_STORES = [
  ...FIFTY_UPC_STORES,
  "Rei",
  "Scheels",
  "ShopWss",
  "Tartecosmetics",
  "Webstaurantstore",
  "Zappos",
  "Woot",
  "Elfcosmetics",
  "Shoppremiumoutlets",
  "DermStore",
  "BenefitCosmetics",
  "Naturalizer",
  "KateSpadeOutlet",
  "Kiehls",
  "YankeeCandle",
  "MidwayUsa",
  "ItCosmetics",
  "FamousFootwear",
  "AllStarHealth",
  "LauraGeller",
  "LovelySkin",
  "Maglite",
  "MacCosmetics",
  "MielleOrganics",
  "NativeCos",
  "SnipesUsa",
  "Spanx",
  "Puma",
  "VitaminShoppe",
  "MaxWarehouse",
  "BikeCloset",
  "CrunchyRoll",
  "PetsMart",
  "DaCardWorld",
  "JuviasPlace",
  "AlegriaShoes",
  "SigmaBeauty",
  "ShoePalace",
  "MichaelKors",
  "TaosFootwear",
  "Sierra",
  "Sephora",
  "Ulta",
  "Tjmaxx.tjx",
  "Nordstromrack",
  "Rackroomshoes",
  "Shoecarnival",
  "Dickssportinggoods",
  "UrbanOutfitters",
  "FinishLine"
]

export const allStores = [ 
  {
  "value": "All",
  "label": "All"
  },
  {
      "value": "Academy",
      "label": "Academy"
  },
  {
      "value": "AceHardware",
      "label": "AceHardware"
  },
  {
      "value": "AcmeTools",
      "label": "AcmeTools"
  },
  {
      "value": "AlegriaShoes",
      "label": "AlegriaShoes"
  },
  {
      "value": "AllStarHealth",
      "label": "AllStarHealth"
  },
  {
      "value": "Allivet",
      "label": "Allivet"
  },
  {
      "value": "Als",
      "label": "Als"
  },
  {
      "value": "Barnesandnoble",
      "label": "Barnesandnoble"
  },
  {
      "value": "BeallsFlorida",
      "label": "BeallsFlorida"
  },
  {
      "value": "Belk",
      "label": "Belk"
  },
  {
      "value": "BenefitCosmetics",
      "label": "BenefitCosmetics"
  },
  {
      "value": "BestBuy",
      "label": "BestBuy"
  },
  {
      "value": "BigBadToyStore",
      "label": "BigBadToyStore"
  },
  {
      "value": "BigLots",
      "label": "BigLots"
  },
  {
      "value": "BikeCloset",
      "label": "BikeCloset"
  },
  {
      "value": "Bjs",
      "label": "Bjs"
  },
  {
      "value": "BloomingDales",
      "label": "BloomingDales"
  },
  {
      "value": "Bluemercury",
      "label": "Bluemercury"
  },
  {
      "value": "Boscovs",
      "label": "Boscovs"
  },
  {
      "value": "Breville",
      "label": "Breville"
  },
  {
      "value": "ChampsSports",
      "label": "ChampsSports"
  },
  {
      "value": "Chewy",
      "label": "Chewy"
  },
  {
      "value": "Columbia",
      "label": "Columbia"
  },
  {
      "value": "Corelle",
      "label": "Corelle"
  },
  {
      "value": "Costco",
      "label": "Costco"
  },
  {
      "value": "CrunchyRoll",
      "label": "CrunchyRoll"
  },
  {
      "value": "Cvs",
      "label": "Cvs"
  },
  {
      "value": "DKHardware",
      "label": "DKHardware"
  },
  {
      "value": "DaCardWorld",
      "label": "DaCardWorld"
  },
  {
      "value": "DermStore",
      "label": "DermStore"
  },
  {
      "value": "Dickssportinggoods",
      "label": "Dickssportinggoods"
  },
  {
      "value": "Dillards",
      "label": "Dillards"
  },
  {
      "value": "DisneyStore",
      "label": "DisneyStore"
  },
  {
      "value": "DollarTree",
      "label": "DollarTree"
  },
  {
      "value": "Dsw",
      "label": "Dsw"
  },
  {
      "value": "ECosmetics",
      "label": "ECosmetics"
  },
  {
      "value": "Elfcosmetics",
      "label": "Elfcosmetics"
  },
  {
      "value": "EntirelyPetsPharmacy",
      "label": "EntirelyPetsPharmacy"
  },
  {
      "value": "EverythingKitchens",
      "label": "EverythingKitchens"
  },
  {
      "value": "FamousFootwear",
      "label": "FamousFootwear"
  },
  {
      "value": "Farmandfleet",
      "label": "Farmandfleet"
  },
  {
      "value": "FinishLine",
      "label": "FinishLine"
  },
  {
      "value": "FleetFarm",
      "label": "FleetFarm"
  },
  {
      "value": "FleetFeet",
      "label": "FleetFeet"
  },
  {
      "value": "FootLocker",
      "label": "FootLocker"
  },
  {
      "value": "Fossil",
      "label": "Fossil"
  },
  {
      "value": "Funko",
      "label": "Funko"
  },
  {
      "value": "GameStop",
      "label": "GameStop"
  },
  {
      "value": "Glossier",
      "label": "Glossier"
  },
  {
      "value": "HarborFreight",
      "label": "HarborFreight"
  },
  {
      "value": "HealthNutrition",
      "label": "HealthNutrition"
  },
  {
      "value": "HealthyPets",
      "label": "HealthyPets"
  },
  {
      "value": "HobbyLobby",
      "label": "HobbyLobby"
  },
  {
      "value": "Homedepot",
      "label": "Homedepot"
  },
  {
      "value": "HotTopic",
      "label": "HotTopic"
  },
  {
      "value": "Iherb",
      "label": "Iherb"
  },
  {
      "value": "ItCosmetics",
      "label": "ItCosmetics"
  },
  {
      "value": "JCPenney",
      "label": "JCPenney"
  },
  {
      "value": "JDSports",
      "label": "JDSports"
  },
  {
      "value": "Joann",
      "label": "Joann"
  },
  {
      "value": "JoesNewBalanceOutlet",
      "label": "JoesNewBalanceOutlet"
  },
  {
      "value": "JuviasPlace",
      "label": "JuviasPlace"
  },
  {
      "value": "KateSpadeOutlet",
      "label": "KateSpadeOutlet"
  },
  {
      "value": "Keurig",
      "label": "Keurig"
  },
  {
      "value": "Kiehls",
      "label": "Kiehls"
  },
  {
      "value": "Kohls",
      "label": "Kohls"
  },
  {
      "value": "Kroger",
      "label": "Kroger"
  },
  {
      "value": "LauraGeller",
      "label": "LauraGeller"
  },
  {
      "value": "Lego",
      "label": "Lego"
  },
  {
      "value": "LookFantastic",
      "label": "LookFantastic"
  },
  {
      "value": "LovelySkin",
      "label": "LovelySkin"
  },
  {
      "value": "Lowes",
      "label": "Lowes"
  },
  {
      "value": "MacCosmetics",
      "label": "MacCosmetics"
  },
  {
      "value": "Macys",
      "label": "Macys"
  },
  {
      "value": "Maglite",
      "label": "Maglite"
  },
  {
      "value": "Marshalls",
      "label": "Marshalls"
  },
  {
      "value": "MaxWarehouse",
      "label": "MaxWarehouse"
  },
  {
      "value": "Meijer",
      "label": "Meijer"
  },
  {
      "value": "Menards",
      "label": "Menards"
  },
  {
      "value": "MichaelKors",
      "label": "MichaelKors"
  },
  {
      "value": "Michaels",
      "label": "Michaels"
  },
  {
      "value": "MidwayUsa",
      "label": "MidwayUsa"
  },
  {
      "value": "MielleOrganics",
      "label": "MielleOrganics"
  },
  {
      "value": "MyOtcStore",
      "label": "MyOtcStore"
  },
  {
      "value": "NativeCos",
      "label": "NativeCos"
  },
  {
      "value": "Naturalizer",
      "label": "Naturalizer"
  },
  {
      "value": "Nike",
      "label": "Nike"
  },
  {
      "value": "Nordstrom",
      "label": "Nordstrom"
  },
  {
      "value": "Nordstromrack",
      "label": "Nordstromrack"
  },
  {
      "value": "OfficeSupply",
      "label": "OfficeSupply"
  },
  {
      "value": "Petco",
      "label": "Petco"
  },
  {
      "value": "PetsMart",
      "label": "PetsMart"
  },
  {
      "value": "PokemonCenter",
      "label": "PokemonCenter"
  },
  {
      "value": "Puma",
      "label": "Puma"
  },
  {
      "value": "Puritan",
      "label": "Puritan"
  },
  {
      "value": "Qvc",
      "label": "Qvc"
  },
  {
      "value": "Rackroomshoes",
      "label": "Rackroomshoes"
  },
  {
      "value": "Reebok",
      "label": "Reebok"
  },
  {
      "value": "Rei",
      "label": "Rei"
  },
  {
      "value": "RiteAid",
      "label": "RiteAid"
  },
  {
      "value": "SaksOff5th",
      "label": "SaksOff5th"
  },
  {
      "value": "SallyBeauty",
      "label": "SallyBeauty"
  },
  {
      "value": "SamsClub",
      "label": "SamsClub"
  },
  {
      "value": "Scheels",
      "label": "Scheels"
  },
  {
      "value": "Sephora",
      "label": "Sephora"
  },
  {
      "value": "ShoePalace",
      "label": "ShoePalace"
  },
  {
      "value": "Shoecarnival",
      "label": "Shoecarnival"
  },
  {
      "value": "ShopMyExchange",
      "label": "ShopMyExchange"
  },
  {
      "value": "ShopWss",
      "label": "ShopWss"
  },
  {
      "value": "Shoppremiumoutlets",
      "label": "Shoppremiumoutlets"
  },
  {
      "value": "Sierra",
      "label": "Sierra"
  },
  {
      "value": "SigmaBeauty",
      "label": "SigmaBeauty"
  },
  {
      "value": "SkinStore",
      "label": "SkinStore"
  },
  {
      "value": "SnipesUsa",
      "label": "SnipesUsa"
  },
  {
      "value": "Spanx",
      "label": "Spanx"
  },
  {
      "value": "Staples",
      "label": "Staples"
  },
  {
      "value": "SunAndSki",
      "label": "SunAndSki"
  },
  {
      "value": "SupplyHouse",
      "label": "SupplyHouse"
  },
  {
      "value": "SwansonVitamins",
      "label": "SwansonVitamins"
  },
  {
      "value": "TaosFootwear",
      "label": "TaosFootwear"
  },
  {
      "value": "Target",
      "label": "Target"
  },
  {
      "value": "TarteCosmetics",
      "label": "TarteCosmetics"
  },
  {
      "value": "TheBeautyStore",
      "label": "TheBeautyStore"
  },
  {
      "value": "Tjmaxx.tjx",
      "label": "Tjmaxx.tjx"
  },
  {
      "value": "ToolNut",
      "label": "ToolNut"
  },
  {
      "value": "Ulta",
      "label": "Ulta"
  },
  {
      "value": "UnderArmour",
      "label": "UnderArmour"
  },
  {
      "value": "UrbanOutfitters",
      "label": "UrbanOutfitters"
  },
  {
      "value": "VictoriasSecret",
      "label": "VictoriasSecret"
  },
  {
      "value": "VitaCost",
      "label": "VitaCost"
  },
  {
      "value": "VitaminShoppe",
      "label": "VitaminShoppe"
  },
  {
      "value": "VitaminWorld",
      "label": "VitaminWorld"
  },
  {
      "value": "Walgreens",
      "label": "Walgreens"
  },
  {
      "value": "Walmart",
      "label": "Walmart"
  },
  {
      "value": "Webstaurantstore",
      "label": "Webstaurantstore"
  },
  {
      "value": "Woot",
      "label": "Woot"
  },
  {
      "value": "WorldMarket",
      "label": "WorldMarket"
  },
  {
      "value": "YankeeCandle",
      "label": "YankeeCandle"
  },
  {
      "value": "Zappos",
      "label": "Zappos"
  },
  {
      "value": "Zoro",
      "label": "Zoro"
  },
  {
      "value": "Zumiez",
      "label": "Zumiez"
  }
];
