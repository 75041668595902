import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import {
  Checkbox,
  Button,
  Radio
} from "@mui/material";

import Header from '../../component/header';
import Notification from '../../component/notifications/notification';
import Spin from '../../component/Spin';

import { SetAuthState } from '../../redux/slices/auth-slice';
import {
  CreateSubscription,
  SetSubscriptionState
} from '../../redux/slices/subscription-slice';
import {
  GetUser,
  SetUserState
} from '../../redux/slices/user-slice';

import { HUNDERED_UPC_STORES } from '../../constants/constants';

import { SupportedStoreWrapper } from "./style";

const SupportedStore = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    priceId,
    subscriptionCreation,
    couponCode
  } = useParams();

  const { user: userData, selectedPlan } = useSelector(state => (state.auth));
  const {
    message,
    success,
    error,
    loading
  } = useSelector(state => (state.subscription));
  const { user, userSuccess, impersonateUserData } = useSelector(state => (state.user));

  const [supportedStores, setSupportedStores] = useState({});

  const handleChange = (store) => {
    let checkedStores = supportedStores;

    checkedStores = {
      ...checkedStores,
      [store]: checkedStores[store] ? !checkedStores[store] : true
    }
    
    setSupportedStores(checkedStores);
  };

  const handleCreateSubscription = () => {
    const stores = [];

    for (const [key, value] of Object.entries(supportedStores)) {
      if (value) {
        stores.push(key)
      }
    };

    const {
      name,
      price,
      plan
    } = selectedPlan || {}

    let maxStores = 1;
    if (name === 'Growth') {
      maxStores = 2;
    }

    if ((name === 'Standard' && stores.length === maxStores) || (name === 'Growth' && stores.length === maxStores)) {
      let coupon = null;
      if (couponCode && couponCode !== 'null') {
        coupon = couponCode;
      }
      if (user?.payment) {
        dispatch(CreateSubscription({
          impersonateUserId: impersonateUserData.userId,
          priceId,
          subscriptionPlan: plan,
          subscriptionCreatingFirstime: subscriptionCreation,
          subscriptionType: name,
          couponCode: coupon,
          subscribedStores: stores
        }));
      } else {
        dispatch(SetUserState({
          field: 'subscriptionData', value: {
            priceId,
            subscriptionCreatingFirstime: subscriptionCreation,
            subscriptionPlan: plan,
            subscriptionType: name,
            couponCode: coupon,
            subscribedStores: stores
          }
        }));
        history.push('/auth/payment');
      }
    } else {
      dispatch(SetSubscriptionState({ field: 'error', value: `You can select only ${maxStores} Stores at a time` }));
    }
  };

  useEffect(() => {
    if (success && message) {
      Notification({
        type: "success",
        title: "Success",
        description: message
      });

      dispatch(GetUser({ 
        userId: userData._id, 
        impersonateUserId: impersonateUserData.userId
      }));
      dispatch(SetSubscriptionState({ field: 'success', value: false }));
      dispatch(SetSubscriptionState({ field: 'message', value: '' }));
    }
  }, [message, success]);

  useEffect(() => {
    if (user?.payment && userSuccess && user?.subscriptionId) {
      dispatch(SetAuthState({ field: 'user', value: { ...userData, payment: user.payment, status: user.status, subscriptionId: user.subscriptionId } }));
      dispatch(SetUserState({ field: 'userSuccess', value: false }));
      if (user.subscriptionId) {
        history.push('/dashboard')
      }
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error
      });

      dispatch(SetSubscriptionState({ field: 'error', value: '' }));
    }
  }, [error]);

  return (
    <>
    <SupportedStoreWrapper>
      <Header/>
      <div className="overlay-ui-wrapper">
        <h1>Supported Stores {supportedStores?.length && <p>{supportedStores.length}</p>}</h1>
      </div>
      <div className="overlay-content-wrapper">
        <div className="overlay-content">
        {
          HUNDERED_UPC_STORES.map((item, index) => {
            return (
                <div className="overlay-content-item" key={item}>
                {index+1}.
                  <Checkbox
                    checked={supportedStores[item] || false}
                    onChange={() => handleChange(item)}
                  />
                  <p>{item}</p>
                </div>
              );
            })
          }
          </div>
        <div />
      </div>
      <Button className="submit-ui-button" onClick={() => handleCreateSubscription()}>Confirm</Button>
    </SupportedStoreWrapper>
    {loading && <Spin />}
    </>
  );
};

export default SupportedStore;
