import React, { useState, useEffect } from "react";
import {
  useSelector,
  useDispatch
} from "react-redux";

import { debounce } from "lodash";
import { useHistory, Link } from "react-router-dom";

import { Checkbox, Tooltip } from 'antd';

import Button from "../../component/Button/Index";
import SearchInput from "../../component/searchInput/Index";
import Modal from "../../component/modal/index";
import { SubSuersWrapper } from "./style";
import Input from "../../component/inputs/input/index";
import IconTrash from "../../assets/images/icon-basket.svg";
import Table from "../../component/table/Index";
import TablePagination from "../../component/pagination";
import Notification from "../../component/notifications/notification";
import Spin from '../../component/Spin';
import { BsCheckCircleFill } from "react-icons/bs";
import { MdDoNotDisturbOn } from "react-icons/md";

import {
  AddSubUser,
  GetSubUsers,
  SetUserState,
  DeleteUser
} from "../../redux/slices/user-slice";

const SubUsers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading,
    totalUsers,
    users,
    user,
    error,
    message,
    impersonateUserData
  } = useSelector(state => state.user);
  

  const [popupDelete, setPopupDelete] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');

  const pageOptions = [10, 25, 50, 100];
  const columnDefs = [
    { headerName: "Name", minWidth: 315, flex: 1, field: "name" },
    { headerName: "Email", minWidth: 150, flex: 1, field: "email" },
    { headerName: "Status", minWidth: 150, flex: 1, field: "status" },
    {
      headerName: "Allow Extension Usage",
      minWidth: 200,
      flex: 1,
      type: "rightAligned",
      field: "actions",
      cellRenderer: ({ data }) => {
        const {
          role
        } = data || {};
        return <SubSuersWrapper>{role === 'special-sub-user' ? <BsCheckCircleFill className="sub-user-check"/> : <MdDoNotDisturbOn className="sub-user-not-check"/> }</SubSuersWrapper>
      },
    },
    {
      headerName: "Actions",
      minWidth: 200,
      flex: 1,
      type: "rightAligned",
      field: "actions",
      cellRenderer: ({ data }) => {
        const {
          _id
        } = data || {};
        return <SubSuersWrapper><Tooltip placement="top" title="Delete"><img className="trash-icon" src={IconTrash} alt="delete-Icon" onClick={() => {
          setPopupDelete(true);
          setUserId(_id);
        }} /></Tooltip></SubSuersWrapper>
      },
    },
  ];

  const [addUser, setAddUser] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const [userId, setUserId] = useState('');
  const [specialSubUser, setSpecialSubUser] = useState(false);

  const emailValidation = (email) => {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
  };

  const handleAddSubUser = () => {
    if(!name) {
      dispatch(SetUserState({ field: 'error', value: 'Name is required.' }));
    } else if (!email) {
      dispatch(SetUserState({ field: 'error', value: 'Email is required.' }));
    } else if (!emailValidation(email)) {
      dispatch(SetUserState({ field: 'error', value: 'Please Enter a valid email address' }));
    } else {
      dispatch(AddSubUser({
        name,
        email,
        userId: impersonateUserData?.userId ? impersonateUserData.userId : user?._id,
        specialSubUser
      }));

      setAddUser(false);
      setName('');
      setEmail('');
      setSpecialSubUser(false)
    }
  }

  const handleDeleteUser = () => {
    dispatch(DeleteUser({ userId }));
    setPopupDelete(false)
  };

  const handleOnPageChange = (current, pageSize) => {
    setPageNumber(current);
    setPageLimit(pageSize);
  };

  const handleSearch = debounce((e) => {
    setSearchKeyword(e.target.value);
    setPageNumber(1);
  }, 300);

  useEffect(()=> {
    dispatch(GetSubUsers({
      userId: impersonateUserData?.userId ? impersonateUserData.userId : user?._id,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
      searchKeyword
    }))
  }, []);

  useEffect(() => {
    dispatch(GetSubUsers({
      userId: impersonateUserData?.userId ? impersonateUserData.userId : user?._id,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
      searchKeyword
    }));
  }, [pageLimit, pageNumber, searchKeyword]);

  useEffect(() => {
    if (users && users.length) {
      const data = users.map(
        (
          {
            _id,
            name,
            email,
            createdAt,
            status,
            role
          },
          index
        ) => {
          return {
            _id,
            name,
            email,
            createdAt,
            status,
            role
          };
        }
      );

      setTableRows(data);
    } else {
      setTableRows([])
    }
  }, [users]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error,
      });
      dispatch(SetUserState({ field: "error", value: "" }));
      return;
    }

    if (message) {
      Notification({
        type: "success",
        title: "Success",
        description: message,
      });
      dispatch(SetUserState({ field: "message", value: "" }));
      dispatch(SetUserState({ field: "success", value: false }));

      if (totalUsers && totalUsers > 0) {
        const remainingSubUsersOnCurrentPage = users.length - 1;
        if (remainingSubUsersOnCurrentPage > 0) {
          dispatch(GetSubUsers({
            userId: impersonateUserData?.userId ? impersonateUserData.userId : user?._id,
            limit: pageLimit,
            skip: (pageNumber - 1) * pageLimit,
            searchKeyword
          }));
        } else {
          const nextPage = pageNumber - 1;
          setPageNumber(nextPage === 0 ? 1 : nextPage);
          history.push('/sub-users');
          dispatch(GetSubUsers({
            userId: impersonateUserData?.userId ? impersonateUserData.userId : user?._id,
            limit: pageLimit,
            skip: (1 - 1) * pageLimit,
            searchKeyword
          }));
        }
      }
      if (totalUsers === 0) {
        dispatch(GetSubUsers({
          userId: impersonateUserData?.userId ? impersonateUserData.userId : user?._id,
          limit: pageLimit,
          skip: (1 - 1) * pageLimit,
          searchKeyword
        }));
      }
      return;
    }
  }, [error, message]);

  return (
    <SubSuersWrapper>
      <div className="d-flex mb-2 justify-content-between">
        <h1 className="page-title">Sub Users List</h1>
        <div className="right-side-action d-flex">
        <SearchInput placeholder="Search Users" onChange={(e) => handleSearch(e)}/>

          <Button onClick={() => setAddUser(true)} outlined>
            Add Sub User
          </Button>
        </div>
      </div>

      <Table
        height={"207px"}
        headerHeight={50}
        columnDefinitions={columnDefs}
        rowData={tableRows}
        rowHeight={67}
        loading = {loading}
      />
      <div className="table-box">
        <TablePagination
          background="#F8F8F8"
          records={totalUsers}
          options={pageOptions}
          onChange={handleOnPageChange}
          pageNumber={pageNumber}
          pageLimit={pageLimit}
        />
      </div>
      <Modal
        className="cancel-modal add-sub-user-modal"
        open={addUser}
        saveText="Add Sub User"
        closeText="Cancel"
        onSave={() => handleAddSubUser()}
        onClose={() => {
            setAddUser(false);
            setName('');
            setEmail('');
            setSpecialSubUser(false)
          }}
      >
        <div className="modal-ui-wrapper">
          <Input label="Name" className="validation-wrapper" placeholder="Enter Name" onChange={e => setName(e.target.value)} />
          <Input label="Email" type="email" className={`validation-wrapper ${email && !emailValidation(email) ? 'login-pg-input-email' : ''}`} placeholder="Enter Email" onChange={e => setEmail(e.target.value)} />
        </div>
        <Checkbox style={{float: 'left'}} checked={specialSubUser} disabled={['Lite', 'Standard', 'Growth']?.includes(user?.subscriptionType)} onChange={e => setSpecialSubUser(e.target.checked)}>Allow Extension Usage {['Lite', 'Standard', 'Growth'].includes(user?.subscriptionType) && <span>(Needs to upgrade subscription plan)</span>}</Checkbox>
      </Modal>
      <Modal
          className="cancel-modal"
          open={popupDelete}
          saveText="Delete"
          closeText="Cancel"
          onSave={() => handleDeleteUser()}
          onClose={() => setPopupDelete(false)}
        >
          <div className="category-modal-wrapper">
            <img src={IconTrash} alt="no/" className="icon-popup" />
            <h2>Are you sure want to delete this User?</h2>
            <p>
              The selected User will be deleted and it will be removed from our
              system.
            </p>
          </div>
        </Modal>
        {loading && <Spin />}
    </SubSuersWrapper>
  );
};

export default SubUsers;
