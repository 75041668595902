import Cookies from 'universal-cookie';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Drawer, Switch, Tooltip } from 'antd';
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { FcCancel } from 'react-icons/fc';
import { MdRotateLeft } from 'react-icons/md';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

import Modal from '../../component/modal';
import Button from '../../component/Button/Index';
import Input from '../../component/inputs/input/index';
import AmazonWhite from '../../assets/images/amazon-img-white.svg';
import IconTrash from '../../assets/images/icon-basket.svg';
import Spin from '../../component/Spin';
import Notification from '../../component/notifications/notification';
import ProfilePayment from "../ProfilePayment/Payment"

import { ManageAccountWrapper, CardStylingWrapper } from './style';
import { SubscriptionPlanWrapper } from "../PaymentsScreen/SubscriptionPlan/style";

import {
  keepaKeyValidation,
  SetAuthState
} from '../../redux/slices/auth-slice';

import {
  CreateSubscription,
  GetSubscriptionPlans,
  SetSubscriptionState
} from '../../redux/slices/subscription-slice';

import {
  CancelSubscription,
  GetUser,
  SetUserState,
  UpdateProfileInfo,
} from '../../redux/slices/user-slice';

import {
  GetStores,
  DeleteStore,
  SetStoreState
} from '../../redux/slices/store-slice';

import { marketplaceFlags } from '../../utils/helpers';
import { CURRENCY_MAPPING } from '../../constants/constants';
import SubscriptionPlanCard from '../../component/subscriptionCard/profile-subscription-card';
import { SelectPlanWrapper } from '../Auth/style';

const Index = () => {
  const {
    token,
    storeStatus,
    error: keepaErr,
    message: authMessage,
    user: userData,
    keepaSuccess,
    keepaError,
    keepaValid
  } = useSelector(state => (state.auth));

  const {
    user,
    loading,
    error,
    message,
    success,
    impersonateUserData,
    subscriptionCancelled
  } = useSelector(state => state.user);

  const {
    storesData: stores,
    loading: storeLoading,
    error: storeError,
    message: storeMessage,
    success: storeSuccess
  } = useSelector(state => state.store);

  const {
    loading: subscriptionLoading,
    message: subscriptionMessage,
    error: subscriptionError,
    subscriptionPlans,
    subscriptionCreated
  } = useSelector(state => (state.subscription));

  const dispatch = useDispatch();
  const history = useHistory();

  const cookie = new Cookies();

  const [storesData, setStoresData] = useState([]);
  const [storeDelete, setStoreDelete] = useState(false);
  const [storeToDelete, setStoreToDelete] = useState('');
  const [userInfo, setUserInfo] = useState({
    name: impersonateUserData.name || user?.name || ''
  });
  const [paymentDrawer, setPaymentDrawer] = useState(false)
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const onClose = () => {
    setPaymentDrawer(false);
  };
  const [subscriptions, setSubscriptions] = useState([]);
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [priceId, setPriceId] = useState('');
  const [interval, setInterval] = useState('');
  const [couponCode, setCouponCode] = useState(null);
  const [blurEffect, setBlurEffect] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState('');
  const [updatePassword, setUpdatePassword] = useState(false);
  const [keepaKeyValue, setKeepaKeyValue] = useState('');
  const [referralLink, setReferralLink] = useState('');
  const [subscriptionDetails, setSubscriptionDetails] = useState();

  const iconClickAble = () => {
    setBlurEffect(!blurEffect)
  };

  const handleInfoChange = (key, value) => {
    setUserInfo({
      ...userInfo,
      [key]: key === 'name' ? value : value.trim()
    });
  };

  const addKeepaKey = () => {
    dispatch(keepaKeyValidation({ key: keepaKeyValue, userId: userData.userId }));
  }

  const handleUpdateUserInfo = () => {
    if (updatePassword && userInfo?.oldPassword
      && userInfo.newPassword && userInfo.confirmPassword
      && userInfo.newPassword === userInfo.confirmPassword
    ) {
      dispatch(UpdateProfileInfo({
        impersonateUserId: impersonateUserData.userId,
        ...userInfo
      }));
    } else if (!updatePassword && userInfo.name) {
      dispatch(UpdateProfileInfo({
        impersonateUserId: impersonateUserData.userId,
        ...userInfo
      }));
    } else if (!userInfo.name) {
      dispatch(SetUserState({ field: "error", value: "Please enter your name" }));
      return;
    } else if (!userInfo.oldPassword) {
      dispatch(SetUserState({ field: "error", value: "Please enter old password" }));
      return;
    } else if (!userInfo.newPassword) {
      dispatch(SetUserState({ field: "error", value: "Please enter new password" }));
      return;
    } else if (!userInfo.confirmPassword) {
      dispatch(SetUserState({ field: "error", value: "Please enter confirm password" }));
      return;
    } else {
      dispatch(SetUserState({ field: "error", value: "New password and Confirm password should be matched !" }));
      return;
    }
  };

  const handleDeleteStore = () => {
    dispatch(DeleteStore({ storeId: storeToDelete }));
    setStoreDelete(false);
  };

  const handleStoreDeleteModal = (storeId) => {
    setStoreToDelete(storeId);
    setStoreDelete(true);
  };

  const handleEditStorePush = (storeId, storeName, storeRegion) => {
    dispatch(SetStoreState({ field: 'storeName', value: storeName }));
    dispatch(SetStoreState({ field: 'storeRegion', value: storeRegion }));
    history.push(`/edit-store/${storeId}`)
  };

  const handleSubscription = () => {
    let currentSubscriptionPlan;
    if (isChecked) {
      currentSubscriptionPlan = subscriptionPlans.find(plan => plan.unitAmount === 588);
    } else {
      currentSubscriptionPlan = subscriptionPlans.find(plan => plan.unitAmount === 99);
    }
    const {
      priceId,
      interval,
      subscriptionName
    } = currentSubscriptionPlan || {};

    setPriceId(priceId);
    setInterval(interval);
    setSubscriptionModal(true);
    setSubscriptionType(subscriptionName);
  };

  const handleCancelSubscription = () => {
    dispatch(CancelSubscription({ impersonateUserId: impersonateUserData.userId }));
    setCancelSubscriptionModal(false);
  };

  const handleCreateSubscription = () => {
    const subscriptionPlan = interval === 'month' ? 'monthlyPlan' : 'yearlyPlan';

    if (subscriptionType === 'Starter' || subscriptionType === 'Growth') {
      return history.push(`/store-based-subscription/${priceId}/${subscriptionType}/${couponCode}/${false}`);
    } else {
      dispatch(CreateSubscription({
        impersonateUserId: impersonateUserData.userId,
        priceId,
        subscriptionPlan,
        subscriptionCreatingFirstime: false,
        subscriptionType,
        couponCode
      }));
      setSubscriptionModal(false);
    }
  };

  const copyReferralLink = (id) => {
    const url = `${process.env.NODE_ENV === 'development' ? 'localhost:3300' : 'https://qikfinds-extension.com'}/auth/sign-up?ref=${id}`
    navigator.clipboard
      .writeText(url)
      .then(t => setReferralLink(url))
      .catch(e => false);
    setTimeout(() => {
      setReferralLink(false)
    }, 2000);
  }

  useEffect(() => {
    dispatch(GetUser({
      impersonateUserId: impersonateUserData.userId
    }));

    dispatch(GetStores({
      impersonateUserId: impersonateUserData.userId
    }));

    dispatch(GetSubscriptionPlans({ productName: 'QikFinds' }));
    const { keepaKey } = user || {};

    if (keepaKey && keepaKey !== 'N/A') {
      setKeepaKeyValue(keepaKey)
    }
  }, []);

  useEffect(() => {
    if (stores && stores.length) {
      const data = stores.map((store, i) => {
        let region = "N/A";
        if (store.region === "na") {
          region = "North America";
        } else if (store.region === "eu") {
          region = "Europe";
        } else if (store.region === "fe") {
          region = "Far East";
        }

        return (
          <>
            <div className={store.status === 'Connected' ? "authorize-card active" : "authorize-card"}>
              <div className="d-flex justify-content-between authorize-card-header">
                <img src={AmazonWhite} alt="no-marketplace" />
                <div className="icon-box">
                  <BsPencilSquare className="edit" onClick={() => handleEditStorePush(store._id, store.name, store.region)} />
                  <BsTrash className="trash" onClick={() => handleStoreDeleteModal(store._id)} />
                </div>
              </div>
              <div className="d-flex justify-content-between store-name align-items-center">
                <CardStylingWrapper>
                  <div className='d-flex align-items-center'>
                    <h2 className={`${blurEffect ? ' mb-0 blur-effect' : "mb-0"}`}>{store?.name || 'N/A'}</h2><span onClick={iconClickAble}> {blurEffect ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</span>
                  </div>
                </CardStylingWrapper>
                <div className={store.status === 'Connected' ? "badge active" : "badge inactive"}>{store.status === 'Connected' ? 'active' : 'inactive'}</div>
              </div>
              <div className="d-flex justify-content-between country-info align-items-center">
                <h3>{region}</h3>
                <div>
                  {
                    store.marketplaces.map((marketplace, index) => {
                      const { countryCode } = marketplace;
                      const marketplaceFlagsList = marketplaceFlags(store.region);
                      const flag = marketplaceFlagsList.find(
                        (item) => item.name === countryCode
                      );
                      return (
                        <img key={index} src={flag?.country} alt="no-country" />
                      );
                    })
                  }
                </div>
              </div>
            </div>
          </>
        )
      });

      setStoresData(data);
    } else {
      setStoresData([]);
    }
  }, [stores, blurEffect]);

  useEffect(() => {
    if (subscriptionPlans && subscriptionPlans.length) {
      setSubscriptions(subscriptionPlans);
    }

    dispatch(SetSubscriptionState({ field: 'success', value: false }));
  }, [subscriptionPlans]);

  useEffect(() => {
    if (user) {
      setUserInfo({
        name: user?.name || ''
      });

      dispatch(SetAuthState({ field: 'user', value: { ...userData, subscriptionPlan: user.subscriptionPlan } }));
      dispatch(SetAuthState({ field: 'selectedPlan', value: user.selectedPlan }));
      dispatch(SetUserState({ field: 'userSuccess', value: false }));
    }
  }, [user]);

  useEffect(() => {
    if (success && message) {
      Notification({
        type: "success",
        title: "Success",
        description: message,
        key: 1,
      });
      setCouponCode(null);
      dispatch(SetUserState({ field: 'message', value: '' }));
      dispatch(SetUserState({ field: 'success', value: false }));
      dispatch(SetUserState({ field: 'userSuccess', value: false }));
      dispatch(SetUserState({ field: 'user', value: { ...user, name: userInfo.name } }))
      setUserInfo({
        ...userInfo,
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      })
      setUpdatePassword(false);
      return;
    }

    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error,
        key: 1,
      });
      dispatch(SetUserState({ field: 'error', value: '' }));
    }
  }, [error, success, message]);

  useEffect(() => {
    if (storeSuccess && storeMessage) {
      Notification({
        type: "success",
        title: "Success",
        description: storeMessage,
        key: 1,
      });
      dispatch(SetStoreState({ field: 'message', value: '' }));
      dispatch(SetStoreState({ field: 'success', value: false }));
      dispatch(GetStores({ impersonateUserId: impersonateUserData.userId }));
      return;
    }

    if (storeError) {
      Notification({
        type: "error",
        title: "Error",
        description: storeError,
        key: 1
      });
      dispatch(SetStoreState({ field: 'error', value: '' }));
    }
  }, [storeError, storeSuccess, storeMessage]);

  useEffect(() => {
    if (subscriptionMessage) {
      Notification({
        type: "success",
        title: "Success",
        description: subscriptionMessage,
        key: 1
      });

      dispatch(SetSubscriptionState({ field: 'message', value: '' }));
      dispatch(SetSubscriptionState({ field: 'success', value: false }));

      // dispatch(GetUser({
      //   impersonateUserId: impersonateUserData.userId
      // }));
      // dispatch(GetSubscriptionPlans({ productName: 'QikFinds' }));

      const currentDate = new Date();
      const nextYear = new Date();

      nextYear.setFullYear(currentDate.getFullYear() + 1);
      if (token && storeStatus === 'Connected') {
        cookie.set('qikFindsToken', token, {
          path: '/',
          expires: nextYear
        });

        const { keepaKey } = userData || {};
        if (keepaKey) {
          cookie.set('keepaKey', keepaKey, {
            path: '/',
            expires: nextYear
          });
        }
      }
      return;
    }

    if (subscriptionError) {
      Notification({
        type: "error",
        title: "Error",
        description: subscriptionError,
        key: 1
      });
      dispatch(SetSubscriptionState({ field: 'error', value: '' }));
    }
  }, [subscriptionError, subscriptionMessage]);

  useEffect(() => {
    if (subscriptionCancelled) {
      // cookie.remove('qikFindsToken', { path: '/' });
      // cookie.remove('keepaKey', { path: '/' });
      console.log('cancellled 2 ======================>')

      dispatch(SetUserState({ field: 'subscriptionCancelled', value: false }));
      dispatch(SetAuthState({ field: 'user', value: { ...userData, status: 'InActive' } }));

      dispatch(GetUser({
        impersonateUserId: impersonateUserData.userId
      }));
      // dispatch(GetSubscriptionPlans({ productName: 'QikFinds' }));
      // dispatch(LogOut());

      return;
    }

    // if (subscriptionCreated) {
    //   console.log('created ======================>')
    //   dispatch(GetUser({ impersonateUserId: impersonateUserData.userId }));
    //   dispatch(SetSubscriptionState({ field: 'subscriptionCreated', value: false }));
    //   return;
    // }
  }, [subscriptionCancelled]);

  useEffect(() => {
    if (keepaSuccess && keepaError) {
      Notification({
        type: "error",
        title: "Error",
        description: 'Enter a valid Keepa API Key.',
        key: 1
      });
      dispatch(SetAuthState({ field: "keepaError", value: "" }));
      dispatch(SetAuthState({ field: "keepaSuccess", value: false }));
      return;
    }

    if (keepaSuccess && keepaValid) {

      dispatch(SetAuthState({ field: "keepaValid", value: false }));
      dispatch(SetAuthState({ field: "keepaSuccess", value: false }));
      dispatch(SetAuthState({ field: "user", value: { ...userData, keepaKey: keepaKeyValue } }));
      Notification({
        type: "success",
        title: "Success",
        description: authMessage,
        key: 1
      });

      const currentDate = new Date();
      const nextYear = new Date();

      nextYear.setFullYear(currentDate.getFullYear() + 1);
      cookie.set('keepaKey', keepaKeyValue, {
        path: '/',
        expires: nextYear
      });
      return;
    }
    if (keepaErr) {
      Notification({
        type: "error",
        title: "Error",
        description: 'Enter a valid Keepa API Key.',
        key: 1
      });
      dispatch(SetAuthState({ field: 'error', value: '' }));
      return;
    }
  }, [keepaSuccess, keepaError, keepaValid, keepaErr]);
  const data = [
    {
      text: 'ASIN'
    },
    {
      text: 'Amazon Product Title'
    },
    {
      text: 'Amazon Product Image'
    },
    {
      text: 'Meltable / Restriction / HAZMAT'
    },
    {
      text: 'Sales Rank & Category'
    },
    {
      text: 'Brand and Manufacturer'
    },
    {
      text: 'Store Discounts/Promos'
    },
    {
      text: 'Profit Calculation'
    },
    {
      text: 'Keepa Graph'
    },
    {
      text: 'Ratings & Reviews'
    },
    {
      text: 'Sold History'
    },
    {
      text: 'On-Hand Inventory'
    },
    {
      text: 'Rank Drops (30/90/180)'
    },
    {
      text: 'FBA / FBM Offers'
    },
    {
      text: 'Keepa New Offer Count Graph'
    },
    {
      text: 'Google Product Search'
    },
    {
      text: 'Multipack Finders'
    },
    {
      text: 'Research Criteria Filters'
    },
    {
      text: 'Custom Notes'
    },
    {
      text: 'Add Favorites'
    },
    {
      text: 'Data Sync With Dashboard'
    },
    {
      text: 'Non-UPC Product Search'
    },
  ]
  return (
    <ManageAccountWrapper>
      <div className="d-flex justify-content-between">
        <h2 className="setting-heading">Account Settings</h2>
        <div className='d-flex gap-2 align-items-center'>
          {(!["sub-user", "special-sub-user", "admin"].includes(userData?.role) || impersonateUserData.userId) && <Tooltip placement='top' title={referralLink ? "Copied" : "Click to Copy"}><p className="mb-0 copy-referral-button" onClick={() => copyReferralLink(impersonateUserData.userId || userData?.userId || userData?._id)}>Copy Referral Link</p></Tooltip>}
        </div>
      </div>
      <div className="setting-page">
        <div className="d-flex justify-content-between add-bottom-spacing settings-title-bar">
          <div className="d-flex">
            <h6 className="fw-bold">Profile </h6>
          </div>
          <div className='actions-button-wrapper'>
            <Button type="primary" className="mb-0 " outlined onClick={() => setUpdatePassword(!updatePassword)}>
              Update Password
            </Button>
            {/* <Button type="primary" className="mb-0 " outlined onClick={    () => handleUpdateUserInfo()}>
            Update
          </Button> */}
          </div>
        </div>
        <Drawer
          title="Payment"
          placement="right"
          width={900}
          onClose={onClose}
          open={paymentDrawer}
        >
          <ProfilePayment setPaymentDrawer={setPaymentDrawer} />

        </Drawer>
        <Row>
          <Col md={3}>
            <Input
              label="Name"
              placeholder="Name"
              className="mb-20"
              value={userInfo.name}
              onChange={(e) => handleInfoChange('name', e.target.value)}
            />
          </Col>
          <Col md={3}>
            <Input
              label="Email"
              placeholder={impersonateUserData.email || user.email}
              className="mb-20 disable-input-wrapper"
              disabled={true}
            />
          </Col>
          {userInfo.name !== user.name && !updatePassword ? <Col md={3} className="d-flex align-items-center mt-1 " >
            <Button type="primary" className="m-0" outlined onClick={() => handleUpdateUserInfo()}>
              Update
            </Button>
          </Col> : null}

        </Row>
        {updatePassword && <Row>
          <Col md={3}>
            <Input
              label="Old Password"
              placeholder="Enter Old Password"
              className="mb-20"
              value={userInfo.oldPassword}
              onChange={(e) => handleInfoChange('oldPassword', e.target.value)}
            />
          </Col>
          <Col md={3}>
            <Input
              label="New Password"
              placeholder="Enter new Password"
              className="mb-20"
              value={userInfo.newPassword}
              onChange={(e) => handleInfoChange('newPassword', e.target.value)}
            />
          </Col>
          <Col md={3}>
            <Input
              label="Confirm Password"
              placeholder="Enter Confirm Password"
              className="mb-20"
              value={userInfo.confirmPassword}
              onChange={(e) => handleInfoChange('confirmPassword', e.target.value)}
            />
          </Col>
          <Col md={3} className="d-flex align-items-center mt-1">
            <Button type="primary" className="m-0" outlined onClick={() => handleUpdateUserInfo()}>
              Update
            </Button>
          </Col>
        </Row>}
        {
          (userData?.role !== 'sub-user' && userData?.role !== 'special-sub-user') ? <>
            <hr className="setting-page-divider" />
            <div className="d-flex justify-content-between justify-content-start flex-column align-items-start add-bottom-spacing settings-title-bar">
              <div>
              <span className="fw-bold">Step 1:</span>
              <h6 className="fw-bold">Keepa Api Key </h6>
              </div>
              <Col md={8} className="d-flex align-items-center mt-2">
                <div className='mr-5'>
                  Keepa Key <a href='https://keepa.com/#!api' target='_blank'>(Find it here)</a>
                  <Input
                    placeholder="Keepa API Key"
                    value={keepaKeyValue}
                    width="700px"
                    onChange={(e) => setKeepaKeyValue(e.target.value)}
                    className={`validation-wrapper ${user.keepaKey === keepaKeyValue && keepaKeyValue ? 'match-keepa-key' : 'mismatch-or-empty'}`}
                  />
                </div>
                {userData.keepaKey !== keepaKeyValue ? <Button type="primary" className="mb-0 ms-5 mt-1" large outlined onClick={() => addKeepaKey()}>
                  Save
                </Button> : null}
              </Col>

            </div>
            <hr className="setting-page-divider" />
            <div className="d-flex justify-content-between align-items-center add-bottom-spacing settings-title-bar">
              <div>
              <span className="fw-bold">Step 2:</span>
              <h6 className="fw-bold">Stores </h6>
              </div>
              <Button type="primary" className="mb-0 mb-2" large outlined onClick={() => history.push('/select-region')}>
                Add Store
              </Button>
            </div>
            <div className="d-flex gap-4">
              {storesData}
            </div>
            {
              userData.role !== 'admin' || impersonateUserData.userId ?
                <>
                  <hr className="setting-page-divider" />
                  <SelectPlanWrapper>
                    <div className="right-side-content profile-side-content">
                      <div className='heading-wrapper'>
                      <span className="fw-bold">Step 3:</span>
                      <h2>Subscription Plans</h2>
                      </div>
                      {(userData?.role !== 'sub-user' && userData?.role !== 'special-sub-user') && <Button type="primary" className="mb-0 drawer-btn-wrapper" small outlined onClick={() => setPaymentDrawer(true)}>Payment Drawer</Button>}

                    </div>
                    <div className='cards-overlay-wrapper'>
                      <SubscriptionPlanCard className="card-overaly" heading="Lite" labelText="All Supported UPC Stores" data={[
                        {
                          text: 'UPC'
                        },
                        {
                          text: 'Search title on Amazon'
                        },
                        {
                          text: 'Optional (API required):'
                        },
                        {
                          text: 'ASIN'
                        },
                        {
                          text: 'Keepa Graph'
                        }
                      ]} subscriptionType={user?.subscriptionType} plan={user?.subscriptionPlan} />
                      <SubscriptionPlanCard className="card-overaly" heading="Standard" labelText="50 Selected UPC Stores" data={data} subscriptionType={user?.subscriptionType} plan={user?.subscriptionPlan} />
                      <SubscriptionPlanCard className="card-overaly" heading="Growth" labelText="100 Selected UPC & Non-UPC Stores" data={data} subscriptionType={user?.subscriptionType} plan={user?.subscriptionPlan} />
                      <SubscriptionPlanCard className="card-overaly" heading="Team" labelText="Unlimited Stores" data={data} subscriptionType={user?.subscriptionType} plan={user?.subscriptionPlan} />
                    </div>
                  </SelectPlanWrapper>
                </> : null
            }
          </> : null
        }
      </div>
      <Modal
        className="cancel-modal"
        open={storeDelete}
        saveText="Delete"
        closeText="Cancel"
        onSave={() => handleDeleteStore()}
        onClose={() => setStoreDelete(false)}
      >
        <div className="category-modal-wrapper store-modal-wrapper">
          <img src={IconTrash} alt="no/" className='icon-popup' />
          <h2>Are you sure want to delete the Store</h2>
        </div>
      </Modal>
      <Modal
        className="cancel-modal"
        open={cancelSubscriptionModal}
        saveText="Cancel Subscription"
        closeText="Cancel"
        onSave={() => handleCancelSubscription()}
        onClose={() => setCancelSubscriptionModal(false)}
      >
        <div className="category-modal-wrapper store-modal-wrapper">
          <FcCancel className="icon-popup" />
          <h2>Are you sure want to cancel the subscription?</h2>
        </div>
      </Modal>
      <Modal
        className="cancel-modal"
        open={subscriptionModal}
        saveText="Update"
        closeText="Cancel"
        onSave={() => handleCreateSubscription()}
        onClose={() => setSubscriptionModal(false)}
      >
        <div className="category-modal-wrapper store-modal-wrapper">
          <MdRotateLeft
            className="icon-popup"
            style={{ stroke: 'red' }}
          />
          <h2>Are you sure want to update the subscription?</h2>
        </div>
      </Modal>
      {
        (loading || storeLoading || subscriptionLoading) && (
          <Spin />
        )
      }
    </ManageAccountWrapper>

  );
};

export default Index;
