import moment from 'moment';
import jwt from 'jsonwebtoken';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Button from "../../component/Button/Index";
import Notification from '../../component/notifications/notification';
import PasswordInput from "../../component/inputs/passwordInput/index";
import Spin from '../../component/Spin';

import {
  SetAuthState,
  ResetPassword
} from '../../redux/slices/auth-slice';

import { Row, Col } from "react-bootstrap";
import ResetPasswordLayout from "./layout";

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const [confirmPassword, setConfirmPassword] = useState('');
  const [userData, setUserData] = useState({
    firstName: 'N/A',
    lastName: 'N/A',
    email: 'N/A',
    password: '',
    company: 'N/A',
    keepaKey: 'N/A'
  });

  const handleChange = (field, value) => {
    setUserData({
      ...userData,
      [field]: value
    });
  };
  const {
    error,
    message,
    success,
    loading
  } = useSelector((state) => state.auth);

  const queryParams = new URLSearchParams(useLocation().search);
  const queryToken = queryParams.get('token');
  const decodedToken = jwt.decode(queryToken, { complete: true });
  const { exp } = decodedToken.payload || {};

  if ((exp && moment.unix(exp).isBefore(moment())) || !queryToken) {
    history.push('/auth/sign-in');
  }

  const passwordValidation = (password) => {
    const regEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    if (!regEx.test(password)) {
      return false;
    }
    return true;
  };

  const handleResetPassword = () => {
    if (!userData.password || !confirmPassword) {
      dispatch(SetAuthState({ field: 'error', value: 'Enter new and confirm password' }));
      return;
    } else if (userData.password !== confirmPassword) {
      dispatch(SetAuthState({ field: 'error', value: 'Password and Confirm Password should be same.' }));
      return;
    } else if (!passwordValidation(userData.password)) {
      dispatch(SetAuthState({ field: 'error', value: 'Password must contain 1 upper case letter, 1 lowercase letter, and minimum 8 characters long.' }));
      return;
    } else {
      dispatch(ResetPassword({ password:userData.password, userId }));
    }
  };

  useEffect(() => {
    dispatch(SetAuthState({ field: 'error', value: '' }));
    dispatch(SetAuthState({ field: 'message', value: '' }));
    dispatch(SetAuthState({ field: 'success', value: false }));
  }, []);

  useEffect(() => {
    if (success && message) {
      Notification({
        type: 'success',
        title: 'Success',
        description: message
      });

      history.push('/auth/sign-in');
      dispatch(SetAuthState({ field: 'success', value: false }));
      dispatch(SetAuthState({ field: 'message', value: '' }));
      return;
    }

    if (error) {
      Notification({
        type: 'error',
        title: 'Error',
        description: error
      });
      dispatch(SetAuthState({ field: 'error', value: '' }));
      return;
    }
  }, [message, error, success]);

  return (
    <>
      <ResetPasswordLayout>
        <div className="auth-content-centered auth-reset-password">
        <h1 className="title">Reset Your Password</h1>
        <h2 className="subtitle">Enter New Password</h2>
        <div className="auth-section-middle-content auth-section-reset-content">
          <Row>
            <Col md={12} className="remove-stericks">
              <PasswordInput
                barColors={[
                  "#0FB600",
                  "#424A47",
                  "#424A47",
                  "#424A47",
                  "#424A47",
                ]}
                strength
                value={userData.password}
                label="Password"
                placeholder="Password Here"
                onChange={(e) => handleChange('password', e.target.value)}
                className={`validation-wrapper ${(userData.password !== confirmPassword) ? 'mismatch-or-empty' : ''}`}


              />
            </Col>
            <Col md={12} className="remove-stericks">
              <PasswordInput
                label="Confirm Password"   
                placeholder="Your Confirm Password Here"
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={`validation-wrapper ${(userData.password !== confirmPassword) ? 'mismatch-or-empty' : ''}`}
              />
            </Col>
          </Row>
        </div>
        <div className="auth-footer auth-reset-footer">
          <Row>
            <Col md={12}>
              <Button
                large="1"
                className="auth-btn"
                outlined
                width="100%"
                onClick={() => handleResetPassword()}
              >
              Confirm Password
              </Button>
            </Col>
          </Row>
        </div>
        </div>
      </ResetPasswordLayout>
      {
        loading && ( <Spin /> )
      }
    </>
  );
};

export default Index;
