import styled from "styled-components";
const PaymentWrapper = styled.div`
  margin-top: 80px !important;
  max-width: 800px;
  border-radius:8px ;
  padding:24px ;
  background-color:#fff ;
  margin: auto;
  .d-flex.align-items-center.stripform-item-wrapper {
    grid-gap: 24px;
  }
  .ant-form-item-control-input {
    border: 1px;
    min-width: 353px;
  }
  input {
    border: none;
    padding: 0px;
    font-weight: 600;
    &::placeholder {
      color: #a3a9c4;
    }
  }
  .footer-wrapper {
    display: flex;
    grid-gap: 16px;
    align-items: center;
    justify-content: end;
    button {
      background: #2476fa;
      color: #fff;
      border: none;
      
    }
  }
  .credentials-wrapper {
    margin-bottom: 16px;
}
  .add-card-popup-wrapper {
    padding: 16px;
    svg {
      margin: auto;
      display: block;
      font-size: 44px;
      margin-top: 16px;
      color: red;
    }
  }
  .stripform-form-address.new-feild-wrapper h2 {
    padding-bottom: 16px;
}
.ant-form-item-control-input {
    border: 1px solid #e6e8f0;
    border-radius: 4px;
    padding-left: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color:#fff ;
  }
  .payment-wrapper {
    width: 700px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 56px;
    div {
      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #272b41;
      }
      svg {
        font-size: 40px;
        color: #2a68cc;
      }
    }
  }
  svg.payment-strip-logo {
    font-size: 40px;
    color: #2a68cc;
  }
  .input-fields-wrapper {
    display: flex;
    grid-gap: 24px;
    p {
      margin: 0px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      padding-bottom: 4px;
    }
    input.form-control {
      min-width: 353px;
      border: none;
    }
  }
  .form-wrappers {
    padding-top: 24px;
    display: flex;
    grid-row-gap: 24px;
    flex-direction: column;
  }
  .fotter-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    margin-top: 24px;
    justify-content: end;
    button.sc-eDvSVe.bcvSGI.btn.btn-primary {
      background: #5f63f2;
      color: #fff;
      border: 1px solid #5f63f2;
      &:nth-child(1) {
        background: transparent;
        color: #5f63f2;
      }
    }
  }

`;

export { PaymentWrapper };
