import Cookies from 'universal-cookie';
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import PageHeader from "../../component/header";
import CongratImg from "../../assets/images/congrat-img.svg";
import Button from "../../component/Button/Index";
import { CongratulationWrapper } from "../authOnboarding-style";

import { SetAuthState } from "../../redux/slices/auth-slice";
import { GetUser } from '../../redux/slices/user-slice';

const Index = () => {
  const {
    token,
  } = useSelector((state) => state.auth);
  const {
    user
  } = useSelector((state) => state.user);

  const history = useHistory();
  const dispatch = useDispatch();

  const cookie = new Cookies();

  const handleCongratulation = () => {
    if (token && user.status === 'Active') {
      const currentDate = new Date();
      const nextYear = new Date();

      nextYear.setFullYear(currentDate.getFullYear() + 1);
      cookie.set('qikFindsToken', token, {
        path: '/',
        expires: nextYear
      });
      cookie.set('subscriptionValid', true, {
        path: '/',
        expires: nextYear
      });

      const { keepaKey } = user || {};
      if (keepaKey) {
        cookie.set('keepaKey', keepaKey, {
          path: '/',
          expires: nextYear
        });
      }

      let { name } = user || {};
      if (name) {
        name = name.split(' ');
        let shortName = name[0][0];
        if (name.length > 1) {
          shortName = `${shortName}${name[1][0]}`;
        }

        cookie.set('name', shortName, {
          path: '/',
          expires: nextYear
        });
      }
    }

    dispatch(SetAuthState({ field: 'storeStatus', value: 'Connected' }));
    history.push('/dashboard');
  };

  useEffect(() => {
    dispatch(GetUser({ userId: user._id }));
  }, [])

  useEffect(()=> {
    if (user && user?.subscriptionId && user?.status === 'Active'){
      dispatch(SetAuthState({ field: 'user', value: user }));
    }
  },[user]);

  return (
    <>
      <PageHeader />
      <CongratulationWrapper>
        <img src={CongratImg} alt="congratulation" />
        <h3>Congratulations!</h3>
        <p>Your Account is now Active</p>
        <p>Install the  <a href='https://chrome.google.com/webstore/detail/qikfinds/hakjiagadepdajkkhjcplkdinlkgakal' target={'_blank'}>extension</a>  to complete your account setup.</p>
        <Button outlined="1" large width={'241px'}
        onClick={() => handleCongratulation()}
        >
          Get Started
        </Button>
      </CongratulationWrapper>
    </>
  );
};

export default Index;
