import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import VerificationImage from "../../assets/images/verification.svg"
import { VerificationWrapper } from "./style";
import SignUpLayout from "./layout";

import { SendEmailVerification, SetAuthState } from "../../redux/slices/auth-slice";
import Notification from "../../component/notifications/notification";
import Spin from '../../component/Spin/index';
import Header from "../../component/header";

const Verification = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loading,
    user,
    error,
    success,
    message
  } = useSelector(state => state.auth);

  const handleSendEmailVerification = () => {
    dispatch(SendEmailVerification({
      userId: user?._id || user?.userId
    }))
  }

  useEffect(() => {
    if (success && message) {
      Notification({
        type: "success",
        title: "Success",
        description: message,
      });
      dispatch(SetAuthState({ field: "message", value: "" }));
      return;
    }
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error,
      });
      dispatch(SetAuthState({ field: "error", value: "" }));
      return;
    }
  }, [error, message]);

  return (
   <>
    <SignUpLayout>
      <VerificationWrapper>
        <h1>Verification Email Sent</h1>
        <img src={VerificationImage}/>
        <div className="footer-wrapper">
        <p>If you didn’t receive the email</p>
        <p className="verify-link" onClick={() => handleSendEmailVerification()}>Click Here</p>
        </div>
      </VerificationWrapper>
    </SignUpLayout>
    {loading && <Spin />}
   </>
  );
};

export default Verification;
