import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import Button from "../../component/Button/Index";
import Input from "../../component/inputs/input/index";
import Notification from '../../component/notifications/notification';
import Spin from '../../component/Spin';

import {
  ForgetPassword,
  SetAuthState
} from '../../redux/slices/auth-slice';

import ResetPassword from "./layout";

const Index = () => {

  const { error, loading, success, message } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');

  const emailValidation = (email) => {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
  };

  const handleForgetPassword = () => {
    if (!email) {
      dispatch(SetAuthState({ field: 'error', value: 'Email is required.' }));
    } else if (!emailValidation(email)) {
      dispatch(SetAuthState({ field: 'error', value: 'Please Enter a valid email address' }));
    } else {
      dispatch(ForgetPassword({ email }));
    }
  };

  useEffect(() => {
    if (success && message) {
      Notification({
        type: 'success',
        title: 'Success',
        description: message,
      });

      dispatch(SetAuthState({ field: 'message', value: '' }));
      dispatch(SetAuthState({ field: 'success', value: false }));
      dispatch(SetAuthState({ field: 'resetPasswordEmail', value: email }));
      history.push('/auth/verify-reset-password');
      return;
    }

    if (error) {
      Notification({
        type: 'error',
        title: 'Error',
        description: error
      });
      dispatch(SetAuthState({ field: 'error', value: '' }));
      return;
    }
  }, [error, message, success]);

  return (
    <>
      <ResetPassword>
        <div className="auth-content-centered auth-reset-password">
          <h1 className="title">Reset Your Password</h1>
          <h2 className="subtitle">Enter your Registerd Email Address</h2>
          <div className="auth-section-middle-content auth-section-reset-content">
            <Row>
              <Col md={12}>
                <Input className={`${email && !emailValidation(email) ? 'login-pg-input-email' : ''}`} label="Email" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
              </Col>
            </Row>
          </div>
          <div className="auth-footer auth-reset-footer">
            <Row>
              <Col md={12}>
                <Button large="1" className="auth-btn" outlined width={"100%"} onClick={() => handleForgetPassword()}>
                  Reset Password
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </ResetPassword>
      {
        loading && ( <Spin /> )
      }
    </>
  );
};

export default Index;
