import React from "react";

import { LoaderWrapper } from "./style";

const Index = () => {
  return (
    <LoaderWrapper>
      <div className="loader" id="loader">
        <div id="box"></div>
        <div id="shadow"></div>
        <p>Connecting...</p>
      </div>
    </LoaderWrapper>
  );
};

export default Index;
